import { Link, useLocation } from "@remix-run/react";

import type { PointOfServiceStock } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { LoadingButton } from "~/components/ui/loading-button";
import { P } from "~/components/ui/text";
import { cn } from "~/lib/ui";

export const StoreLoadMore = ({
  stores,
  totalStores,
  isLoading,
  pageSize,
  coordinates,
  allStoresLoaded,
}: {
  stores: PointOfServiceStock[];
  totalStores: number | undefined;
  isLoading: boolean;
  pageSize: number;
  coordinates:
    | {
        lat: string;
        lng: string;
      }
    | undefined;
  allStoresLoaded?: boolean;
}) => {
  const location = useLocation();
  const newSearchParams = new URLSearchParams(location.search);
  newSearchParams.set("pageSize", (pageSize + 5).toString());
  if (coordinates) {
    newSearchParams.set("lat", coordinates.lat);
    newSearchParams.set("lng", coordinates.lng);
  }

  return (
    <div className="flex w-full flex-col items-center justify-center self-center">
      <P className="text-dark-on-light py-3 text-center font-semibold">
        Showing {stores.length} of {totalStores} stores
      </P>
      <LoadingButton
        as={Link}
        to={`${location.pathname}?${newSearchParams.toString()}`}
        prefetch="render"
        replace
        preventScrollReset
        className={cn(
          "h-[52px] border-2 border-brand-primary-black bg-transparent px-6 font-extrabold text-brand-primary-black hover:bg-neutral-9 hover:no-underline",
          allStoresLoaded &&
            "pointer-events-none border-neutral-5  bg-neutral-7 text-neutral-5",
        )}
        isLoading={isLoading}
        onClick={(e: {
          preventDefault: () => void;
          stopPropagation: () => void;
        }) => {
          if (allStoresLoaded) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        variant="outline"
        loadingIndicatorHidden
      >
        <span>Load More...</span>
      </LoadingButton>
    </div>
  );
};
