import { Suspense } from "react";

import { Await, Link } from "@remix-run/react";

import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";

import type { CategoryHierarchy } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { getImageMediaLink } from "~/commerce-sap/shop/utils/image";
import { Accordion, AccordionItem } from "~/components/ui/accordion";
import { useURL } from "~/contexts";
import { useCategories, useCategory } from "~/contexts/mobile-menu";
import { cn } from "~/lib/ui";

// When
export const MobileMenu = () => {
  const url = useURL();
  const {
    selectedCategory,
    setSelectedCategory,
    goBack,
    categoryHistory,
    categoryDepth,
  } = useCategory();
  const categories = useCategories();
  const historyMaxDepth = categoryDepth - 1;

  return (
    <div className="flex w-full flex-col justify-center gap-4 text-left">
      <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={categories}>
          {categories => (
            <Accordion type="multiple">
              {!selectedCategory ? (
                categories?.subcategories?.map(
                  (category: CategoryHierarchy, index, array) => {
                    /* Hide categories that are marked to be hidden from the navigation bar */
                    if (category.hideFromNavigationBar === "Yes") return;

                    return (
                      <AccordionItem
                        onClick={() => setSelectedCategory(category)}
                        key={category.id}
                        value={`${category.id}`}
                        className={cn(
                          "group relative w-full cursor-pointer hover:before:opacity-100",
                          "before:absolute before:-left-8 before:-right-8 before:bottom-0 before:top-0 before:bg-gray-100 before:opacity-0 before:transition-opacity before:duration-200",
                        )}
                      >
                        <div className="relative z-10 flex w-full items-center justify-between py-2 text-base text-black md:justify-between">
                          <div className="flex flex-row items-center">
                            {category?.thumbnail?.url && (
                              <img
                                src={getImageMediaLink(category.thumbnail, {
                                  type: "thumb",
                                  params: { fmt: "auto" },
                                })}
                                alt="thumbnail"
                                className="mr-2 h-8 w-[40px]"
                              />
                            )}
                            {category.name}
                          </div>
                          <ChevronRightIcon className="h-5 w-5" />
                        </div>
                      </AccordionItem>
                    );
                  },
                )
              ) : (
                <div className="mt-2 flex flex-col">
                  <div className="flex flex-col md:flex-row md:justify-between">
                    <button
                      onClick={goBack}
                      className="mb-4 flex items-center justify-start text-left text-base font-bold"
                    >
                      <ChevronLeftIcon className="mr- -ml-1 h-5 w-5" />
                      {categoryHistory.length < 2
                        ? "Home"
                        : categoryHistory[categoryHistory.length - 2].name}
                    </button>

                    {/* Sub Category Title */}
                    {/* TODO: add in category image next to title  */}
                    <Label className="mb-2 inline-block text-right font-roboto text-lg font-semibold uppercase md:text-right">
                      {selectedCategory.name}
                    </Label>
                  </div>
                  {selectedCategory.contentPageId && (
                    <div className="flex h-[60px] items-center border-t border-neutral-6 align-middle text-base font-bold">
                      <Link
                        //TODO: PLP Content landing page
                        to={"/"}
                        className="w-full pl-4 hover:no-underline"
                        prefetch="render"
                      >
                        Explore {selectedCategory.name}
                      </Link>
                    </div>
                  )}

                  {/* Sub Category Shop all */}
                  <div
                    className={cn(
                      "group relative flex items-center px-4 py-4 text-base font-bold",
                      "before:absolute before:-left-8 before:-right-8 before:bottom-0 before:top-0 before:bg-gray-100 before:opacity-0 before:transition-opacity before:duration-200",
                      "hover:before:opacity-100",
                    )}
                  >
                    <Link
                      to={
                        `${selectedCategory.url}?hideVehicleComponent=${
                          selectedCategory.hideVehicleComponent ?? "false"
                        }` || "/"
                      }
                      className="relative z-10 w-full cursor-pointer hover:no-underline"
                      prefetch="render"
                    >
                      Shop All
                    </Link>
                  </div>

                  {selectedCategory?.subcategories?.map(
                    (category: CategoryHierarchy, index, array) => {
                      if (category.hideFromNavigationBar === "Yes") return;

                      const isLast = index === array.length - 1;

                      return (
                        <AccordionItem
                          onClick={() => {
                            if (
                              !!category?.subcategories?.length &&
                              categoryHistory.length !== historyMaxDepth
                            ) {
                              setSelectedCategory(category);
                            }
                          }}
                          key={category.id}
                          value={`${category.id}`}
                          className={cn(
                            "group relative w-full",
                            "before:absolute before:-left-8 before:-right-8 before:bottom-0 before:top-0 before:bg-gray-100 before:opacity-0 before:transition-opacity before:duration-200",
                            "hover:before:opacity-100",
                          )}
                        >
                          <div
                            className={cn(
                              "relative z-10 flex w-full items-center justify-between py-2 text-base text-black ",
                              isLast && "border-b-0",
                              index === 0 && "",
                            )}
                          >
                            {!!category?.subcategories?.length &&
                            categoryHistory.length !== historyMaxDepth ? (
                              <>
                                <span className="ml-4">{category.name}</span>
                                <ChevronRightIcon className="h-5 w-5" />
                              </>
                            ) : (
                              <Link
                                to={url(
                                  `${category.url}?hideVehicleComponent=${
                                    category.hideVehicleComponent ?? "false"
                                  }` || "",
                                )}
                                className="relative z-10 flex w-full items-center hover:no-underline"
                              >
                                <span className="ml-4">{category.name}</span>
                              </Link>
                            )}
                          </div>
                        </AccordionItem>
                      );
                    },
                  )}
                </div>
              )}
            </Accordion>
          )}
        </Await>
      </Suspense>
    </div>
  );
};
