import { json } from "@remix-run/cloudflare";
import { useFetcher } from "@remix-run/react";

import {
  invariantResponseError,
  validationError,
} from "~/components/forms/validationErrorResponse.server";

import type {
  VehicleFieldList,
  VehicleList,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useURL } from "~/contexts";

import { vehicleDetailsRequestValidator } from "./schemas/vehicle-details";
import { vehicleManualValidator } from "./schemas/vehicle-manual-search";

export async function loader({ request, context, params }: ActionArgs) {
  const { api } = context;
  const url = new URL(request.url);
  const data = Object.fromEntries(url.searchParams.entries());
  const action = params["type"];

  invariantResponseError(typeof action === "string", "Action must be string!");

  switch (action) {
    case "getVehicleFields": {
      const validateManual = await vehicleManualValidator.validate(data);

      if (validateManual.error) {
        console.error("Validation Error", validateManual.error);
        return validationError(validateManual.error);
      }

      const { make, engine, model, series, year } = validateManual.data;

      let field = "make";

      switch (true) {
        case !make:
          field = "make";
          break;
        case !model:
          field = "model";
          break;
        case !year:
          field = "year";
          break;
        case !series:
          field = "series";
          break;
        case !engine:
          field = "engine";
          break;
      }

      const manualSearchQuery = {
        userCookie: context.session.id,
        callingIPAddress: context.ip,
        field: field,
      };

      const searchParams = {
        ...validateManual.data,
      };
      try {
        const result = await api.search(manualSearchQuery, searchParams);
        return json(result);
      } catch (e) {
        console.error("getVehicleFields: " + (e as any).stack || e);
        return invariantResponseError("Error fetching Vehicle Field ", field);
      }
    }

    case "getVehicleDetails": {
      const getVehicleQuery = {
        userCookie: context.session.id,
        callingIPAddress: context.ip,
      };
      const validateDetails =
        await vehicleDetailsRequestValidator.validate(data);
      if (validateDetails.error) {
        console.error("Validation Error", validateDetails.error);
        return validationError(validateDetails.error);
      }

      const validateParams =
        await vehicleDetailsRequestValidator.validate(data);
      if (validateParams.error) {
        console.error("Validation Error", validateParams.error);
        return validationError(validateParams.error);
      }

      const getVehicleParams = {
        ...validateDetails.data,
      };
      const result = await api.getVehicle(getVehicleQuery, getVehicleParams);

      return json(result);
    }

    default: {
      const vehicleMakes = await api.getVehicleMakes();
      return json(vehicleMakes);
    }
  }
}

export const useLoadVehicleManualActionPath = (
  action?: "getVehicleFields" | "getVehicleDetails" | undefined,
) => {
  const _url = useURL();
  return _url(
    `/resources/vehicle/vehicle-manual${action ? `/${action}` : "/make"}`,
  );
};

export const useMakesVehicle = () => {
  const fetcher = useFetcher<VehicleFieldList | VehicleList>({
    key: "vehicleMakes",
  });
  const loadVehicleMakes = () => {
    fetcher.load("/resources/vehicle/vehicle-manual/load");
  };
  return { fetcher, loadVehicleMakes } as const;
};
