import { useCallback } from "react";

import type { SerializeFrom } from "@remix-run/cloudflare";

import type {
  Image,
  Product,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useURL } from "~/contexts";

import type { ProductDetailsHystoryState } from "../../_product-details+/location-state/product-details.location-state";

export const useProductTileData = (
  product: Product,
  quickNav?: Product[],
  totalProducts?: number,
) => {
  const url = useURL();

  const getHystoryState = useCallback(
    (image?: Image): ProductDetailsHystoryState | undefined => {
      if (!product) return undefined;

      return {
        product: product as SerializeFrom<typeof product>,
        image,
        quickNav: (quickNav?.filter(Boolean) || []) as SerializeFrom<Product>[],
        totalProducts,
      };
    },
    [product, quickNav, totalProducts],
  );
  return {
    getHystoryState,
    url,
  };
};
