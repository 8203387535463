import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "@remix-run/react";

import { Skeletons } from "~/commerce-sap/routes/($locale)+/_product-list+/components/skeletons";
import { Sheet, SheetContent, SheetTrigger } from "~/components/ui/sheet";
import { useBasket } from "~/routes/($locale)+/resources+/hooks/useBasket";

import MiniCartContentWrapper from "./mini-cart-content-wrapper";
import MiniCartHeader from "./mini-cart-header";

const MiniCartDrawer = ({ children }: { children: React.ReactNode }) => {
  const { hash, search } = useLocation();
  const [isOpen, setIsOpen] = useState(
    hash.toLocaleLowerCase().includes("drawer=mini-cart"),
  );
  const basket = useBasket();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(hash.toLocaleLowerCase().includes("drawer=mini-cart"));
  }, [hash]);

  const handleCloseMiniCart = () => {
    navigate((search ? search : "") + "#drawer", {
      preventScrollReset: true,
      replace: true,
    });
  };

  return (
    <Sheet
      open={isOpen}
      onOpenChange={isOpen => (!isOpen ? handleCloseMiniCart() : null)}
    >
      <div
        onClick={e => e.preventDefault()}
        onKeyDown={e => e.preventDefault()}
        tabIndex={-1}
        role="button"
      >
        <SheetTrigger asChild>{children}</SheetTrigger>
      </div>
      <SheetContent
        className="h-dvh z-[100] flex flex-col border-none bg-white sm:max-w-[340px] md:max-w-[448px] lg:max-w-[512px]"
        side="right"
        onCloseAutoFocus={e => e.preventDefault()}
      >
        <div className="flex h-full flex-col">
          <MiniCartHeader totalUnitCount={basket?.basket?.totalUnitCount} />

          {basket ? (
            <MiniCartContentWrapper basket={basket.basket} />
          ) : (
            <Skeletons size={4} className="h-full w-full md:w-full lg:w-full" />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default MiniCartDrawer;
