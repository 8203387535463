import { json } from "@remix-run/cloudflare";

import { validationError } from "~/components/forms/validationErrorResponse.server";

import type {
  PointOfServiceStock,
  StoreFinderStockSearchPage,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useURL } from "~/contexts";

export const loader = () => {};

export const action = async ({ request, context }: ActionArgs) => {
  const formData = await request.formData();
  const { shop, api } = context;
  const basket = await shop.getBasket();

  const pageSize = formData.get("pageSize");
  //TODO
  // const locationQuery = formData.get("locationQuery");
  const lat = formData.get("lat");
  const lng = formData.get("lng");
  const extractedItemCodes = JSON.parse(
    formData.get("extractedItemCodes") + "" || "[]",
  );
  const isCartPage = formData.get("isCartPage") === "true";
  if (typeof lat === "string" && typeof lng === "string") {
    try {
      const data = await shop.getStoreLocations({
        latitude: +lat,
        longitude: +lng,
        q: "",
        pageSize: pageSize ? Number(pageSize) : 5,
      });

      const inStockStores = [];
      const inStockStoresDataForMultipleItems: {
        code: string;
        inStockStores: StoreFinderStockSearchPage;
      }[] = [];

      for (const extractedItemCode of extractedItemCodes) {
        const page = 0;
        const totalPages = (pageSize ? Number(pageSize) : 5) / 5;

        const productStockData: PointOfServiceStock[] = [];

        for (let i = 0; i < totalPages; i++) {
          const pageProductStockData = await api.productStockCheck({
            productCode: extractedItemCode,
            latitude: +lat,
            longitude: +lng,
            page: page + i,
          });

          pageProductStockData.stores?.forEach(store => {
            productStockData.push(store);
          });
        }
        inStockStores.push({
          extractedItemCode,
          inStockStores: productStockData,
        });
      }

      const enrichedStoresData = [];

      // TODO
      // if (isCartPage) {
      //   const cartId = context.session.get("basketId");
      //   if (!cartId) throw new Error("No basket for session");

      //   //TODO
      //   // storess.stores[0].cartItemsStock => stock for each item in the cart
      //   const storess = await api.cartProductStockCheck({
      //     cartId,
      //     locationQuery: locationQuery ?? "",
      //     latitude: +lat,
      //     longitude: +lng,
      //     userId: context.auth.customerId,
      //   });

      //   const a = storess;
      // }
      if (isCartPage && data.stores) {
        for (const store of data.stores) {
          const storeName = store?.name?.toString();
          if (!storeName) {
            continue;
          }

          const pointOfServiceStockData =
            await shop.getPointOfServiceStock(storeName);

          if (pointOfServiceStockData) {
            const allProducts: number =
              pointOfServiceStockData.stockList?.length || 0;
            let inStockProducts: number = 0;
            let oosProducts: number = 0;

            pointOfServiceStockData.stockList?.map(stockItem => {
              const productInBasketQty =
                basket?.entries?.find(
                  e => e.product?.code === stockItem.productCode,
                )?.quantity || 0;
              if (
                productInBasketQty &&
                stockItem?.availableQty &&
                stockItem?.availableQty >= productInBasketQty
              ) {
                inStockProducts++;
              } else {
                oosProducts++;
              }
            });
            enrichedStoresData.push({
              storeName: storeName,
              cartStockInfo: {
                allProducts: basket?.entries?.length ?? allProducts ?? 0,
                inStockProducts,
                oosProducts,
              },
            });
          }
        }
      }

      return json({
        stores: data.stores,
        totalStores: data.pagination?.totalResults,
        pageSize: data.pagination?.pageSize,
        inStockStoresDataForMultipleItems,
        enrichedStoresData,
        inStockStores:
          inStockStores.length === 1 ? inStockStores[0].inStockStores : [],
      });
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.stack || error);

        return validationError(
          "Something went wrong. Please try selecting the address again.",
        );
      }
      throw error;
    }
  }

  return null;
};

export const useFindStoresPath = () => {
  const _url = useURL();
  return () => _url(`/resources/store/find-stores`);
};
