import { SheetClose } from "~/components/ui/sheet";
import { Text } from "~/components/ui/text";

const MiniCartNoItems = () => {
  return (
    <div className="mt-8 flex flex-col gap-6 px-6">
      <Text className="text-xl font-bold">
        There are no items in your shopping cart
      </Text>
      <SheetClose className="w-fit">
        <Text className="cursor-pointer text-xs font-bold underline">
          Keep Shopping
        </Text>
      </SheetClose>
    </div>
  );
};

export default MiniCartNoItems;
