import { useEffect, useState } from "react";

import type {
  PartsFitment,
  Vehicle,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import type { Option } from "~/hooks/use-vehicle-state";
import { ERR_MESSAGES } from "~/lib/constants";
import { useOnActionCompleted } from "~/lib/remix/fetcher";

import {
  useLoadVehicleManualActionPath,
  useMakesVehicle,
} from "../vehicle-manual.$type";
import { useLoadVehicleRegoActionPath } from "../vehicle-search";
import { VehicleSelect } from "./vehicle-manual-select";

interface ManualSearchProps {
  state: {
    make: string;
    model: string;
    year: string;
    series: string;
    engine: string;
    details: Vehicle;
    vehicleMakes: Option[];
    vehicleModels: Option[];
    vehicleYears: Option[];
    vehicleSeries: Option[];
    vehicleEngines: Option[];
    vehicleDetails: Vehicle[];
  };
  setMake: (make: string) => void;
  setModel: (model: string) => void;
  setYear: (year: string) => void;
  setSeries: (series: string) => void;
  setEngine: (engine: string) => void;
  setDetails: (details: Vehicle) => void;
}
type VehicleField =
  | "vehicleMakes"
  | "vehicleModels"
  | "vehicleYears"
  | "vehicleSeries"
  | "vehicleEngines";
type StateField = "make" | "model" | "year" | "series" | "engine";

interface VehicleOption {
  value: string;
}

interface State {
  make?: string;
  model?: string;
  year?: string;
  series?: string;
  engine?: string;
  details?: { desc: string };
  vehicleMakes: VehicleOption[];
  vehicleModels: VehicleOption[];
  vehicleYears: VehicleOption[];
  vehicleSeries: VehicleOption[];
  vehicleEngines: VehicleOption[];
  vehicleDetails: { desc: string }[];
}

export function ManualSearch({
  state,
  setMake,
  setModel,
  setYear,
  setSeries,
  setEngine,
  setDetails,
}: ManualSearchProps) {
  const { fetcher, loadVehicleMakes } = useMakesVehicle();
  const vehicleDetailsActionPath =
    useLoadVehicleManualActionPath("getVehicleDetails");
  const vehicleManualFieldsActionPath =
    useLoadVehicleManualActionPath("getVehicleFields");

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const vehicleRegoActionPath = useLoadVehicleRegoActionPath("setVehicle");

  useOnActionCompleted<{ result: PartsFitment; addedVehicle: string }>(
    vehicleRegoActionPath,
    data => {
      if (data && "partList" in data && !data.partList) {
        setErrorMessage(ERR_MESSAGES.noParts);
        setHasError(true);
        return;
      }
    },
  );

  useEffect(() => {
    loadVehicleMakes();
  }, []);

  useEffect(() => {
    const handleSingleSelection = (
      stateField: StateField,
      vehicleField: VehicleField,
      setter: (value: string) => void,
      actionKey: string,
    ) => {
      if (!state[stateField] && state[vehicleField].length === 1) {
        const value = state[vehicleField][0].value;
        setter(value);
        triggerVehicleFieldsAction(actionKey, value);
      }
    };
    if (hasError) setHasError(false);
    handleSingleSelection("make", "vehicleMakes", setMake, "make");
    handleSingleSelection("model", "vehicleModels", setModel, "model");
    handleSingleSelection("year", "vehicleYears", setYear, "year");
    handleSingleSelection("series", "vehicleSeries", setSeries, "series");
    handleSingleSelection("engine", "vehicleEngines", setEngine, "engine");

    if (
      Object.keys(state.details).length === 0 &&
      state.vehicleDetails.length === 1 &&
      state.engine &&
      state.vehicleDetails[0].desc
    ) {
      setDetails(state.vehicleDetails[0]);
      triggerVehicleFieldsAction("details", state.vehicleDetails[0].desc);
    }
  }, [
    state.vehicleDetails,
    state.vehicleEngines,
    state.vehicleSeries,
    state.vehicleYears,
    state.vehicleModels,
    state.vehicleMakes,
  ]);

  const triggerVehicleFieldsAction = (field: string, value: string) => {
    const hierarchy = ["make", "model", "year", "series", "engine", "details"];

    const index = hierarchy.indexOf(field);
    hierarchy.slice(index + 1).forEach(key => {
      switch (key) {
        case "model":
          setModel("");
          break;
        case "year":
          setYear("");
          break;
        case "series":
          setSeries("");
          break;
        case "engine":
          setEngine("");
          break;
        case "details":
          setDetails({});
          break;
      }
    });

    const formData: Record<string, string> = {};

    for (const key of hierarchy) {
      if (key === field) {
        formData[key] = value;
        break;
      } else if (state[key as keyof typeof state]) {
        formData[key] = state[key as keyof typeof state] as string;
      }
    }

    const actionPath =
      field === "engine"
        ? vehicleDetailsActionPath
        : vehicleManualFieldsActionPath;

    fetcher.submit(formData, { method: "get", action: actionPath });
  };

  const handleDropdownToggle = (field: string) => {
    setOpenDropdown(prev => (prev === field ? null : field));
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-3">
        {hasError && (
          <span className="block text-sm font-normal text-error">
            {errorMessage}
          </span>
        )}
        <GlobalErrors data={fetcher.data} />
        <VehicleSelect
          selectedOption={state.make}
          setSelectedOption={value => {
            setMake(value);
            triggerVehicleFieldsAction("make", value);
          }}
          label="Make*"
          options={state.vehicleMakes.map(option => option.label)}
          disabled={false}
          isLoading={fetcher.state !== "idle"}
          onOpen={() => handleDropdownToggle("make")}
          open={openDropdown === "make"}
        />
        <VehicleSelect
          selectedOption={state.model}
          setSelectedOption={value => {
            setModel(value);
            triggerVehicleFieldsAction("model", value);
          }}
          label="Model*"
          options={state.vehicleModels.map(option => option.label)}
          disabled={!state.make}
          isLoading={
            fetcher.state !== "idle" && "make" === fetcher.data?.responseField
          }
          onOpen={() => handleDropdownToggle("model")}
          open={openDropdown === "model"}
        />
        <VehicleSelect
          selectedOption={state.year}
          setSelectedOption={value => {
            setYear(value);
            triggerVehicleFieldsAction("year", value);
          }}
          label="Year*"
          options={state.vehicleYears.map(option => option.label)}
          disabled={!state.model}
          isLoading={
            fetcher.state !== "idle" && "model" === fetcher.data?.responseField
          }
          onOpen={() => handleDropdownToggle("year")}
          open={openDropdown === "year"}
        />
        <VehicleSelect
          selectedOption={state.series}
          setSelectedOption={value => {
            setSeries(value);
            triggerVehicleFieldsAction("series", value);
          }}
          label="Series*"
          options={state.vehicleSeries.map(option => option.label)}
          disabled={!state.year}
          isLoading={
            fetcher.state !== "idle" && "year" === fetcher.data?.responseField
          }
          onOpen={() => handleDropdownToggle("series")}
          open={openDropdown === "series"}
        />
        <VehicleSelect
          selectedOption={state.engine}
          setSelectedOption={value => {
            setEngine(value);
            triggerVehicleFieldsAction("engine", value);
          }}
          label="Engine*"
          options={state.vehicleEngines.map(option => option.label)}
          disabled={!state.series}
          isLoading={
            fetcher.state !== "idle" && "series" === fetcher.data?.responseField
          }
          dataSide={"top"}
          onOpen={() => handleDropdownToggle("engine")}
          open={openDropdown === "engine"}
        />
        <VehicleSelect
          selectedOption={state.details?.desc || ""}
          setSelectedOption={desc => {
            const selectedDetail = state.vehicleDetails.find(
              vehicle => vehicle.desc === desc,
            );
            if (selectedDetail) {
              setDetails(selectedDetail);
            }
          }}
          label="Details*"
          isLoading={
            fetcher.state !== "idle" && "engine" === fetcher.data?.responseField
          }
          options={state.vehicleDetails.map(vehicle => vehicle.desc || "")}
          disabled={!state.engine}
          dataSide={"top"}
          onOpen={() => handleDropdownToggle("details")}
          open={openDropdown === "details"}
        />
      </div>
    </div>
  );
}
