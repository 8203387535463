import { ClientOnly } from "remix-utils/client-only";

import { useMediaScreen } from "~/lib/utils/screens";

import type { FooterContent } from "../types";
import { Icon } from "./icon";
import { IconList } from "./icon-list";
import { ExpandableLinkGroup, LinkGroupRow } from "./link-group";
import { RichTextRender } from "./rich-text";

type Props = {
  content: FooterContent;
};

export const Footer = ({ content }: Props) => {
  const { is } = useMediaScreen();

  return (
    <footer className="bg-brand-primary-black px-6 py-8 text-white lg:px-10 lg:pb-0 lg:pt-[64px] xl:px-24">
      <div className="mx-auto flex max-w-1920 flex-col lg:flex-row lg:flex-wrap">
        {/* Rich text section */}
        <section className="mb-6 w-full lg:mb-12 lg:w-1/2 lg:pr-[5%]">
          {content.fields.richText &&
            content.fields.richText.map(rt => (
              <RichTextRender
                key={rt.sys.id}
                entryId={rt.sys.id}
                content={rt.fields.richText}
                textClassname="text-neutral-10 font-normal text-xs lg:text-base"
                textAlignment={rt.fields.textAlignment}
                color={rt.fields.textColour}
              />
            ))}
        </section>

        {/* Expandable links section */}
        <section className="mb-6 flex w-full flex-wrap lg:mb-12 lg:w-1/2">
          {content.fields.linkSections &&
            content.fields.linkSections.map(linkGroup => (
              <div key={linkGroup.sys.id} className="w-full  lg:w-1/3">
                <ExpandableLinkGroup content={linkGroup} />
              </div>
            ))}
        </section>

        {/* Sponsors & Awards sections */}
        <section className="flex w-full flex-wrap lg:w-1/2 lg:justify-between">
          {content.fields.iconsSection &&
            content.fields.iconsSection.map(iconList => (
              <div
                key={iconList.sys.id}
                className="mb-6 w-full lg:mb-20 lg:w-[45%]"
              >
                <IconList content={iconList} iconHeight={44} />
              </div>
            ))}
        </section>
      </div>

      {/* Bottom footer  */}
      <section className="mx-auto flex w-full max-w-1920 flex-col items-center gap-6 bg-inherit lg:flex-row lg:items-center lg:justify-between lg:gap-0 lg:bg-brand-primary-black lg:py-3">
        <div className="flex flex-col items-center gap-6 lg:flex-row lg:items-center lg:gap-5">
          {/* Low Price Guarantee */}
          <div>
            <ClientOnly>
              {() =>
                content.fields.footerLogo && (
                  <Icon
                    height={is === "sm" || is === "md" ? 68 : 42}
                    content={content.fields.footerLogo}
                  />
                )
              }
            </ClientOnly>
          </div>

          {/* Payment Methods */}
          <div className="flex items-center justify-center gap-4">
            {content.fields.paymentMethods && (
              <IconList
                iconHeight={16}
                containerStyles="flex flex-wrap gap-4 justify-center"
                content={content.fields.paymentMethods}
              />
            )}
          </div>
        </div>

        {/* Legal & Site Info */}
        {content.fields.legalSiteInfo && (
          <LinkGroupRow
            content={content.fields.legalSiteInfo}
            linkStyles="text-2xs font-bold hover:no-underline"
            containerStyles="flex items-center justify-center gap-4 lg:gap-6 lg:mr-[10%]"
          />
        )}

        {/* Copyright text */}
        <div className="flex flex-col items-center gap-1 lg:items-end">
          <p className="text-xs">{content.fields.copyrightSection1}</p>
          <p className="text-xs">
            &copy; {new Date().getFullYear()} {content.fields.copyrightSection2}
          </p>
        </div>
      </section>
    </footer>
  );
};
