import { useEffect } from "react";
import toast from "react-hot-toast";

import { Cross1Icon } from "@radix-ui/react-icons";

const ResetPasswordFlash = ({ message }: { message?: string }) => {
  useEffect(() => {
    if (message) {
      toast.custom(
        t => (
          <div className="flex rounded-lg border-2 border-success bg-success-lite p-3">
            <div>
              <p>Success!</p>
              <p>{message}</p>
            </div>
            <div className="h-fit cursor-pointer">
              <Cross1Icon onClick={() => toast.remove(t.id)} />
            </div>
          </div>
        ),
        { duration: 5000, position: "top-left" },
      );
    }
  }, [message]);
  return null;
};

export default ResetPasswordFlash;
