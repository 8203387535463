import { MinusIcon } from "@radix-ui/react-icons";

import { LoadingButton } from "../../../../components/ui/loading-button";

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: "submit" | "button";
  onClick?: () => void;
};
const MinusButton = ({
  isDisabled,
  isLoading,
  type = "submit",
  onClick,
}: Props) => {
  return (
    <LoadingButton
      className="h-[52px] w-[52px] min-w-[52px] rounded-md p-[14px] hover:border-neutral-2 hover:bg-neutral-2 disabled:bg-transparent"
      disabled={isDisabled}
      isLoading={isLoading}
      type={type}
      onClick={onClick}
      loadingIndicatorClassName="fill-brand-primary-black"
    >
      {!isLoading && (
        <MinusIcon
          width={24}
          height={24}
          className={`${
            isDisabled ? "text-brand-primary-black" : "text-white"
          }`}
        />
      )}
    </LoadingButton>
  );
};

export default MinusButton;
