import { useState } from "react";

import { CheckCircleIcon } from "@heroicons/react/20/solid";

import type { VehicleRego } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { Form, FormErrors } from "~/components/forms/Form";
import { TriButton } from "~/components/ui/tri-button";

import { setVehicleValidator } from "../schemas/vehicle-set";
import { useLoadVehicleRegoActionPath, useSetVehicle } from "../vehicle-search";

interface VehicleDetailsProps {
  vehicles: NonNullable<VehicleRego["vehicles"]>;
  rego?: NonNullable<VehicleRego["rego"]>;
  onClose: () => void;
}

export const VehicleList: React.FC<VehicleDetailsProps> = ({
  vehicles,
  rego,
  onClose,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState<number | undefined>(0);
  const vehicleSet = useSetVehicle();
  const vehicleRegoActionPath = useLoadVehicleRegoActionPath("setVehicle");

  return (
    <div className="flex h-screen flex-col">
      <div className="mb-19 flex-grow overflow-y-auto p-4">
        <h1 className="mb-4 text-[26px] font-bold leading-[33.8px] text-brand-primary-black">{`Rego - ${rego?.toLocaleUpperCase()}`}</h1>
        <h1 className="mb-4 text-sm font-normal leading-[18.2px] text-neutral-1">
          We have found possible matches. Please select the correct vehicle and
          add it.
        </h1>
        <div className="space-y-2">
          {vehicles.map((vehicle, index) => (
            <button
              key={index}
              className="flex w-full cursor-pointer justify-between rounded-lg border-2 border-brand-primary-black p-4"
              onClick={() => setSelectedVehicle(index)}
            >
              {vehicle.desc}
              {selectedVehicle === index && (
                <CheckCircleIcon
                  className="text-success-10"
                  height={20}
                  width={20}
                />
              )}
            </button>
          ))}
        </div>
      </div>

      <div className="mb-14 w-full flex-none bg-white  p-4  shadow-lg shadow-black/90 md:py-4">
        <Form
          action={vehicleRegoActionPath}
          id="form-rego-search"
          className="flex flex-col"
          method="post"
          fetcher={vehicleSet.fetcher}
          validator={setVehicleValidator}
        >
          <TriButton
            form="form-rego-search"
            isLoading={vehicleSet.fetcher.state !== "idle"}
            disabled={selectedVehicle === undefined}
            className={`h-[52px] w-full border-0 font-extrabold hover:no-underline ${
              selectedVehicle === undefined ? "bg-neutral-9 text-neutral-3" : ""
            }`}
          >
            Add My Vehicle
          </TriButton>
          <FormErrors></FormErrors>
          {vehicles &&
            Object.entries(
              selectedVehicle !== undefined && vehicles[selectedVehicle],
            ).map(([key, value]) => (
              <input key={key} type="hidden" name={key} value={value || ""} />
            ))}
          <button
            onClick={onClose}
            className="mb-4 mt-2 h-[52px] w-full rounded bg-neutral-9 px-4 text-center font-extrabold text-neutral-1"
          >
            Not My Vehicle, Try Again
          </button>
        </Form>
      </div>
    </div>
  );
};
