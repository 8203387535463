import type { Entry, EntrySkeletonType } from "contentful";
import React from "react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { cn } from "~/lib/ui";

import type {
  TypeRewardsPromoBanner,
  TypeSimpleTextSlideSkeleton,
} from "../compiled";
import type { CTAContent } from "../types";
import { ContentfulCTA } from "./contentful-cta";
import { ContentfulPicture } from "./picture";
import { RichTextRender } from "./rich-text";

interface RewardsPromoBannerProps {
  content: TypeRewardsPromoBanner<never, never>;
  className?: string;
  buttonClassname?: string;
  textClassname?: string;
}

function isEntry<T extends EntrySkeletonType>(entry: any): entry is Entry<T> {
  return entry && entry.sys && entry.sys.type === "Entry";
}
export const RewardsPromoBanner: React.FC<RewardsPromoBannerProps> = ({
  content,
  className = "",
  buttonClassname = "",
}) => {
  const fields = content?.fields || {};
  const inspectorProps = useContentfulInspectorMode();

  // Set up background color and text color based on `backgroundColor` field
  const backgroundColor =
    fields.backgroundColor === "FFF200" ? "bg-brand-secondary" : "bg-gray-900";
  const textColor =
    fields.backgroundColor === "FFF200" ? "text-black" : "text-white";

  return (
    <div
      className={`relative ${backgroundColor} py-6 ${className}`}
      {...inspectorProps({
        entryId: content?.sys?.id || "",
        fieldId: "fields",
      })}
    >
      <div
        className={cn(
          "mx-auto grid max-w-sm auto-rows-auto grid-cols-1 gap-6 px-6",
          "md:max-w-768 md:grid-cols-2",
          "lg:flex lg:max-w-1280 lg:justify-around",
          "xl:max-w-1920 xl:justify-between xl:px-[6rem]",
        )}
      >
        {/* Logo Image */}
        {fields.logoImage && (
          <div
            className={cn(
              "grid-col-1 order-1 flex max-w-[180px] items-center",
              "md:grid-col-1 md:order-1",
              "xl:justify-start",
            )}
          >
            <ContentfulPicture
              image={fields.logoImage as any}
              className="flex h-auto items-center justify-start md:justify-center"
            />
          </div>
        )}

        {/* Callout Text */}
        {fields.calloutText && (
          <div
            className={cn(
              "grid-col-1 order-2 flex items-center",
              "md:grid-col-1 md:order-3 md:w-[250px]",
              "lg:order-2 lg:w-[200px]",
              "xl:justify-center",
            )}
          >
            <RichTextRender
              content={fields.calloutText}
              entryId={content.sys?.id || ""}
            />
          </div>
        )}

        {/* Benefits Icons */}
        {fields.benefits && fields.benefits.length > 0 && (
          <div
            className={cn(
              "grid-col-1 order-3 flex flex-row flex-wrap items-center justify-start gap-4",
              "md:grid-col-1 md:order-2 md:justify-end",
              "lg:order-3 lg:inline-flex",
              "xl:justify-center",
            )}
          >
            {fields.benefits.map(
              (benefit, index) =>
                isEntry<TypeSimpleTextSlideSkeleton>(benefit) &&
                benefit.fields?.icon && (
                  <React.Fragment key={index}>
                    <div className="flex items-center space-x-2">
                      <ContentfulPicture
                        image={benefit.fields.icon as any}
                        className="h-8 w-8 md:h-10 md:w-10"
                      />
                      <span
                        className={`font-roboto text-base font-bold lg:text-xl ${textColor}`}
                      >
                        {benefit.fields.name || ""}
                      </span>
                    </div>

                    {/* Insert SVG if it's not the last item */}
                    {index < (fields.benefits?.length || 0) - 1 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="15"
                        fill="none"
                        viewBox="0 0 12 15"
                        className="hidden lg:inline"
                      >
                        <path fill="#121212" d="M.37 14.5V.5l11 7-11 7Z" />
                      </svg>
                    )}
                  </React.Fragment>
                ),
            )}
          </div>
        )}

        {/* CTA Buttons */}
        <div
          className={cn(
            "grid-col-1 order-4 flex flex-row items-center gap-4",
            "md:grid-col-1 md:justify-end",
          )}
        >
          {fields.buttons &&
            fields.buttons
              .filter(
                (button): button is CTAContent =>
                  !!button && "metadata" in button,
              )
              .map(button => (
                <ContentfulCTA
                  key={button.sys?.id || ""}
                  content={button}
                  className={buttonClassname}
                />
              ))}
        </div>
      </div>
    </div>
  );
};
