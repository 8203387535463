// Constants for contentful entry ids
export const HOME_PAGE_CONTENT = "38LEyL7m3iZap9MYVMzjkz";
export const FOOTER_CONTENT = "3Jj81i9CnRDfYddRFkeJou";
export const SIMPLIFIED_FOOTER_CONTENT = "3uqLwSuead5mdC8SQf6QQ6";
export const OUR_STORY_CONTENT = "4Lpe0XEXEAYkbdQlNbdUyo";
export const CARE_SECTION_CONTENT = "1qFCc9Hn1SYy1BgaSOkAcm";
export const COOKIE_BANNER = "5B1lfK6wBzUurDI4w2zE9c";
export const PDP_VIDEO = "2dYy4jWV7Qrec5ao97njlk";

// Accelerated Rewards content type ids
export const LOYALTY_CONFIGURATIONS_TYPE = "loyaltyConfigurations";
export const LOYALTY_CONFIGURATIONS_NAME = "Loyalty Configurations";

// Product Reccomendations content type id
export const PRODUCT_RECOMMENDATIONS_CATEGORY =
  "productRecommendationsCategory";
export const PRODUCT_RECOMMENDATIONS_PRODUCTS =
  "productRecommendationsProductIDs";

// Configuration templates
export const LAYOUT_CONFIG_TYPE = "configurationTemplate";
export const LAYOUT_CONFIG_NAME = "Autobarn Configuration Template";

// Homepage content type ids
export const HOMEPAGE_HERO_BANNER_CONTENT = "bannerCarousel"; // Carousel for the homapage Hero banners - full width
export const HOMEPAGE_HERO_BANNER = "componentHeroBanner"; // Custom Hero banner component
export const HOMEPAGE_CAROUSEL_CONTENT = "carousel"; // Carousel for homepage content (Not Hero)
export const HOMEPAGE_TILES_CONTENT = "tiles";
export const HOMEPAGE_IMAGE_WITH_CTA = "imageWithCta";
export const HOMEPAGE_IMAGE_WITH_HYPERLINK = "imageWithHyperlink";
export const VIDEO_TYPE = "video";
export const IFRAME = "iFrame";
export const BREADCRUMB = "breadcrumb";
export const RICH_TEXT = "richText";
export const BANNER_CONTENT = "banner";

// Article content type Ids
export const SIDE_BY_SIDE = "sideBySide";
export const LAYOUT_CONTAINER = "layoutContainer";
export const REWARDS_PROMO_BANNER = "rewardsPromoBanner";

// Organism components
export const CARD_TEXT_BOX = "cardTextBox";

// Generic template id
export const GENERIC_TEMPLATE = "genericTemplate";
export const CATEGORY_TEMPLATE = "categoryPageTemplate";
export const ARTICLE_HERO_BANNER = "articleHeroBanner";

// Pdp Usp
export const PDP_USP_TYPE = "pdpUsp";
export const DEFAULT_PDP_USP_NAME = "default-PDP-USP";

//Simplified Footer for Checkout
export const CHECKOUT_FOOTER_TYPE = "simplifiedFooter";
export const CHECKOUT_FOOTER_NAME = "Default Checkout Footer";
