import type { SerializeFrom } from "@remix-run/server-runtime";

import { useLocationState } from "~/location-state/location-state";

import type { loader } from "../shop.$";

export type ProductListHystoryState = {
  predata?: Awaited<SerializeFrom<typeof loader>["data"]>["0"]["hits"];
  postdata?: Awaited<SerializeFrom<typeof loader>["data"]>["0"]["hits"];
  fitmentData?: Awaited<SerializeFrom<typeof loader>["fitmentData"]>;
};
export const isProductListHystoryState = (
  state: unknown,
): state is ProductListHystoryState => {
  return (
    (state !== undefined &&
      typeof state === "object" &&
      state !== null &&
      "predata" in state &&
      Array.isArray(state?.predata)) ||
    (state !== undefined &&
      state !== null &&
      typeof state === "object" &&
      "postdata" in state &&
      Array.isArray(state?.postdata) &&
      state?.postdata?.length !== undefined)
  );
};
export const useProductListHistoryState = () => {
  const [state, set] = useLocationState<ProductListHystoryState>();
  if (isProductListHystoryState(state)) return [state, set] as const;
  return [undefined, set] as const;
};
