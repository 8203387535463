import type { Vehicle } from "~/commerce-sap/.server/api/generated/__generated_apis";

/**
 *
 * According to the backend logic, if any vehicle in the response has an ID of "0" or null,
 * it means that parts are not supplied for that REGO and STATE combination in Rego search. This function returns true only
 * if all vehicles have valid IDs.
 */
export function doesVehicleHaveMatchingProducts(vehicles: Vehicle[]) {
  return vehicles.every(vehicle => vehicle.id !== "0" && vehicle.id !== null);
}

export function getVehicleErrorMessage(vehicles?: Vehicle[]) {
  let errorMessage =
    "Sorry, we couldn’t find the vehicle you were looking for. Please check your details and try again or use the manual search to find by make and model.";

  if (vehicles && !doesVehicleHaveMatchingProducts(vehicles)) {
    errorMessage =
      "We don't supply parts specific to this vehicle, however there are many other generic products that universally fit.  You can continue shopping or add another vehicle.";
  }

  return errorMessage;
}

export const getCarIconColor = (isChecked: boolean, vehicle?: Vehicle) => {
  if (!vehicle) {
    return "#FFD700"; // Color when no vehicle
  }
  return isChecked ? "#93D500" : "#E7E7E7"; // Green if checked, light gray if unchecked
};
