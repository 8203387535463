import type { InputFieldProps } from "~/components/forms/Form";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/forms/Form";
import { Input } from "~/components/ui/input";

type InputIconProps = {
  IconComponent?: JSX.Element;
  onIconClick?: () => void;
} & InputFieldProps;

const FormInputWithIcon = ({
  name,
  label,
  description,
  placeholder,
  IconComponent,
  onIconClick,
  ...props
}: InputIconProps) => {
  return (
    <FormField name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-contrast-black text-sm font-semibold">
            {`${label}`}
          </FormLabel>
        )}

        <div className="relative">
          <FormControl>
            <Input placeholder={placeholder} {...props} />
          </FormControl>
          {IconComponent && (
            <button
              type="button"
              className="absolute inset-y-0 right-0 mr-4 flex items-center"
              onClick={() => onIconClick && onIconClick()}
            >
              {IconComponent}
            </button>
          )}
        </div>

        <FormControl></FormControl>
        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    </FormField>
  );
};

export default FormInputWithIcon;
