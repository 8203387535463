import * as React from "react";
import { useEffect, useState } from "react";

import { CaretDownIcon } from "@radix-ui/react-icons";

import {
  FormControl,
  FormDescription,
  FormField,
  FormFieldInput,
  FormItem,
  FormLabel,
} from "~/components/forms/Form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "~/components/ui/select";
import { cn } from "~/lib/ui";

const PLACEHOLDER = "placeholder";

const makeOptions = (options: string[]) =>
  options.map(option => {
    return (
      <SelectItem
        key={option}
        value={option}
        className="h-[48px]"
        indicatorRight
      >
        {option}
      </SelectItem>
    );
  });

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const isValidDate = (dateString: string) => {
  const date = new Date(dateString);

  const [year, month, day] = dateString.split("-").map(Number);

  return (
    date.getFullYear() === year &&
    date.getMonth() + 1 === month &&
    date.getDate() === day
  );
};

const transformDate = (date: string) => {
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};

const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

const FormDatePicker = ({
  name,
  description,
  defaultValue,
  label,
  ...props
}: {
  name: string;
  description?: string;
  defaultValue?: string;
  label?: string;
  "aria-invalid"?: boolean;
}) => {
  const [openSelect, setOpenSelect] = useState<string | null>(null);

  const [date, setDate] = useState<string>(() => {
    if (defaultValue) {
      return transformDate(defaultValue);
    }
    return "";
  });

  const createDescArray = (from: number, length: number) =>
    Array.from({ length }, (_, i) => from - i);

  const createAscArray = (from: number, length: number) =>
    Array.from({ length }, (_, i) => i + from);

  const years = createDescArray(new Date().getFullYear(), 125).map(year =>
    year.toString(),
  );

  const [day, setDay] = React.useState<string | undefined>(() => {
    if (defaultValue) {
      return defaultValue.split("/")[0];
    }
    return undefined;
  });
  const [days, setDays] = React.useState<string[]>(() => {
    if (defaultValue) {
      const [, defaultMonthStr, defaultYearStr] = defaultValue.split("/");
      const defaultMonthIndex = Number(defaultMonthStr) - 1;
      const defaultYear = Number(defaultYearStr);

      const daysInMonth = new Date(
        defaultYear,
        defaultMonthIndex + 1,
        0,
      ).getDate();

      return createAscArray(1, daysInMonth).map(day => day.toString());
    } else {
      return createAscArray(1, 31).map(day => day.toString());
    }
  });
  const [month, setMonth] = React.useState<string | undefined>(() => {
    if (defaultValue) {
      return months[Number(defaultValue.split("/")[1]) - 1];
    }
    return undefined;
  });
  const [year, setYear] = React.useState<string | undefined>(() => {
    if (defaultValue) {
      return defaultValue.split("/")[2];
    }
    return undefined;
  });

  const updateDays = (year: number, month: number) => {
    const daysInMonth = getDaysInMonth(year, month);

    setDays(createAscArray(1, daysInMonth).map(day => day.toString()));

    if (day && Number(day) > daysInMonth) {
      setDay(daysInMonth.toString());
    }
  };

  const updateYear = (value: string) => {
    if (value === PLACEHOLDER) {
      setYear(undefined);
      return;
    }

    setYear(value);

    if (month) {
      updateDays(Number(value), months.indexOf(month));
    }
  };

  const updateMonth = (value: string) => {
    if (value === PLACEHOLDER) {
      setMonth(undefined);
      return;
    }

    setMonth(value);

    updateDays(Number(year || new Date().getFullYear()), months.indexOf(value));
  };

  useEffect(() => {
    if (month && day && year) {
      const monthStr = String(months.indexOf(month) + 1).padStart(2, "0");
      const dayStr = String(day).padStart(2, "0");
      const dateString = `${year}-${monthStr}-${dayStr}`;

      if (isValidDate(dateString)) {
        setDate(dateString);
      } else {
        setDate("invalid");
      }
    } else if (!month && !day && !year) {
      setDate("");
    } else {
      setDate("invalid");
    }
  }, [day, month, year]);

  const toggleOpen = (select: string) => (isOpen: boolean) => {
    isOpen ? setOpenSelect(select) : setOpenSelect(null);
  };

  return (
    <FormField name={name}>
      <FormItem>
        <div>
          {label && (
            <FormLabel className="text-sm font-semibold text-brand-primary-black">
              {label}
            </FormLabel>
          )}
          <div className="flex gap-2">
            <FormControl className={'aria-[invalid="true"]:bg-error'}>
              <>
                <Select
                  open={openSelect === "day"}
                  onOpenChange={toggleOpen("day")}
                  name="day"
                  value={day}
                  defaultValue={""}
                  onValueChange={value =>
                    setDay(value === PLACEHOLDER ? undefined : value)
                  }
                  {...props}
                >
                  <SelectTrigger
                    aria-invalid={props["aria-invalid"]}
                    className=" my-2 flex h-[52px] flex-col items-start justify-center  border-primary bg-white shadow-none focus:ring-0 [&>svg]:hidden"
                  >
                    <span
                      className={cn(
                        "text-primary-100 flex w-full flex-row items-center justify-between text-sm font-semibold ",
                      )}
                    >
                      {day || "DD"}
                      <CaretDownIcon height="24px" width="24px" />
                    </span>
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="max-h-52 bg-white focus:outline-none "
                  >
                    <SelectItem value={PLACEHOLDER} indicatorRight>
                      DD
                    </SelectItem>
                    {makeOptions(days)}
                  </SelectContent>
                </Select>
                <Select
                  open={openSelect === "month"}
                  onOpenChange={toggleOpen("month")}
                  name="month"
                  value={month}
                  onValueChange={updateMonth}
                  defaultValue=""
                  {...props}
                >
                  <SelectTrigger className=" my-2 flex h-[52px] flex-col items-start justify-center  border-primary bg-white  shadow-none focus:ring-0 [&>svg]:hidden">
                    <span
                      className={cn(
                        "text-primary-100 flex w-full flex-row items-center justify-between text-sm font-semibold ",
                      )}
                    >
                      {month || "MM"}
                      <CaretDownIcon height="24px" width="24px" />
                    </span>
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="max-h-52 bg-white focus:outline-none"
                  >
                    <SelectItem value={PLACEHOLDER} indicatorRight>
                      MM
                    </SelectItem>
                    {makeOptions(months)}
                  </SelectContent>
                </Select>

                <Select
                  open={openSelect === "year"}
                  onOpenChange={toggleOpen("year")}
                  name="year"
                  value={year}
                  onValueChange={updateYear}
                  defaultValue={""}
                  {...props}
                >
                  <SelectTrigger className=" my-2 flex h-[52px] flex-col items-start justify-center  border-primary bg-white  shadow-none focus:ring-0 [&>svg]:hidden">
                    <span
                      className={cn(
                        "text-primary-100 flex w-full flex-row items-center justify-between text-sm font-semibold ",
                      )}
                    >
                      {year || "YYYY"}
                      <CaretDownIcon height="24px" width="24px" />
                    </span>
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="max-h-52 bg-white focus:outline-none"
                  >
                    <SelectItem value={PLACEHOLDER} indicatorRight>
                      YYYY
                    </SelectItem>
                    {makeOptions(years)}
                  </SelectContent>
                </Select>
              </>
            </FormControl>
          </div>
          {description && <FormDescription>{description}</FormDescription>}
          <FormFieldInput
            name={name}
            className={"hidden"}
            type="text"
            value={date}
          />
        </div>
      </FormItem>
    </FormField>
  );
};

export default FormDatePicker;
