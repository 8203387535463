import { useCallback, useEffect, useState } from "react";
import { z } from "zod";

import { json, useFetcher } from "@remix-run/react";

import type { MyStoreInfo } from "~/commerce-sap/.server/sessions.server";
import { commitSession } from "~/commerce-sap/.server/sessions.server";
import { invariantResponseError } from "~/components/forms/validationErrorResponse.server";
import { assertIsPost } from "~/lib/utils-server/http.server";

import { useURL } from "~/contexts";
import { invariant } from "~/lib/utils/utils";

export const loader = async () => {};

export const action = async ({ request, context }: LoaderArgs) => {
  const { api, session } = context;

  assertIsPost(request);
  invariant(
    request.headers.get("Content-Type") === "application/json",
    "Invalid content type",
  );
  const payload = z
    .object({
      latitude: z.number(),
      longitude: z.number(),
      declinedLocation: z.boolean(),
    })
    .safeParse(await request.json());

  invariantResponseError(payload.success, () => {
    if (!payload.success) {
      return `Invalid payload! ${payload.error.message}`;
    }
    return "Invalid payload";
  });
  const { latitude, longitude, declinedLocation } = payload.data;
  const myStoreInfo = session.get("myStoreInfo") || {};
  const storeLocation = myStoreInfo?.store || {};

  if (latitude && longitude) {
    const storeLocation = await api.getCustomerLocation({
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      fields: "DEFAULT",
      pageSize: 1,
    });
    session.set("myStoreInfo", {
      ...myStoreInfo,
      userCoordinates:
        latitude && longitude
          ? {
              lat: latitude,
              long: longitude,
            }
          : undefined,
      store: storeLocation,
      declinedLocation: false,
    });
  } else if (declinedLocation) {
    session.set("myStoreInfo", {
      ...myStoreInfo,
      declinedLocation: true,
    });
  }
  session.set("personalized", true);

  const headers = new Headers();
  headers.append("Set-Cookie", await commitSession());
  headers.append("Content-Type", "application/json");

  return json({
    storeLocation: storeLocation,
    headers: headers,
  });
};

export const useLoadUserStoreLocationPath = () => {
  const _url = useURL();
  return ({
    latitude,
    longitude,
    declinedLocation,
  }: {
    latitude: number;
    longitude: number;
    declinedLocation: boolean;
  }) =>
    _url(
      `/resources/store-location?latitude=${latitude}&longitude=${longitude}&declinedLocation=${declinedLocation}`,
    );
};
export const useSetStoreLocation = () => {
  const fetcher = useFetcher<typeof loader>({ key: "store-location" });
  const getPath = useLoadUserStoreLocationPath();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const load = useCallback(
    ({
      long,
      lat,
      declinedLocation,
    }: {
      long: number;
      lat: number;
      declinedLocation: boolean;
    }) =>
      fetcher.submit(
        { latitude: lat, longitude: long, declinedLocation },
        {
          action: getPath({ latitude: lat, longitude: long, declinedLocation }),
          preventScrollReset: true,
          method: "post",
          encType: "application/json",
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { fetcher, setStore: load } as const;
};

export const useStoreLocation = (myStoreInfo: MyStoreInfo | undefined) => {
  const { shouldFetchAll, savedCoordinates, nearbyStore } =
    getStoreLocationFromSession(myStoreInfo);
  const [checkBrowserLocation] = useState(shouldFetchAll);
  const [coordinates] = useState(savedCoordinates);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const { setStore, fetcher } = useSetStoreLocation();

  useEffect(() => {
    if (checkBrowserLocation && !hasFetchedData && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;

          setStore({ lat: lat, long: long, declinedLocation: false });
          setHasFetchedData(true);
        },
        error => {
          if (
            error.code === error.PERMISSION_DENIED ||
            error.code === error.POSITION_UNAVAILABLE
          ) {
            setStore({ lat: 0, long: 0, declinedLocation: true });
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBrowserLocation]);

  useEffect(() => {
    if (coordinates && coordinates.lat && coordinates.long && !hasFetchedData) {
      setStore({ ...coordinates, declinedLocation: false });

      setHasFetchedData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates]);

  return {
    storeLocation: nearbyStore ?? fetcher.data,
  };
};

export function getStoreLocationFromSession(
  myStoreInfo: MyStoreInfo | undefined,
) {
  let shouldFetchAll = false;
  let savedCoordinates = null;
  let nearbyStore = null;

  if (
    !myStoreInfo ||
    (!myStoreInfo.store &&
      !myStoreInfo.userCoordinates &&
      !myStoreInfo.declinedLocation)
  ) {
    shouldFetchAll = true;
  } else if (
    myStoreInfo &&
    !myStoreInfo.store &&
    myStoreInfo.userCoordinates?.lat != 0 &&
    myStoreInfo.userCoordinates?.long != 0
  ) {
    savedCoordinates = myStoreInfo.userCoordinates ?? null;
  } else if (myStoreInfo && myStoreInfo.store) {
    nearbyStore = myStoreInfo.store;
  } else {
    // Customer has declined location & we don't have any store info
    // Do nothing - don't propmt the customer for location again
  }

  return {
    shouldFetchAll,
    savedCoordinates,
    nearbyStore,
  };
}
