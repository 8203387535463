import type React from "react";
import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

import type { CategoryHierarchy } from "~/commerce-sap/.server/api/generated/__generated_apis";

interface CategoryContextType {
  selectedCategory: CategoryHierarchy | null;
  setSelectedCategory: (category: CategoryHierarchy) => void;
  categoryHistory: CategoryHierarchy[];
  goBack: () => void;
  categories: CategoryHierarchy | Promise<CategoryHierarchy>;
  categoryDepth: number;
}

const CategoryContext = createContext<CategoryContextType | undefined>(
  undefined,
);

interface CategoryProviderProps {
  children: ReactNode;
  categories: CategoryHierarchy | Promise<CategoryHierarchy>;
  categoryDepth: number;
}
export const useCategories = ():
  | CategoryHierarchy
  | Promise<CategoryHierarchy> => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error("useCategories must be used within a CategoryProvider");
  }
  return context.categories;
};
export const CategoryProvider: React.FC<CategoryProviderProps> = ({
  children,
  categories,
  categoryDepth,
}) => {
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryHierarchy | null>(null);

  const [categoryHistory, setCategoryHistory] = useState<CategoryHierarchy[]>(
    [],
  );

  const updateCategory = (category: CategoryHierarchy) => {
    if (selectedCategory !== category) {
      setCategoryHistory(prevHistory => [...prevHistory, category]);
      setSelectedCategory(category);
    }
  };

  const goBack = () => {
    setCategoryHistory(prevHistory => {
      if (prevHistory.length > 1) {
        const newHistory = [...prevHistory];
        newHistory.pop();
        const previousCategory = newHistory[newHistory.length - 1];
        setSelectedCategory(previousCategory);
        return newHistory;
      }
      setSelectedCategory(null);
      return [];
    });
  };

  return (
    <CategoryContext.Provider
      value={{
        categories,
        selectedCategory,
        setSelectedCategory: updateCategory,
        categoryHistory,
        goBack,
        categoryDepth,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = (): CategoryContextType => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error("useCategory must be used within a CategoryProvider");
  }
  return context;
};
