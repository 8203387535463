import { ClientOnly, cn } from "~/lib/ui";
import useLocalStorage from "~/lib/utils/useLocalStorage";
import { isServer } from "~/lib/utils/utils";

import type { ConsentBannerContent } from "../types";
import { RichTextRender } from "./rich-text";

type Props = {
  content: ConsentBannerContent;
};

export const ConsentBanner = ({ content }: Props) => {
  const [showBanner, setShowBanner] = useLocalStorage<boolean>("consent", true);

  if (isServer || !content || !setShowBanner) return null;

  const handleDismiss = () => {
    setShowBanner(false);
  };

  const textAlignment = content.fields.richText?.fields.textAlignment || "left";

  return (
    <ClientOnly>
      {() => (
        <section
          className={cn(
            "fixed bottom-4 left-1.5 right-1.5 z-50 rounded-lg bg-white px-3 py-2.5 shadow-banner md:bottom-8 md:left-6 md:right-6 md:px-8 md:py-4 lg:right-0 lg:max-w-[542px] xl:left-10",
            !showBanner && "hidden",
          )}
        >
          <div
            className={cn("flex flex-col space-y-1", {
              "items-start": textAlignment === "left",
              "items-center": textAlignment === "center",
              "items-end": textAlignment === "right",
            })}
          >
            {content.fields.richText?.fields.richText && (
              <RichTextRender
                entryId={content.sys.id}
                content={content.fields.richText?.fields.richText}
                textClassname="text-sm font-normal text-brand-primary-black"
                color={content.fields.richText.fields.textColour}
                textAlignment={textAlignment}
              />
            )}
            <button
              onClick={() => handleDismiss()}
              className="shrink-0 text-2xs font-bold leading-lg text-brand-primary-black underline focus:outline-none lg:text-base lg:font-extrabold"
            >
              Accept
            </button>
          </div>
        </section>
      )}
    </ClientOnly>
  );
};
