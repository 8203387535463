import type { Entry } from "contentful/dist/types/types/entry";
import { type ReactNode } from "react";

import ProductRecommendations from "~/components/product-recommendations/product-recommendations";
import type {
  TypeImageWithCtaSkeleton,
  TypeRichTextSkeleton,
} from "~/contentful/compiled";
import type { TypeImageWithHyperlinkSkeleton } from "~/contentful/compiled/TypeImageWithHyperlink";
import { ArticleHeroBanner } from "~/contentful/components/article-hero-banner";
import { ContentfulBanner } from "~/contentful/components/banner";
import Breadcrumb from "~/contentful/components/breadcrumb";
import { ContentfulCarousel } from "~/contentful/components/contentful-carousel";
import { HeroBanner } from "~/contentful/components/hero-banner";
import { ContentfulIFrame } from "~/contentful/components/iFrame";
import { ContentfulImageWithCTA } from "~/contentful/components/image-with-cta";
import { ContentfulImageWithHyperlink } from "~/contentful/components/image-with-hyperlink";
import { LayoutContainer } from "~/contentful/components/layout-container";
import { RewardsPromoBanner } from "~/contentful/components/rewards-promo-banner";
import { RichTextRender } from "~/contentful/components/rich-text";
import { SideBySide } from "~/contentful/components/side-by-side";
import { Tiles } from "~/contentful/components/tiles";
import ContentfulVideo from "~/contentful/components/video";
import {
  ARTICLE_HERO_BANNER,
  BANNER_CONTENT,
  BREADCRUMB,
  HOMEPAGE_CAROUSEL_CONTENT,
  HOMEPAGE_HERO_BANNER_CONTENT,
  HOMEPAGE_IMAGE_WITH_CTA,
  HOMEPAGE_IMAGE_WITH_HYPERLINK,
  HOMEPAGE_TILES_CONTENT,
  IFRAME,
  LAYOUT_CONTAINER,
  PRODUCT_RECOMMENDATIONS_CATEGORY,
  PRODUCT_RECOMMENDATIONS_PRODUCTS,
  REWARDS_PROMO_BANNER,
  RICH_TEXT,
  SIDE_BY_SIDE,
  VIDEO_TYPE,
} from "~/contentful/contents";
import type { ImageWithHyperlinkContent } from "~/contentful/types";
import { cn } from "~/lib/ui";

export const componentsSwitch = (id: string, content: any, index: number) => {
  const uniqueId = id + index;
  switch (id) {
    // Carousel Swiper for Hero banner
    case HOMEPAGE_HERO_BANNER_CONTENT:
      return (
        <div
          className="contentful-homepage-hero-banner-content h-auto min-h-[320px]"
          key={uniqueId}
        >
          <HeroBanner content={content} />
        </div>
      );
    case HOMEPAGE_CAROUSEL_CONTENT:
      return (
        // contentful-carousel-content
        <div
          className="contentful-carousel-content px-6 lg:px-10 xl:px-24"
          key={uniqueId}
        >
          <ContentfulCarousel carouselContent={content} />
        </div>
      );
    case REWARDS_PROMO_BANNER:
      return (
        <div className="contentful-rewards-promo-banner " key={uniqueId}>
          <RewardsPromoBanner content={content} />
        </div>
      );
    case HOMEPAGE_TILES_CONTENT:
      return (
        <div
          className="contentful-homepage-tiles-content px-6 lg:px-10 xl:px-24"
          key={uniqueId}
        >
          <Tiles content={content} />
        </div>
      );
    case HOMEPAGE_IMAGE_WITH_CTA:
      return (
        <div
          className="contentful-homepage-image-with-cta px-6 lg:px-10 xl:px-24"
          key={uniqueId}
        >
          <ContentfulImageWithCTA content={content} />
        </div>
      );
    case IFRAME:
      return (
        <div
          className="contentful-iframe h-full px-6 lg:px-10 xl:px-24"
          key={uniqueId}
        >
          <ContentfulIFrame content={content} />
        </div>
      );
    case ARTICLE_HERO_BANNER:
      return (
        <div
          className="contentful-article-hero-banner relative left-1/2 h-full w-screen -translate-x-1/2 transform"
          key={uniqueId}
        >
          <ArticleHeroBanner content={content} />
        </div>
      );
    case BREADCRUMB:
      return (
        <div
          key={uniqueId}
          className="contentful-breadcrumb mb-5 px-6 pt-6 lg:px-10 xl:px-24"
        >
          <Breadcrumb content={content} />
        </div>
      );
    case RICH_TEXT:
      return (
        <div
          key={uniqueId}
          className="contentful-rich-text mb-5 px-6 pt-6 lg:px-10 xl:px-24"
        >
          <RichTextRender
            entryId={uniqueId}
            content={content.fields.richText}
            color={content.fields.textColour}
            textAlignment={content.fields.textAlignment}
          />
        </div>
      );

    case HOMEPAGE_IMAGE_WITH_HYPERLINK:
      return (
        <div
          key={uniqueId}
          className="contentful-homepage-image-with-hyperlink"
        >
          <ContentfulImageWithHyperlink
            content={content as ImageWithHyperlinkContent}
            isBanner={true}
          />
        </div>
      );
    case PRODUCT_RECOMMENDATIONS_CATEGORY:
    case PRODUCT_RECOMMENDATIONS_PRODUCTS:
      return (
        <div
          className="contentful-product-recommendations px-6 lg:px-10 xl:px-24"
          key={uniqueId}
        >
          <ProductRecommendations sysId={id} fields={content.fields} />
        </div>
      );
    case VIDEO_TYPE:
      return (
        <div className="contentful-video" key={uniqueId}>
          <ContentfulVideo content={content} />
        </div>
      );

    case SIDE_BY_SIDE:
      return (
        <div className="contentful-side-by-side px-5 xl:px-0" key={uniqueId}>
          <SideBySide content={content} />
        </div>
      );

    case LAYOUT_CONTAINER:
      return (
        // Layout Container
        <div
          className={cn(
            "contentful-layout-container",
            "px-6 first:mt-16 last:mb-16 lg:px-10 xl:px-24",
          )}
          key={uniqueId}
        >
          <LayoutContainer content={content} />
        </div>
      );
    case BANNER_CONTENT:
      return (
        <div
          className="contentful-banner-content px-6 lg:px-10 xl:px-24"
          key={uniqueId}
        >
          <ContentfulBanner content={content} />
        </div>
      );

    default:
      break;
  }
};

type RenderContentfulComponentType = Entry<
  | TypeImageWithHyperlinkSkeleton
  | TypeImageWithCtaSkeleton
  | TypeRichTextSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  string
>;

export const renderContentfulComponent = (
  source: RenderContentfulComponentType[],
): ReactNode =>
  source?.map((content: RenderContentfulComponentType, index: number) => {
    return componentsSwitch(content?.sys?.contentType?.sys?.id, content, index);
  });
