import { z } from "zod";

import { withZod } from "@remix-validated-form/with-zod";

export const getVehiclesByRegoParamsSchema = z.object({
  ac: z.string().optional().default("1"),
  rego: z.string().min(3, "Registration number (rego) is required"),
  state: z.string().min(2, "State is required"),
});
export type GetVehiclesByRegoParams = z.infer<
  typeof getVehiclesByRegoParamsSchema
>;
export const vehicleRegoSchemaValidator = withZod(
  getVehiclesByRegoParamsSchema,
);
