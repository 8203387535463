import { z } from "zod";

import { withZod } from "@remix-validated-form/with-zod";

export const setVehicleParamsSchema = z.object({
  baseSiteId: z.string().optional(), // Base site identifier
  callingIPAddress: z.string(), // Required field for the IP address
  fields: z.string().optional().default("DEFAULT"), // Optional, with a default value of "DEFAULT"
  userCookie: z.string(), // Required field for user cookie
});

export type SetVehicleParams = z.infer<typeof setVehicleParamsSchema>;

export const setVehicleParamsSchemaValidator = withZod(setVehicleParamsSchema);

export const setVehicleSchema = z.object({
  id: z.string().optional(),
  bodyType: z.string().optional(),
  chassis: z.string().optional(),
  desc: z.string().optional(),
  details: z.string().optional(),
  doors: z.string().optional(),
  engine: z.string().optional(),
  fuel: z.string().optional(),
  hasMID: z.string().optional(),
  lngDsc: z.string().optional(),
  make: z.string().optional(),
  model: z.string().optional(),
  rego: z.string().optional(),
  regoState: z.string().optional(),
  searchStrategy: z.enum(["STANDARD", "REGO", "SMART", "ID"]).optional(),
  series: z.string().optional(),
  seriesChassis: z.string().optional(),
  subModel: z.string().optional(),
  vin: z.string().optional(),
  year: z.string().optional(),
});

export type SetVehicleParamsData = z.infer<typeof setVehicleSchema>;
export const setVehicleValidator = withZod(setVehicleSchema);
