import type { ChangeEvent } from "react";
import { useState } from "react";

import { type FetcherWithComponents } from "@remix-run/react";

import { ChevronLeftIcon } from "@radix-ui/react-icons";

import {
  Form,
  FormFieldInput,
  FormFieldPassword,
} from "~/components/forms/Form";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { Button } from "~/components/ui/button";
import { Large, Text } from "~/components/ui/text";
import { TriButton } from "~/components/ui/tri-button";
import { useURL } from "~/contexts";
import { loginSchemaValidator } from "~/routes/($locale)+/_auth+/login";

type Props = {
  fetcher: FetcherWithComponents<unknown>;
  actionData: unknown;
  isLoading: boolean;
  customerEmail: string;
  action: string;
  onForgotPasswordClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  handleBackButton: () => void;
  isFlyoutMode?: boolean;
};

const LoginForm = ({
  fetcher,
  isLoading,
  actionData,
  customerEmail,
  action,
  onForgotPasswordClick,
  handleBackButton,
  isFlyoutMode = false,
}: Props) => {
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const url = useURL();
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsInputEmpty(!event.target.value);
  };

  return (
    <>
      <div className="flex-1">
        <div className=" flex cursor-pointer items-center font-bold underline">
          <Button
            variant="link"
            size="1"
            onClick={handleBackButton}
            className="p-0 font-bold"
          >
            <ChevronLeftIcon width="16px" height="16px" /> <p>Back</p>
          </Button>
        </div>

        <Text
          variant="h4"
          className="!mt-4 flex flex-col font-bold lg:text-2xl"
        >
          {`Hey!`}
          <Large className="text-2xl font-bold">
            {`Let's get you logged in`}
          </Large>
        </Text>

        <GlobalErrors data={actionData} showFieldErrors={false} />
        <Form
          validator={loginSchemaValidator}
          className="!mt-3 space-y-4"
          action={action}
          method="post"
          fetcher={fetcher}
        >
          <FormFieldInput
            label="Email Address*"
            name="email"
            autoComplete="username"
            type="email"
            placeholder="name@email.com"
            defaultValue={customerEmail}
            aria-disabled
            readOnly
            className="text-contrast-black mt-1 font-semibold"
          />
          <FormFieldPassword
            label="Enter Password*"
            autoComplete="current-password"
            name="password"
            className="text-contrast-black mt-1"
            onChange={handleInputChange}
            placeholder="Enter password"
          />

          <div className=" flex justify-end ">
            <Button
              as="a"
              variant="link"
              size="1"
              className="font-bold leading-4 underline"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                if (onForgotPasswordClick) {
                  e.preventDefault();
                  onForgotPasswordClick(e);
                }
              }}
              href={url("/reset-password")}
            >
              Forgot Password?
            </Button>
          </div>

          <div>
            <TriButton
              type="submit"
              disabled={
                isInputEmpty ||
                (isLoading && fetcher.formAction?.includes("loginUser"))
              }
              isLoading={isLoading && fetcher.formAction?.includes("loginUser")}
              className="mt-3 h-[52px] w-full px-6  text-base font-extrabold"
            >
              Log In
            </TriButton>
          </div>
        </Form>
      </div>
      {!isFlyoutMode && (
        <div className="w-full flex-1">{/*TODO: Add content here*/}</div>
      )}
    </>
  );
};

export default LoginForm;
