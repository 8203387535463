import { Suspense, useMemo } from "react";

import { useNavigate } from "@remix-run/react";

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import type { BapcorFitment } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { SkeletonAwait } from "~/components/suspense/skeleton";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";
import AddToCartCTA from "~/routes/($locale)+/_product-details+/components/add-to-cart-cta";

import { useProductListHistoryState } from "../../location-state/product-list.location-state";
import PriceDisplay from "../price/price-display";
import { getLowestPrice } from "../price/price-utils";
import ProductAvailabilityInfo from "./product-availability-info";
import ProductFitmentButton from "./product-fitment-button";
import ProductTileAdditionalContent from "./product-tile-additional-content";

type ProductTileProps = {
  product: AlgoliaSearchResultHit;
  fitmentData:
    | {
        [key: string]: BapcorFitment[] | undefined;
      }
    | Promise<{
        [key: string]: BapcorFitment[] | undefined;
      }>;
  showSuitability: boolean;
  isGiftCard?: boolean;
  isLoyalityMember?: boolean;
  addToCartEvent: () => void;
  removeFromCartEvent: () => void;
};

const ProductTileCartCTA = ({
  product,
  fitmentData,
  showSuitability,
  isLoyalityMember,
  addToCartEvent,
  removeFromCartEvent,
}: ProductTileProps) => {
  const navigate = useNavigate();

  const [state] = useProductListHistoryState();

  // make product code as upperCase because we always receive the product code as upperCase in the basket.
  product.code = product.code.toUpperCase();

  const getFitmentData = (
    product: { partNumber?: string },
    fitmentData?: {
      [key: string]: BapcorFitment[] | undefined;
    },
  ) => {
    if (!product || !product.partNumber || !fitmentData) return undefined;
    const arePartsFit = product.partNumber && product.partNumber in fitmentData;
    const fitmentDataParts = arePartsFit
      ? fitmentData[product.partNumber]
      : null;
    return fitmentDataParts || undefined;
  };

  const memberPrice = product.loyaltyPrice;
  const salePrice = useMemo(
    () => (product?.onSale ? getLowestPrice(product?.price) : undefined),
    [product?.price, product?.onSale],
  );

  const isOutOfStock =
    product.purchasable === false ||
    (!product?.availableForClickCollect && !product?.availableForDelivery);
  const handleOpenVehicleSearch = () => {
    navigate(`${window.location.search}#drawer=vehicle`, {
      preventScrollReset: true,
      replace: true,
    });
  };

  const { myStoreInfo } = useRootLayoutData();
  const selectedStoreKey = myStoreInfo?.store?.name;
  const stockCount =
    product?.storeStock?.find(stockItem => stockItem.store === selectedStoreKey)
      ?.stock ?? 0;
  return (
    <>
      <PriceDisplay
        isMember={isLoyalityMember}
        rrpPrice={product?.rrpPrice}
        memberPrice={memberPrice}
        salePrice={salePrice}
      ></PriceDisplay>
      <div className="mt-auto">
        {showSuitability ? (
          <Suspense>
            <SkeletonAwait resolve={fitmentData} className="h-44 w-full">
              {fitmentData => {
                const fitmentDataParts =
                  getFitmentData(product, fitmentData) ||
                  getFitmentData(product, state?.fitmentData);
                return (
                  <ProductTileAdditionalContent
                    isFitmentSpecific={product.fitmentSpecific}
                    fitmentDataParts={fitmentDataParts}
                  />
                );
              }}
            </SkeletonAwait>
          </Suspense>
        ) : (
          <ProductFitmentButton
            fitmentSpecific={product.fitmentSpecific}
            handleOpenVehicleSearch={handleOpenVehicleSearch}
          />
        )}

        {selectedStoreKey && (
          <ProductAvailabilityInfo
            storeName={myStoreInfo?.store?.displayName}
            stockCount={stockCount}
            availableForCnC={product?.availableForClickCollect}
            availableForDelivery={product?.availableForDelivery}
            fulfilmentMessage={product?.fulfilmentMessage}
          />
        )}

        <div className="mt-auto">
          <AddToCartCTA
            productId={product.code}
            isOutOfStock={isOutOfStock}
            addToCartEvent={addToCartEvent}
            removeFromCartEvent={removeFromCartEvent}
          />
        </div>
      </div>
    </>
  );
};

export default ProductTileCartCTA;
