import { useCallback, useEffect } from "react";

import { useLocation, useNavigate } from "@remix-run/react";

import { Cross2Icon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";

import { isValidationError } from "~/components/forms/validationErrorResponse";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "~/components/ui/sheet";
import { useOnActionCompleted } from "~/lib/remix/fetcher";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";

import { AuthForm } from "./auth-drawer-form";

export function LoginDrawer({ children }: { children?: React.ReactNode }) {
  const navigate = useNavigate();
  const { search, hash } = useLocation();
  const isOpen = hash.includes("drawer=login");
  const { user } = useRootLayoutData();
  const qParams = search ? search : "";

  const handleOpen = useCallback(() => {
    navigate(qParams + "#drawer=login", {
      preventScrollReset: true,
      replace: true,
    });
    // navigate is stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qParams]);
  const handleClose = useCallback(() => {
    // NB: using just # redirect to wrong url
    navigate(qParams + "#drawer", {
      preventScrollReset: true,
      replace: true,
    });
    // navigate is stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qParams]);

  useEffect(() => {
    if (user?.cdcUser && isOpen) {
      handleClose();
    }
  }, [user, handleClose, isOpen]);
  useOnActionCompleted("loginUser", data => {
    if (isValidationError(data)) {
      return;
    }
    handleClose();
  });

  if (user?.cdcUser) {
    return null;
  }

  return (
    <Sheet
      open={isOpen}
      onOpenChange={isOpen => (!isOpen ? handleClose() : null)}
    >
      <div
        onClick={handleOpen}
        onKeyDown={e => e.preventDefault()}
        tabIndex={-1}
        role="button"
      >
        <SheetTrigger asChild>{children}</SheetTrigger>
      </div>
      <SheetContent
        className="z-[100] flex max-h-screen flex-col overflow-y-auto bg-white sm:max-w-sm md:max-w-lg"
        side="left"
        onCloseAutoFocus={e => e.preventDefault()}
        onInteractOutside={e => {
          e.preventDefault();
        }}
      >
        <div>
          <div className="relative z-50 flex h-16 w-full items-center bg-heading pl-4 pr-5 align-middle">
            <img
              src="/icons/person.svg"
              alt="My Account"
              className="h-8 w-8 text-neutral-1"
            />
            <div className="ml-4 flex w-full justify-between">
              <div className="flex max-w-sm flex-col">
                <Label className="text-xl font-extrabold">My Account</Label>
              </div>
              <SheetClose>
                <Cross2Icon className="h-6 w-6" />
              </SheetClose>
            </div>
          </div>
        </div>
        <AuthForm />
      </SheetContent>
    </Sheet>
  );
}
