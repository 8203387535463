import { useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import type { SimpleTextSliderContent, USPBannerContent } from "../types";
import { Icon } from "./icon";

type SlideProps = {
  content: SimpleTextSliderContent;
};
const UspSlide = ({ content }: SlideProps) => {
  const link = content.fields.link;
  const inspectorProps = useContentfulInspectorMode();

  const slideElement = (
    <div
      {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
      className="flex h-12 flex-col items-center justify-center py-2 "
    >
      <div className="flex shrink-0 items-center justify-center gap-2">
        {content.fields.icon && (
          <Icon height={24} content={content.fields.icon} />
        )}
        <p className="text-base font-bold text-brand-primary-black md:text-sm lg:text-base">
          {content.fields.title}
        </p>
      </div>
      <p className="truncate text-sm font-bold text-neutral-1 xl:text-base">
        {content.fields.body}
      </p>
    </div>
  );

  return link ? (
    <a href={link} className="hover:underline">
      {slideElement}
    </a>
  ) : (
    slideElement
  );
};

type ContainerProps = {
  content: USPBannerContent | undefined;
};

export const UspBanner = ({ content }: ContainerProps) => {
  const [isStatic, setIsStatic] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsStatic(window.innerWidth >= 1024);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!content) return null;
  const slides = content.fields.textSlides;

  return isStatic ? (
    <div className=" bg-neutral-7">
      <div className="mx-auto flex max-w-1920  flex-wrap justify-center gap-6 px-10 lg:gap-8 lg:px-20 xl:gap-12 xl:px-24">
        {slides.map(slide => (
          <div key={slide.sys.id} className="w-full md:w-auto">
            <UspSlide content={slide} />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <Swiper
      spaceBetween={0}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      loop={true}
      modules={[Autoplay, Pagination, Navigation]}
      id="uspBanner"
      className="bg-neutral-7"
    >
      {slides.map(slide => (
        <SwiperSlide key={slide.sys.id}>
          <UspSlide content={slide} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
