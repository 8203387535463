import { Suspense, useEffect, useState } from "react";

import {
  Await,
  useActionData,
  useFetcher,
  useNavigation,
} from "@remix-run/react";

import { ChevronLeftIcon } from "@radix-ui/react-icons";

import type { action } from "~/commerce-sap/routes/($locale)+/_auth+/register";
import { Button } from "~/components/ui/button";
import { Spinner } from "~/components/ui/spinner";
import { useURL } from "~/contexts";
import { useOnActionCompleted } from "~/lib/remix/fetcher";
import { cn } from "~/lib/ui";
import RegisterAlmostDone from "~/routes/($locale)+/_auth+/components/register-almost-done";
import RegisterGetStarted from "~/routes/($locale)+/_auth+/components/register-get-started";

import type { loader } from "../register";

type RegisterStep = "get-started" | "almost-done";

type Props = {
  className?: string;
  onBackButtonClick?: () => void;
  customerEmail?: string;
};

export type RegisterState = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  birthDay?: string;
  phoneNumber: string;
  state: string;
  preferredStore: string;
  termsAndConditionsAgreement: string;
  regToken?: string;
  captchaRequired: string;
  "g-recaptcha-response": string;
};

export const RegisterDrawer = ({
  className,
  onBackButtonClick,
  customerEmail,
}: Props) => {
  const transition = useNavigation();
  const fetcher = useFetcher<typeof action>({ key: "register-verify" });
  const fetcherLoadRegisterData = useFetcher<typeof loader>({
    key: "register-load",
  });
  const actionData = useActionData<typeof action>() || fetcher.data;
  const { data } = fetcherLoadRegisterData;
  const url = useURL();

  const [registerStep, setRegisterStep] = useState<RegisterStep>("get-started");
  useEffect(() => {
    fetcherLoadRegisterData.load(url("/register/"));
  }, []);

  useOnActionCompleted("validateUserMainData", () => {
    setRegisterStep("almost-done");
  });

  useOnActionCompleted(
    "validateUserSecondaryData",
    (data?: Partial<RegisterState>) => {
      const formData = new FormData();
      // Object.keys(state).forEach(key =>
      //   formData.append(key, state[key as keyof RegisterState] as string),
      // );

      if (data) {
        Object.keys(data).forEach(key => {
          formData.delete(key);
          formData.append(key, data[key as keyof RegisterState] as string);
        });
      }

      fetcher.submit(formData, {
        action: "/register/?action=registerUser",
        method: "post",
      });
    },
  );

  const isLoading = transition.state !== "idle" || fetcher.state !== "idle";
  if (!data)
    return (
      <>
        <div className=" flex cursor-pointer items-center px-6 font-bold underline">
          <Button
            variant="link"
            size="1"
            onClick={onBackButtonClick}
            className="p-0 font-bold"
          >
            <ChevronLeftIcon width="16px" height="16px" /> <p>Back</p>
          </Button>
        </div>
        <div className="flex h-screen items-center justify-center justify-items-center">
          <Spinner />
        </div>
      </>
    );
  return (
    <Suspense>
      <Await resolve={data}>
        {resolved => (
          <div className={cn(className, "flex flex-col justify-center")}>
            <div className=" flex cursor-pointer items-center px-6 font-bold underline">
              <Button
                variant="link"
                size="1"
                onClick={() => {
                  registerStep === "almost-done" || !onBackButtonClick
                    ? setRegisterStep("get-started")
                    : onBackButtonClick();
                }}
                className="p-0 font-bold"
              >
                <ChevronLeftIcon width="16px" height="16px" /> <p>Back</p>
              </Button>
            </div>
            <div className={cn("flex  h-full w-full flex-col space-y-8")}>
              <div className="px-6">
                {registerStep === "get-started" && (
                  <RegisterGetStarted
                    fetcher={fetcher}
                    actionData={actionData}
                    isLoading={isLoading}
                    action={"/register/?action=validateUserMainData"}
                    defaultValues={{
                      email: customerEmail,
                    }}
                    onEdit={onBackButtonClick}
                    isFlyoutMode={true}
                  />
                )}
                {registerStep === "almost-done" && (
                  <RegisterAlmostDone
                    regToken={resolved.regToken}
                    requireCaptcha={resolved.requireCaptcha}
                    recaptchaSecret={resolved.RECAPTCHA_SECRET_KEY}
                    fetcher={fetcher}
                    stores={resolved.stores}
                    actionData={actionData}
                    isLoading={isLoading}
                    handleBackButton={() => setRegisterStep("get-started")}
                    isFlyoutMode={true}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Await>
    </Suspense>
  );
};
