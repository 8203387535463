import { useCallback } from "react";

import type {
  Cart,
  CategoryHierarchy,
} from "~/commerce-sap/.server/api/generated/__generated_apis";

export type EventsPayload =
  | { eventType: "view"; eventData: string }
  | { eventType: "setEmail"; eventData: string }
  | {
      eventType: "cart";
      eventData: Cart;
    }
  | {
      eventType: "purchase";
      eventData: Cart;
    }
  | { eventType: "category"; eventData: CategoryHierarchy };

export const useEmarsysEvents = () => {
  const cb = <const T extends EventsPayload>(...events: Array<T>) => {
    const scarabQueue = window.ScarabQueue;
    if (!scarabQueue) return;

    const controlArray: any[] = [];

    try {
      events.forEach(event => {
        const eventReportData = prepareEventData(event);
        if (eventReportData) {
          scarabQueue.push([event.eventType, eventReportData.eventData]);
          controlArray.push([event.eventType, eventReportData.eventData]);
        }
      });
    } catch (e) {
      console.error(e);
    }

    if (controlArray.length === 0) return;

    scarabQueue.push(["go"]);
    controlArray.push(["go"]);
    //debug
    // console.log(controlArray);
  };

  return useCallback(cb, []);
};

const prepareEventData = (
  event: EventsPayload,
):
  | { eventType: "view"; eventData: string }
  | { eventType: "setEmail"; eventData: string }
  | {
      eventType: "cart";
      eventData: Array<{ item: string; price: number; quantity: number }>;
    }
  | {
      eventType: "purchase";
      eventData: {
        orderId: string;
        items: Array<{ item: string; price: number; quantity: number }>;
      };
    }
  | { eventType: "category"; eventData: string } => {
  switch (event.eventType) {
    case "cart":
      return prepareCartEventData(event.eventData);
    case "view":
      return prepareViewEventData(event.eventData);
    case "purchase":
      return preparePurchaseEventData(event.eventData);
    case "category":
      return prepareCategoryEventData(event.eventData);
    case "setEmail":
      return prepareSetEmailEventData(event.eventData);
    default:
      throw new Error("Invalid event type");
  }
};

const prepareCartEventData = (
  eventData: Cart,
): {
  eventType: "cart";
  eventData: Array<{ item: string; price: number; quantity: number }>;
} => {
  return {
    eventType: "cart",
    eventData:
      eventData.entries?.map(entry => {
        return {
          item: entry.product?.code ?? "",
          price: entry.totalPrice?.value ?? 0,
          quantity: entry.quantity ?? 0,
        };
      }) ?? [],
  };
};

const prepareViewEventData = (productId: string) => {
  return { eventType: "view", eventData: productId } as const;
};

const preparePurchaseEventData = (
  order: Cart,
): {
  eventType: "purchase";
  eventData: {
    orderId: string;
    items: Array<{ item: string; price: number; quantity: number }>;
  };
} => {
  return {
    eventType: "purchase",
    eventData: {
      orderId: order.code ?? "",
      items:
        order.entries?.map(entry => {
          return {
            item: entry.product?.code ?? "",
            price: entry.totalPrice?.value ?? 0,
            quantity: entry.quantity ?? 0,
          };
        }) ?? [],
    },
  };
};

const prepareCategoryEventData = (
  category: CategoryHierarchy,
): { eventType: "category"; eventData: string } => {
  return {
    eventType: "category",
    eventData:
      category?.categoryBreadcrumb
        ?.map(cat => {
          return cat.name;
        })
        .join(">") ?? "",
  };
};

const prepareSetEmailEventData = (
  email: string,
): { eventType: "setEmail"; eventData: string } => {
  return { eventType: "setEmail", eventData: email } as const;
};
