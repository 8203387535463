import { useMemo } from "react";

import { Link } from "@remix-run/react";

import { getImageLink } from "@commerce/shop/utils/image";

import type { Image } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useURL } from "~/contexts";
import { useGTMTracker } from "~/google-tagmanager";
import { cn } from "~/lib/ui";

import { useProductTileData } from "../../hooks/use-product-tile-data";

type ProductTileImageProps = {
  product: {
    images?: Image[] | { format: string; url: string }[];
    code?: string;
    name?: string;
  };
  linkTo?: string;
  list_id: string;
  list_name: string;
  index: number;
  isCarousel?: boolean;
  isGiftCard?: boolean;
};

const ProductTileImage = ({
  product,
  linkTo,
  list_id,
  list_name,
  index = 0,
  isCarousel = false,
  isGiftCard = false,
}: ProductTileImageProps) => {
  const { getHystoryState } = useProductTileData(product);
  const url = useURL();
  const { selectItemEvent } = useGTMTracker();

  const productImage = useMemo(() => {
    return Array.isArray(product.images)
      ? product.images?.find((image: Image) => image.format === "product")
      : undefined;
  }, [product.images]);

  return (
    <Link
      state={getHystoryState(productImage)}
      to={linkTo ?? url(`/${product.code}.html`)}
      className={cn(
        isGiftCard ? "self-center" : "",
        isCarousel ? "sm:pb-[0px]" : "sm:pb-[20px]",
      )}
      onClick={() => selectItemEvent(product, index, list_id, list_name)}
    >
      <div
        className={cn(
          "flex justify-center",
          isCarousel
            ? "carousel-product-tile-image mx-auto  h-[100px] w-[100px]  sm:h-[180px] sm:w-[180px] md:h-[210px] md:w-[210px]"
            : "listing-product-tile-image listing-product-tile-image mx-auto sm:h-[110px] sm:w-[110px] md:h-[210px] md:w-[210px]",
        )}
      >
        {productImage && (
          <img
            src={getImageLink(productImage.url ?? "")}
            alt={product.name}
            className="sm:[h-110px] md:h-full"
          />
        )}
      </div>
    </Link>
  );
};

export default ProductTileImage;
