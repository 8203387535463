export const CustomExclamationCircleIcon = ({
  width = 12,
  height = 12,
  stroke = "#FFF",
  fill = "#121212",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      fill={fill}
      stroke={stroke}
      strokeWidth="2"
    />
    <line
      x1="12"
      y1="8"
      x2="12"
      y2="14"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="12" cy="17" r="1" fill={stroke} />
  </svg>
);
