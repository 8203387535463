import { Cross2Icon } from "@radix-ui/react-icons";

import { SheetClose } from "~/components/ui/sheet";
import { Text } from "~/components/ui/text";

type Props = {
  totalUnitCount?: number;
};
const MiniCartHeader = ({ totalUnitCount }: Props) => {
  return (
    <div className="relative z-50 flex h-[70px] min-h-[70px] w-full items-center justify-between bg-brand-primary-black py-[9px] pl-[18px] pr-6">
      <div className="flex items-center gap-2">
        <img
          src={"/icons/cart.svg"}
          className={`h-8 w-8 fill-white`}
          alt={"icon"}
        />

        <Text className="text-base text-white">
          My Cart ({totalUnitCount ?? 0})
        </Text>
      </div>
      <SheetClose className="hover:rounded-md hover:bg-dark-gray">
        <Cross2Icon
          width={32}
          height={32}
          viewBox="0 0 15.25 15.25"
          className="text-white"
        />
      </SheetClose>
    </div>
  );
};

export default MiniCartHeader;
