import { Link } from "@remix-run/react";

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import useProductPrimaryImage from "~/commerce-sap/routes/($locale)+/_product-list+/hooks/use-product-primary-image";
import { useURL } from "~/contexts";

import { RRPrice } from "../price/price-utils";
import { useProductTileData } from "./use-product-tile-data";

type ProductTileProps = {
  product: AlgoliaSearchResultHit;
  addToCartEvent: () => void;
};

const GiftcardShowCTA = ({ product, addToCartEvent }: ProductTileProps) => {
  const url = useURL();
  const { getHystoryState } = useProductTileData(product);
  const { primaryImage } = useProductPrimaryImage({
    images: product.images ?? [],
  });

  return (
    <>
      <div className="flex pb-3">
        <RRPrice price={product.priceRangeMap?.minPrice} className="text-xl" />
        <span className="px-0.5 text-xl font-bold">-</span>
        <RRPrice price={product.priceRangeMap?.maxPrice} className="text-xl" />
      </div>
      <Link
        state={getHystoryState(primaryImage)}
        to={url(`/${product.code}.html`)}
        onClick={() => {
          addToCartEvent();
        }}
        className="mt-4 flex w-full justify-center rounded-md	 bg-brand-primary-black px-4 py-2 text-base font-extrabold text-white hover:no-underline md:ml-auto md:mt-auto"
      >
        Shop Now
      </Link>
    </>
  );
};

export default GiftcardShowCTA;
