import type { IconProps } from "../../types";

export const AustralianFlagIcon = ({
  width = 18,
  height = 12,
  fill = "none",
  ...props
}: IconProps & { width?: number; height?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 12"
      fill={fill}
      {...props}
    >
      <path
        d="M17.6896 12H0.310359C0.138972 12 0 11.8586 0 11.6842V0.315803C0 0.14141 0.138972 0 0.310359 0H17.6896C17.861 0 18 0.14141 18 0.315803V11.6842C18 11.8586 17.861 12 17.6896 12Z"
        fill="#41479B"
      />
      <path
        d="M8.68961 0H8.15052L5.27581 1.91651V0H3.72409V1.91651L0.849372 0H0.310358C0.138972 0 0 0.141375 0 0.315769V0.566252L2.46638 2.21049H0V3.78944H2.46638L0 5.43371V5.6842C0 5.85859 0.138972 6 0.310358 6H0.849442L3.72416 4.08349V6H5.27588V4.08349L8.15056 6H8.68964C8.86103 6 9 5.85859 9 5.6842V5.43371L6.53362 3.78948H8.99996V2.21052H6.53359L8.99996 0.566288V0.315805C8.99996 0.141375 8.86099 0 8.68961 0Z"
        fill="#F5F5F5"
      />
      <path
        d="M9 2.52633H4.9655V0H4.0345V2.52633H0V3.47367H4.0345V6H4.9655V3.47367H9V2.52633Z"
        fill="#FF4B55"
      />
      <path
        d="M3.46154 2.11765L0.149972 0C0.0618495 0.0549244 0 0.147249 0 0.25759V0.273674L2.88354 2.11765H3.46154Z"
        fill="#FF4B55"
      />
      <path
        d="M5.78366 2.11765L9.00105 0.151713C8.94513 0.0626456 8.84647 0 8.72855 0H8.65905L5.19336 2.11765H5.78366Z"
        fill="#FF4B55"
      />
      <path
        d="M3.21641 3.88208L0 5.84424C0.0554635 5.93547 0.155816 5.99973 0.27564 5.99973H0.336355L3.80769 3.88208H3.21641Z"
        fill="#FF4B55"
      />
      <path
        d="M9.0006 5.74683L6.10667 3.88208H5.53906L8.82535 5.99973C8.92379 5.95437 8.99132 5.85933 9.0006 5.74683Z"
        fill="#FF4B55"
      />
      <path
        d="M4.56522 7.81688L4.79472 8.60822L5.53018 8.30225C5.59294 8.27614 5.64997 8.35089 5.61167 8.40896L5.1624 9.08975L5.84995 9.49954C5.90861 9.53451 5.88825 9.62769 5.82088 9.63261L5.03113 9.69021L5.1531 10.5072C5.1635 10.5769 5.08104 10.6184 5.03537 10.5664L4.49985 9.95743L3.96433 10.5664C3.91866 10.6184 3.8362 10.5769 3.8466 10.5072L3.96856 9.69021L3.17882 9.63261C3.11145 9.62769 3.09109 9.53451 3.14975 9.49954L3.8373 9.08975L3.38803 8.40896C3.34972 8.35089 3.40676 8.27614 3.46952 8.30225L4.20498 8.60822L4.43448 7.81688C4.45414 7.74935 4.54566 7.74935 4.56522 7.81688Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.8794 2.14447L13.9941 2.54012L14.3618 2.38716C14.3931 2.37413 14.4217 2.41146 14.4025 2.44052L14.1779 2.78094L14.5217 2.98586C14.551 3.00331 14.5408 3.04993 14.5071 3.05236L14.1123 3.08115L14.1732 3.48962C14.1784 3.52449 14.1372 3.54523 14.1143 3.51923L13.8466 3.21474L13.5789 3.51923C13.556 3.54519 13.5148 3.52446 13.52 3.48962L13.5809 3.08115L13.1861 3.05236C13.1524 3.04993 13.1422 3.00331 13.1716 2.98586L13.5153 2.78094L13.2907 2.44052C13.2715 2.41146 13.3001 2.37413 13.3314 2.38716L13.6991 2.54012L13.8139 2.14447C13.8238 2.1107 13.8696 2.1107 13.8794 2.14447Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.1098 4.96771L11.2246 5.36336L11.5923 5.2104C11.6236 5.19737 11.6522 5.23471 11.633 5.26376L11.4084 5.60418L11.7521 5.8091C11.7815 5.82655 11.7713 5.87317 11.7376 5.8756L11.3427 5.9044L11.4037 6.31287C11.4089 6.34774 11.3677 6.36847 11.3448 6.34247L11.0771 6.03799L10.8093 6.34247C10.7865 6.36843 10.7453 6.3477 10.7505 6.31287L10.8114 5.9044L10.4166 5.8756C10.3829 5.87317 10.3727 5.82655 10.402 5.8091L10.7458 5.60418L10.5212 5.26376C10.502 5.23471 10.5306 5.19737 10.5619 5.2104L10.9296 5.36336L11.0443 4.96771C11.0543 4.93394 11.1 4.93394 11.1098 4.96771Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.3012 3.90948L16.416 4.30514L16.7837 4.15218C16.815 4.13914 16.8436 4.17648 16.8244 4.20553L16.5998 4.54595L16.9435 4.75087C16.9729 4.76832 16.9627 4.81494 16.929 4.81737L16.5341 4.84617L16.5951 5.25464C16.6003 5.28951 16.5591 5.31024 16.5362 5.28424L16.2685 4.97976L16.0007 5.28424C15.9779 5.3102 15.9367 5.28947 15.9419 5.25464L16.0028 4.84617L15.608 4.81737C15.5743 4.81494 15.5641 4.76832 15.5934 4.75087L15.9372 4.54595L15.7126 4.20553C15.6934 4.17648 15.722 4.13914 15.7533 4.15218L16.121 4.30514L16.2357 3.90948C16.2457 3.87571 16.2914 3.87571 16.3012 3.90948Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.8794 8.8504L13.9941 9.24606L14.3618 9.09306C14.3931 9.08003 14.4217 9.11737 14.4025 9.14642L14.1779 9.48685L14.5217 9.69177C14.551 9.70923 14.5408 9.75585 14.5071 9.75828L14.1123 9.78707L14.1732 10.1956C14.1784 10.2304 14.1372 10.2512 14.1143 10.2252L13.8466 9.92067L13.5789 10.2252C13.556 10.2511 13.5148 10.2304 13.52 10.1956L13.5809 9.78707L13.1861 9.75828C13.1524 9.75585 13.1422 9.70923 13.1716 9.69177L13.5153 9.48685L13.2907 9.14642C13.2715 9.11737 13.3001 9.08003 13.3314 9.09306L13.6991 9.24606L13.8139 8.8504C13.8238 8.81663 13.8696 8.81663 13.8794 8.8504Z"
        fill="#F5F5F5"
      />
      <path
        d="M14.6686 5.6808L14.5645 6.01395L14.2355 6.01667C14.1929 6.01705 14.1752 6.07504 14.2095 6.10204L14.4742 6.31066L14.3749 6.64546C14.3621 6.68883 14.4083 6.72467 14.4429 6.69823L14.7106 6.49403L14.9783 6.69823C15.013 6.72467 15.0592 6.68879 15.0464 6.64546L14.9471 6.31066L15.2118 6.10204C15.2461 6.075 15.2284 6.01705 15.1858 6.01667L14.8568 6.01395L14.7527 5.6808C14.7392 5.63765 14.682 5.63765 14.6686 5.6808Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
