import type { Toast } from "react-hot-toast";
import toast from "react-hot-toast";
import { z } from "zod";

import { json } from "@remix-run/server-runtime";

import { commitSession } from "~/commerce-sap/.server/sessions.server";
import { validationError } from "~/components/forms/validationErrorResponse.server";

import { withZod } from "@remix-validated-form/with-zod";

import ToastError from "~/components/toasts/toast-error";
import ToastSuccess from "~/components/toasts/toast-success";
import { useURL } from "~/contexts";

export const loader = () => {};

export const action = async ({ request, context }: ActionArgs) => {
  const { api } = context;
  const validation = await setCustomerStoreLocationValidator.validate(
    await request.formData(),
  );

  if (validation.error)
    return validationError(validation.error, validation.submittedData);

  const store = await api.getCustomerLocation({
    latitude: validation.data?.latitude,
    longitude: validation.data?.longitude,
    fields: "DEFAULT",
    pageSize: 1,
  });
  store.formattedDistance = validation.data?.formattedDistance;

  const myStoreInfo = context.session.get("myStoreInfo");

  context.session.set("myStoreInfo", {
    ...myStoreInfo,
    store,
  });
  context.session.set("personalized", true);

  const headers = new Headers();
  headers.append("Set-Cookie", await commitSession());

  return json(
    { success: true, message: "Location saved successfully" },
    {
      headers,
    },
  );
};

//TODO: move to app/lib/schemas
export const setCustomerStoreLocationSchema = z.object({
  longitude: z.string().min(1),
  latitude: z.string().min(1),
  formattedDistance: z.string().min(1),
});

//TODO: move to app/lib/schemas
export const setCustomerStoreLocationValidator = withZod(
  setCustomerStoreLocationSchema,
);

//TODO: move to app/components/toasts
export const showSetStoreToast = (
  data: { success: boolean; message: string } | undefined,
  isMobile: boolean,
) => {
  const isSuccessful = typeof data === "object" && data && "success" in data;
  const toastComponent = (t: Toast) =>
    isSuccessful ? (
      <ToastSuccess t={t} message={`Your store has been set.`} />
    ) : (
      <ToastError t={t} message={`An error has occured.`} />
    );
  toast.custom(t => toastComponent(t), {
    position: isMobile ? "top-center" : "top-right",
  });
};

export const useSetStorePath = () => {
  const _url = useURL();
  return () => _url(`/resources/store/set-store`);
};
