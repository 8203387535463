import { ClientOnly } from "remix-utils/client-only";

import { Cross2Icon } from "@radix-ui/react-icons";

import useLocalStorage from "~/lib/utils/useLocalStorage";
import { isServer } from "~/lib/utils/utils";

import type { AlertBannerContent } from "../types";
import { RichTextRender } from "./rich-text";

type Props = {
  content: AlertBannerContent | undefined;
};

export const AlertBanner = ({ content }: Props) => {
  const [dismissedAlerts, setDismissedAlerts] = useLocalStorage<string[]>(
    "dismissedAlerts",
    [],
  );

  const showAlertBanner = !dismissedAlerts.includes(String(content?.sys.id));

  if (isServer || !content || !showAlertBanner) return null;

  const handleDismiss = (alertId: string) => {
    const updatedDismissedAlerts = [...dismissedAlerts, alertId];
    setDismissedAlerts(updatedDismissedAlerts);
  };

  return (
    <ClientOnly>
      {() => (
        <div
          className="flex flex-row items-center justify-between bg-brand-primary px-6 py-4"
          id="alertBanner"
        >
          <div className="w-6" />
          <div className="mr-8 lg:mr-0">
            <RichTextRender
              entryId={content.sys.id}
              content={content.fields.content}
              textAlignment="center"
              textClassname="text-sm"
            />
          </div>
          <button
            className="self-start focus:outline-none"
            onClick={() => handleDismiss(content.sys.id)}
          >
            <Cross2Icon width={24} height={24} />
          </button>
        </div>
      )}
    </ClientOnly>
  );
};
