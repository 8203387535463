import type { MutableRefObject, ReactNode } from "react";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useLocation, useNavigation } from "@remix-run/react";

import type { Cart } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";
import { useBasket } from "~/routes/($locale)+/resources+/hooks/useBasket";

import { useEmarsysEvents } from "./report.emarsys.events";

const _EmarsysContext = createContext<{
  preventDefaultEvents: () => void;
  reset: () => void;
  ref: MutableRefObject<boolean>;
}>(null!);
export const EmarsysContext = memo(({ children }: { children: ReactNode }) => {
  const ref = useRef(false);
  const preventDefaultEvents = useCallback(() => {
    ref.current = true;
  }, []);
  const reset = useCallback(() => {
    ref.current = false;
  }, []);
  const value = useMemo(
    () => ({ preventDefaultEvents, ref, reset }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <_EmarsysContext.Provider value={value}>
      {children}
    </_EmarsysContext.Provider>
  );
});
EmarsysContext.displayName = "EmarsysContext";

export const useEmarsys = () => {
  const context = useContext(_EmarsysContext);
  if (!context) {
    throw new Error("useEmarsysEvents must be used within a EmarsysProvider");
  }
  return context;
};
export const usePreventDefaultEvents = () => {
  const { preventDefaultEvents, reset } = useEmarsys();
  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  preventDefaultEvents();
};
export const SendEmarsysEvents = memo(() => {
  const { user } = useRootLayoutData();
  const basket = useBasket();

  if (!basket?.basket) return null;
  if (!user) {
    return <InternalSendEmarsysGuest basket={basket.basket} />;
  }
  return <InternalSendEmarsysRegistered basket={basket?.basket} user={user} />;
});

SendEmarsysEvents.displayName = "SendEmarsysEvents";

const InternalSendEmarsysGuest = memo(({ basket }: { basket: Cart }) => {
  const sendEmarsysReport = useEmarsysEvents();
  const nav = useNavigation();
  const location = useLocation();
  const { ref } = useEmarsys();
  useEffect(() => {
    if (nav.state !== "idle") return;
    if (ref.current) return;
    const timer = setTimeout(() => {
      if (ref.current) {
        return;
      }
      sendEmarsysReport({ eventType: "cart", eventData: basket });
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key, nav.state]);
  return null;
});
InternalSendEmarsysGuest.displayName = "InternalSendEmarsysGuest";
const InternalSendEmarsysRegistered = memo(
  ({
    basket,
    user,
  }: {
    basket: Cart;
    user: NonNullable<ReturnType<typeof useRootLayoutData>["user"]>;
  }) => {
    const sendEmarsysReport = useEmarsysEvents();
    const nav = useNavigation();
    const location = useLocation();
    const { ref } = useEmarsys();
    useEffect(() => {
      if (nav.state !== "idle") return;
      if (ref.current) return;
      const timer = setTimeout(() => {
        if (ref.current) {
          return;
        }
        sendEmarsysReport(
          {
            eventType: "setEmail",
            eventData: user.cdcUser.profile.email ?? "",
          },
          { eventType: "cart", eventData: basket },
        );
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.key, nav.state]);

    return null;
  },
);

InternalSendEmarsysRegistered.displayName = "InternalSendEmarsysRegistered";
