import type { AlgoliaSearchResultHit, Category } from "~/algolia/algolia.types";
import { GIFT_CARD_CATEGORY_ID } from "~/lib/constants";
import type { AlgoliaSearchProductSuggestion } from "~/routes/($locale)+/resources+/search-suggestions";

export const IsGiftCard = (
  product: AlgoliaSearchResultHit | AlgoliaSearchProductSuggestion,
): boolean => {
  return !!(
    product?.categories &&
    product.categories.some(
      (category: Category) => category.code === GIFT_CARD_CATEGORY_ID,
    )
  );
};
