import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { cn } from "~/lib/ui";

import type { CardTextBoxContent } from "../types";
import { getMaxWidthClass } from "../util";
import { ContentfulCTA } from "./contentful-cta";
import { RichTextStyledRenderer } from "./rich-text-styled";
import { TitleUnderline } from "./title-underline";
import TooltipWithRichText from "./tooltip-with-rich-text";

export const CardTextBox = ({
  content,
  className = "",
}: {
  content: CardTextBoxContent;
  className?: string;
}) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();

  // Function to get the rich text content and bullet/marker styling based on content type
  const getRichTextData = (bodyField: any) => {
    if (!bodyField || !bodyField.fields)
      return { content: null, bulletStyling: null, markerStyling: null };

    const contentTypeId = bodyField.sys.contentType.sys.id;

    if (contentTypeId === "richTextBody") {
      return {
        content: bodyField.fields.richText,
        bulletStyling: null,
        markerStyling: null,
      };
    } else if (contentTypeId === "unorderedList") {
      return {
        content: bodyField.fields.unorderedListItems,
        bulletStyling: bodyField.fields.bulletStyling || "default",
        markerStyling: null,
      };
    } else if (contentTypeId === "orderedList") {
      return {
        content: bodyField.fields.orderedListItems,
        bulletStyling: null,
        markerStyling: bodyField.fields.markerStyling || "default",
      };
    } else {
      return { content: null, bulletStyling: null, markerStyling: null };
    }
  };

  const {
    content: richTextContent,
    bulletStyling,
    markerStyling,
  } = getRichTextData(fields.body);

  // Get the Tailwind class for maxWidth
  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  // Font title size map
  const titleSizeClasses = {
    ".h1":
      "[h1] text-[56px] lg:text-[86px] leading-[56px] lg:leading-[78px] font-black italic",
    ".h2":
      "[h2] text-[32px] lg:text-[56px] leading-[34px] lg:leading-[58px] font-bold italic",
    ".h3":
      "[h3] text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-semibold",
    ".h4":
      "[h4] text-[21px] lg:text-[24px] leading-[30px] lg:leading-[30px] font-medium",
    ".h5":
      "[h5] text-[18px] lg:text-[20px] leading-[22px] lg:leading-[24px] font-medium",
  };

  const titleSizeClass = titleSizeClasses[fields.titleSize ?? ".h3"];

  const titleCaseClasses = {
    "Sentence case": "normal-case",
    "Title Case": "capitalize",
    "UPPER CASE": "uppercase",
  };

  const titleCase = titleCaseClasses[fields.titleCase ?? "Sentence case"];

  return (
    <div
      className={cn(
        "card-text-box flex w-full flex-col gap-5",
        className,
        maxWidthClass,
      )}
      {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
    >
      {/* Title */}
      {fields.title && (
        <div>
          <h2
            className={cn(
              // text-[18px] lg:text-[20px] xl:text-[24px]
              `font-roboto  font-semibold`,
              `${titleSizeClass}`,
              `${titleCase}`,
            )}
          >
            {fields.title}
          </h2>
          {fields.titleUnderline && <TitleUnderline className="mt-[10px]" />}
        </div>
      )}

      {/* Body */}
      {richTextContent && fields.body && (
        <div>
          <RichTextStyledRenderer
            content={richTextContent}
            entryId={fields.body.sys.id}
            contentType={fields.body.sys.contentType.sys.id} // Pass content type here
            bulletStyling={bulletStyling} // Pass the bullet styling prop
            markerStyling={markerStyling} // Pass the marker styling prop
          />
        </div>
      )}

      {/* Tooltip */}
      {fields.toolTipLabel && fields.tooltip && (
        <div className="flex items-center gap-1">
          <TooltipWithRichText
            content={
              <RichTextStyledRenderer
                content={fields.tooltip}
                entryId={fields.tooltip.data.id}
                contentType="tooltip" // Add a default contentType for tooltips
              />
            }
          >
            <span className="cursor-pointer text-sm font-semibold underline">
              {fields.toolTipLabel}
            </span>
          </TooltipWithRichText>
        </div>
      )}

      {/* CTA */}
      {fields.cta && fields.cta.length > 0 && (
        <div className="flex flex-wrap gap-4">
          {fields.cta.map(cta => (
            <ContentfulCTA
              key={cta.sys.id}
              content={cta}
              {...inspectorProps({ entryId: content.sys.id, fieldId: "cta" })}
            />
          ))}
        </div>
      )}
    </div>
  );
};
