import InStockIcon from "~/components/ui/icons/in-stock-icon";
import LowStockIcon from "~/components/ui/icons/low-stock-icon";
import OutOfStockIcon from "~/components/ui/icons/out-of-stock-icon";

type Props = {
  stockStatus: string | undefined;
};

const StockStatusMessage = ({ stockStatus }: Props) => {
  switch (stockStatus) {
    case "inStock":
      return (
        <>
          <InStockIcon />
          <p> In stock</p>
        </>
      );
    case "lowStock":
      return (
        <>
          <LowStockIcon />
          <p>Low stock</p>
        </>
      );
    default:
      return (
        <>
          <OutOfStockIcon />
          <p>Out of stock</p>
        </>
      );
  }
};

export default StockStatusMessage;
