import type { ReactNode } from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  Form,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "@remix-run/react";

import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

import { useURL } from "~/contexts";
import { cn, useDebounce } from "~/lib/ui";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";
import { VehicleDrawer } from "~/routes/($locale)+/resources+/vehicle+/vehicle-search";

import { Button } from "../ui/button";
import { CustomCheckCircleIcon } from "./custom-checkmark-icon";

const STROKE_CAR_ICON_COLOR = "#FFF200";
const STROKE_COLOR = "white";

export const MeganavSearchBar = ({
  className,
  suggestions,
  isMobile,
}: {
  className?: string;
  suggestions?: (text: string) => ReactNode;
  isMobile?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const queryParam = searchParams.get("text");
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const url = useURL();
  const [value, setValue] = useState<string | null>(null);

  const { vehicle } = useRootLayoutData();

  useEffect(() => {
    clearInput(false);
  }, [pathname]);

  useEffect(() => {
    if (!searchInputRef.current) return;
    if (queryParam === "") return;

    if (searchInputRef.current?.value !== queryParam && queryParam) {
      searchInputRef.current.value = queryParam;
      setValue(queryParam);
    }
  }, [queryParam]);

  useEffect(() => {
    const blurrableElements = document.querySelectorAll("#blurrable-content");
    const headerContent = document.querySelector("#nav-content");
    const backdrop = document.querySelector("#backdrop-quick-search");

    if (backdrop) {
      backdrop.classList.add("hidden");
    }
    if (blurrableElements.length && !isSuggestionsOpen)
      blurrableElements.forEach(
        e => e.classList.remove("blur", "pointer-events-none"),
        headerContent?.classList.remove(
          "hover:bg-transparent",
          "focus-within:bg-transparent",
        ),
      );
    if (!isSuggestionsOpen) return;
    const closeSearch = (e: MouseEvent) => {
      if (e.target instanceof Element) {
        const isViewResultsBtnClicked = e.target.id === "view-all-results";
        const isLinkClicked = e.target.tagName.toLowerCase() === "a";
        const isSuggestionClicked =
          e.target.closest(".search-suggestion") !== null;

        if (
          (!searchContainerRef?.current?.contains(e.target) &&
            !isSuggestionClicked &&
            !isViewResultsBtnClicked) ||
          isSuggestionClicked ||
          isLinkClicked
        ) {
          setIsSuggestionsOpen(false);
        }
      }
    };
    const onKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") setIsSuggestionsOpen(false);
    };
    document.addEventListener("keydown", onKey);
    document.addEventListener("click", closeSearch);
    return () => {
      document.removeEventListener("keydown", onKey);
      document.removeEventListener("click", closeSearch);
    };
  }, [isSuggestionsOpen]);

  const debouncedValue = useDebounce(value?.trim() ?? "", 120);
  const debouncedLink = url(
    debouncedValue && `/search?text=${encodeURIComponent(debouncedValue)}`,
  );

  const suggestionsComponent = useMemo(() => {
    if (!suggestions) return null;
    return suggestions(debouncedValue);
  }, [suggestions, debouncedValue]);

  const clearInput = (focusInput = true) => {
    if (searchInputRef?.current) {
      searchInputRef.current.value = "";
      focusInput && searchInputRef.current.focus();
    }
    setValue(null);
  };

  const handleOpenVehicleSearch = () => {
    navigate(`${window.location.search}#drawer=vehicle`, {
      preventScrollReset: true,
      replace: true,
    });
  };
  const handleCloseVehicleSearch = () => {
    navigate(`${window.location.search}#drawer`, {
      preventScrollReset: true,
      replace: true,
    });
  };

  return (
    <div
      className={cn(
        "z-20  flex flex-1 items-center bg-white text-xs transition-height",
        isMobile && "h-26 animate-reveal",
        isSuggestionsOpen &&
          " relative mx-[-5px] mt-[-5px] h-[54px] rounded-md rounded-bl-none rounded-br-none px-1 pt-1",
        className,
      )}
    >
      <div
        className={cn(
          "flex flex-1 flex-col items-center justify-items-center bg-brand-primary-black",
          isSuggestionsOpen && "bg-white",
        )}
        ref={searchContainerRef}
      >
        <dl
          className={cn(
            "sm-max:block flex w-full",
            isSuggestionsOpen &&
              "box-border h-[50px] rounded-md border border-black shadow-inner ",
          )}
        >
          <dt className="sr-only">Search</dt>
          <dd className="relative w-full">
            <Form
              method="get"
              action={url("/search")}
              className="flex w-full flex-row"
            >
              <VehicleDrawer onClose={handleCloseVehicleSearch}>
                <Button
                  aria-label="Search"
                  variant="default"
                  type="button"
                  onClick={handleOpenVehicleSearch}
                  className={cn(
                    "flex h-12 min-w-[132px]  justify-start gap-1 rounded-br-none rounded-tr-none border-none px-1 hover:no-underline lg:min-w-[208px] lg:gap-3",
                    vehicle
                      ? "bg-button-primary hover:bg-button-primary active:bg-button-primary"
                      : "bg-brand-secondary hover:bg-button-secondary active:bg-button-secondary",
                  )}
                >
                  <div className="relative pl-[3px]">
                    <img src="/icons/car.svg" alt="car icon" className="w-6" />
                    <div className="absolute right-[-2px] top-[-4px] flex h-3 w-3 items-center justify-center rounded-full bg-brand-primary-black">
                      {vehicle ? (
                        <CustomCheckCircleIcon
                          width={12}
                          height={12}
                          stroke="#93D500"
                          fill="#121212"
                        />
                      ) : (
                        <PlusIcon
                          width={12}
                          height={12}
                          aria-hidden="true"
                          stroke={STROKE_CAR_ICON_COLOR}
                          strokeWidth={2}
                        />
                      )}
                    </div>
                  </div>
                  <span className="max-w-[132px] truncate text-xs font-bold text-brand-primary-black no-underline">
                    {vehicle ? vehicle?.desc : "Set Vehicle"}
                  </span>
                </Button>
              </VehicleDrawer>
              <input
                suppressHydrationWarning
                ref={searchInputRef}
                type="text"
                name="text"
                maxLength={50}
                onChange={e => {
                  const value = e.target.value;
                  if (value && value.length >= 3) {
                    setValue(value);
                    setIsSuggestionsOpen(true);
                  } else {
                    setValue(null);
                    setIsSuggestionsOpen(false);
                  }
                }}
                onFocus={e => {
                  const value = e.target.value;
                  if (value && value.length >= 3) {
                    setValue(value);
                    setIsSuggestionsOpen(true);
                  } else {
                    setValue(null);
                    setIsSuggestionsOpen(false);
                  }
                }}
                style={!value ? {} : { paddingRight: "1.5rem" }}
                id="search"
                defaultValue={searchParams.get("text") || ""}
                autoComplete="off"
                className="block h-12 w-full rounded-none bg-white px-3 py-2 text-base text-neutral-1 placeholder-neutral-1 focus:border-blue-400 focus:bg-white focus:outline-none"
                placeholder="Search for parts"
              />
              <div className="flex h-12 w-12 items-center rounded-md rounded-bl-none rounded-tl-none border-2 border-l-0 border-white bg-white md:ml-[-1px] md:border-l-2">
                <Button
                  aria-label="Search"
                  variant="linkInline"
                  type="submit"
                  className="flex h-11 w-11 items-center border-white bg-brand-primary-black hover:bg-neutral-2  "
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => {
                    if (value) {
                      setIsSuggestionsOpen(false);
                      return;
                    }
                    event.preventDefault();
                  }}
                >
                  <MagnifyingGlassIcon
                    aria-hidden="true"
                    width={24}
                    height={24}
                    stroke={STROKE_COLOR}
                    strokeWidth={0.5}
                  />
                </Button>
              </div>
            </Form>
            {debouncedLink && (
              <Link to={debouncedLink} prefetch="render" className="hidden">
                Search: {debouncedLink}
              </Link>
            )}
            {value && (
              <div className="absolute inset-y-0 right-12 flex items-center transition-opacity lg:right-[74px]">
                <Button
                  variant="linkInline"
                  onClick={() => {
                    clearInput();
                    setIsSuggestionsOpen(false);
                  }}
                >
                  <XMarkIcon className="h-5 w-5 text-brand-primary-black" />
                </Button>
              </div>
            )}
          </dd>
        </dl>
        {suggestionsComponent && isSuggestionsOpen && (
          <div className=" sm-max:block sm-max:shadow-none  absolute left-0 top-[50px] z-[-10] flex max-h-screen-no-nav w-full flex-row justify-between overflow-auto rounded-lg rounded-t-md rounded-tl-none rounded-tr-none bg-white px-2 pt-2">
            {suggestionsComponent}
          </div>
        )}
      </div>
    </div>
  );
};
MeganavSearchBar.displayName = "Meganav.MeganavSearchBar";
