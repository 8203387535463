import type { OrderEntry } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { Text } from "~/components/ui/text";

type Props = {
  productBasketEntry: OrderEntry;
};

const CartPriceDisplay = ({ productBasketEntry }: Props) => {
  return (
    <div className="flex justify-between">
      <div>
        <Text className="self-start text-sm font-bold">
          {productBasketEntry.product?.rrpPrice?.formattedValue} ea
        </Text>
        {productBasketEntry.product?.loyaltyPrice &&
          productBasketEntry.product.loyaltyPrice.value && (
            <div className="mt-1">
              <Text className="text-sm font-bold text-info">
                ${productBasketEntry.product.loyaltyPrice.value.toFixed(2)}
              </Text>
              <div className="mt-[2px] w-fit rounded-[4px] rounded-bl-none rounded-tr-none bg-brand-primary-black px-1 py-[2px]">
                <Text className="text-[10px] font-bold leading-lg text-white">
                  member <span className="text-brand-secondary">price</span>
                </Text>
              </div>
            </div>
          )}
      </div>
      <div>
        <Text className="text-xl font-bold">
          {productBasketEntry.totalPrice?.formattedValue ?? 0}
        </Text>
      </div>
    </div>
  );
};

export default CartPriceDisplay;
