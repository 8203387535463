import { cn } from "~/lib/ui";

import { MemberPrice, RRPrice, SalePrice } from "./price-utils";

type PriceDisplayProps = {
  isMember: boolean | undefined;
  rrpPrice: number;
  salePrice?: number | undefined;
  memberPrice?: number;
  className?: string;
  isSearchSuggestion?: boolean;
};

const PriceDisplay = ({
  isMember,
  rrpPrice,
  salePrice,
  memberPrice,
  className,
  isSearchSuggestion = false,
}: PriceDisplayProps) => {
  const bestPrice = Math.min(
    rrpPrice,
    salePrice ?? rrpPrice,
    memberPrice ?? rrpPrice,
  );

  // Check whether sale and member prices are equal
  const pricesAreEqual =
    salePrice === memberPrice && !!salePrice && !!memberPrice;

  if (pricesAreEqual) {
    return (
      <>
        <SalePrice price={salePrice} />
        <RRPrice price={rrpPrice} lineThrough={true} />
      </>
    );
  }

  if (!!memberPrice && !!salePrice && memberPrice < salePrice) {
    return (
      <>
        <MemberPrice
          isSearchSuggestion={isSearchSuggestion}
          price={memberPrice}
          isMember={true}
        />
        <SalePrice price={salePrice} lineThrough={isMember} />
        <RRPrice price={rrpPrice} lineThrough={true} />
      </>
    );
  }

  return (
    <div className={cn("mb-4", className)}>
      {!salePrice && !memberPrice ? (
        <>
          {salePrice && !isMember && <SalePrice price={salePrice} />}
          {memberPrice && isMember && (
            <MemberPrice
              isSearchSuggestion={isSearchSuggestion}
              price={memberPrice}
              isMember={isMember}
            />
          )}
          {<RRPrice price={rrpPrice} lineThrough={pricesAreEqual} />}
        </>
      ) : (
        <>
          {!isMember && !salePrice && <RRPrice price={rrpPrice} />}
          {((salePrice && bestPrice === salePrice) ||
            (!isMember && salePrice)) && (
            <>
              <SalePrice price={salePrice} />
              <RRPrice price={rrpPrice} lineThrough={true} />
            </>
          )}
          {memberPrice && bestPrice === memberPrice && (
            <MemberPrice
              isSearchSuggestion={isSearchSuggestion}
              price={memberPrice}
              isMember={isMember}
            />
          )}
          {(!salePrice || !memberPrice) &&
            isMember &&
            bestPrice !== salePrice && (
              <RRPrice price={rrpPrice} lineThrough={true} />
            )}
          {!!salePrice && !!memberPrice && bestPrice !== salePrice && (
            <RRPrice price={rrpPrice} lineThrough={true} />
          )}
        </>
      )}
    </div>
  );
};

export default PriceDisplay;
