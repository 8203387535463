import { Link } from "@remix-run/react";

import Breadcrumbs from "@commerce/components/breadcrumbs";

import EmailIcon from "~/components/ui/icons/email-icon";
import { Text } from "~/components/ui/text";
import { useURL } from "~/contexts";

type Props = {
  isFlyoutMode?: boolean;
};
const ForgotPasswordSuccessForm = ({ isFlyoutMode = false }: Props) => {
  const url = useURL();
  return (
    <>
      <div className="flex-1">
        {!isFlyoutMode && <Breadcrumbs pageName={"Forgot Password"} />}

        <EmailIcon className="!mt-2" />
        <Text className="!mt-2 font-bold sm:text-2xl md:text-2xl lg:text-product-price">
          Email sent
        </Text>

        <Text className="!mt-2">
          Your password reset instructions have been sent to your email address.
        </Text>
        <Text>
          Please contact{" "}
          <Link className="underline" to={url("/pages/contact-us")}>
            customer support
          </Link>{" "}
          if you require additional assistance.
        </Text>
      </div>
      {!isFlyoutMode && (
        <div className="w-full flex-1">{/*TODO: Add content here*/}</div>
      )}
    </>
  );
};

export default ForgotPasswordSuccessForm;
