import type { IconProps } from "../../types";

const PinIcon = ({ size = 32, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0066 16C16.6686 16 17.2329 15.7643 17.6996 15.293C18.1663 14.8216 18.3996 14.255 18.3996 13.593C18.3996 12.931 18.1639 12.3666 17.6926 11.9C17.2213 11.4333 16.6546 11.2 15.9926 11.2C15.3306 11.2 14.7663 11.4356 14.2996 11.907C13.8329 12.3783 13.5996 12.945 13.5996 13.607C13.5996 14.269 13.8353 14.8333 14.3066 15.3C14.7779 15.7666 15.3446 16 16.0066 16ZM15.9996 28.8C12.5114 25.8864 9.90594 23.1803 8.18328 20.6816C6.46083 18.1827 5.59961 15.8555 5.59961 13.7C5.59961 10.7222 6.5885 8.22773 8.56628 6.21662C10.5441 4.20551 13.0163 3.19995 15.9829 3.19995C18.9496 3.19995 21.4274 4.20551 23.4163 6.21662C25.4052 8.22773 26.3996 10.7222 26.3996 13.7C26.3996 15.8555 25.5441 18.1777 23.8329 20.6666C22.1218 23.1555 19.5107 25.8666 15.9996 28.8Z"
        fill="#141414"
      />
    </svg>
  );
};

export default PinIcon;
