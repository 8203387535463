import type { ReactNode } from "react";
import { type Toast, toast } from "react-hot-toast";

import { Cross2Icon } from "@radix-ui/react-icons";

import { Text } from "../ui/text";
import { TickIcon } from "../ui/tick";

type Props = {
  message: string;
  t: Toast;
  children?: ReactNode;
  childrenClassname?: string;
};
const ToastNeutral = ({ message, t, children, childrenClassname }: Props) => {
  return (
    <div className="flex max-w-[312px] flex-col rounded-lg bg-white p-4 shadow-lg md:max-w-[363px]">
      <div className="flex items-center justify-between gap-4">
        <TickIcon color="#4CAF50" width="20" height="16" />
        <Text className="text-sm font-normal">{message}</Text>
        <button onClick={() => toast.remove(t.id)} className="self-start">
          <Cross2Icon width={24} height={24} />
        </button>
      </div>
    </div>
  );
};

export default ToastNeutral;
