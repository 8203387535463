import * as React from "react";

import type { InputProps } from "./input";
import { Input } from "./input";

// Adjust the import path as needed
interface Props extends InputProps {
  icon?: React.ReactNode;
}

const SearchInput = React.forwardRef<HTMLInputElement, Props>(
  ({ className, icon, ...props }, ref) => {
    return (
      <div className="relative w-full">
        <Input
          placeholder="Search"
          className={className}
          ref={ref}
          data-testid="search-input"
          {...props}
        />
        {icon}
      </div>
    );
  },
);
SearchInput.displayName = "SearchInput";

export { SearchInput };
