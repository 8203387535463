import React from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import type { NavLinkProps } from "@remix-run/react";
import { NavLink, useLocation } from "@remix-run/react";

import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";

import { cn } from "~/lib/ui";

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={cn(
      "relative z-10 flex max-w-max flex-1 items-center justify-center",
      className,
    )}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Root>
));
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={cn(
      "group flex flex-1 list-none items-center justify-center space-x-1",
      className,
    )}
    {...props}
  />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = NavigationMenuPrimitive.Item;

const navigationMenuTriggerStyle = tv({
  base: "group inline-flex h-9 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50",
});

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={cn(navigationMenuTriggerStyle(), "group", className)}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Trigger>
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={cn(
      "w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:w-auto ",
      className,
    )}
    {...props}
  />
));
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavLink>,
  Omit<NavLinkProps, "className"> & { className?: string; isMainMenu?: boolean }
>(({ className, children, prefetch, isMainMenu, ...props }, ref) => {
  const href = useLocation().pathname;
  const isActive = href === props.to;
  const underlineActiveItem = isMainMenu && isActive;

  return (
    <NavLink
      ref={ref}
      prefetch={prefetch ?? "render"}
      className={() =>
        cn(
          "relative h-7 data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52",
          className,
          underlineActiveItem && "underline underline-offset-4",
        )
      }
      {...props}
    >
      {children}
    </NavLink>
  );
});
NavigationMenuLink.displayName = "NavigationMenuLink";

const ViewPortVariants = tv({
  slots: {
    container: "absolute left-0 top-full flex justify-center -shadow",
    viewport:
      "relative h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border bg-popover text-popover-foreground md:w-[var(--radix-navigation-menu-viewport-width)] ",
  },
  variants: {
    shadow: {
      true: {
        viewport: "shadow",
      },
    },
    background: {
      transparent: {
        container: "bg-transparent",
        viewport: "bg-transparent",
      },
      contrast: {
        container: "bg-panel-translucent",
      },
    },
    placement: {
      wideCenter: {
        container: " w-[100%] -border",
        viewport: " -border bg-panel-translucent",
      },
      center: {},
    },
    animation: {
      none: {
        viewport:
          "h-0 data-[state=open]:h-[var(--radix-navigation-menu-viewport-height)]",
      },
      zoomIn: {
        viewport:
          "origin-top-center  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90",
      },

      heightReveal: {
        viewport:
          "origin-top-center h-0 data-[state=open]:h-[var(--radix-navigation-menu-viewport-height)] transition-[height] ease-in-out",
      },
    },
  },
  defaultVariants: {
    animation: "heightReveal",
    placement: "wideCenter",
    shadow: false,
    background: "contrast",
  },
});
export type ViewPortVariantsType = VariantProps<typeof ViewPortVariants>;

const NavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  {
    contailerClassName?: string;
  } & ViewPortVariantsType &
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, contailerClassName, ...props }, ref) => {
  const { container, viewport } = ViewPortVariants({
    ...props,
  });
  return (
    <div className={container({ className: contailerClassName })}>
      <NavigationMenuPrimitive.Viewport
        className={viewport({ className })}
        ref={ref}
        {...props}
      />
    </div>
  );
});
NavigationMenuViewport.displayName =
  NavigationMenuPrimitive.Viewport.displayName;

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className={cn(
      "top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
      className,
    )}
    {...props}
  >
    <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-border shadow-md" />
  </NavigationMenuPrimitive.Indicator>
));
NavigationMenuIndicator.displayName =
  NavigationMenuPrimitive.Indicator.displayName;

export {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuViewport,
};
