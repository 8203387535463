import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useFetchers, useLocation } from "@remix-run/react";

import { Cross2Icon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";

import type {
  PartsFitment,
  VehicleField,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { isValidationError } from "~/components/forms/validationErrorResponse";
import ToastNeutral from "~/components/toasts/toast-neutral";
import { CarIcon } from "~/components/ui/car";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "~/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { TriButton } from "~/components/ui/tri-button";
import { processFetcherData, useVehicleState } from "~/hooks/use-vehicle-state";
import { ERR_MESSAGES } from "~/lib/constants";
import { useOnActionCompleted } from "~/lib/remix/fetcher";
import { cn } from "~/lib/ui";
import { useMediaScreen } from "~/lib/utils/screens";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";

import { doesVehicleHaveMatchingProducts } from "../utils/vehicle-utils";
import { useMakesVehicle } from "../vehicle-manual.$type";
import {
  useLoadVehicleRego,
  useLoadVehicleRegoActionPath,
} from "../vehicle-search";
import { VehicleList } from "./vehicle-list";
import { ManualSearch } from "./vehicle-manual-search";
import { RegoSearch } from "./vehicle-rego-search";
import { VehicleSelected } from "./vehicle-selected";

export function VehicleDrawer({
  onClose,
  children,
}: {
  onClose: () => void;
  children: React.ReactNode;
}) {
  const location = useLocation();
  const { vehicleKey } = useRootLayoutData();
  const [isOpen, setIsOpen] = useState(
    location.hash.toLocaleLowerCase().includes("drawer=vehicle"),
  );
  const removeActionPath = useLoadVehicleRegoActionPath("removeVehicle");
  const vehicleRegoActionPath = useLoadVehicleRegoActionPath("setVehicle");
  const inprogress = useFetchers().find(
    fetcher =>
      fetcher.state !== "idle" && fetcher.formAction === removeActionPath,
  );
  const { isMobile } = useMediaScreen();

  useEffect(() => {
    if (
      inprogress?.state === "submitting" &&
      inprogress?.formData?.get("type") === "remove"
    ) {
      /* We will optimistically close the drawer but not redirecting so loaders are fetching */
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inprogress?.state, inprogress?.formData?.get("type")]);

  useEffect(() => {
    setIsOpen(location.hash.toLocaleLowerCase().includes("drawer=vehicle"));
  }, [location.hash]);

  useOnActionCompleted<{ result: PartsFitment; addedVehicle: string }>(
    vehicleRegoActionPath,
    data => {
      if (isValidationError(data)) return;
      if (data && "addedVehicle" in data && data.addedVehicle) {
        toast.custom(
          t => (
            <div className="absolute top-[-100px] md:top-[-40px]">
              <ToastNeutral
                t={t}
                message="Your vehicle has been successfully added."
              />
            </div>
          ),
          {
            position: isMobile ? "top-center" : "top-left",
            id: "vehicle-added",
            duration: 3000,
          },
        );
      }
    },
  );

  useOnActionCompleted<{ success: boolean }>(
    removeActionPath,
    (data, submitFetcher) => {
      if (isValidationError(data)) return;

      const type = submitFetcher?.formData?.get("type");
      if (data && "success" in data && data.success) {
        toast.custom(
          t => (
            <div className="absolute top-[-100px] md:top-[-40px]">
              <ToastNeutral
                t={t}
                message="Your vehicle has been successfully deleted."
              />
            </div>
          ),
          {
            position: isMobile ? "top-center" : "top-left",
            id: "vehicle-deleted",
            duration: 3000,
          },
        );

        if (type !== "change") {
          onClose();
        }
      }
    },
  );

  return (
    <Sheet open={isOpen} onOpenChange={isOpen => (!isOpen ? onClose() : null)}>
      <div
        onClick={e => e.preventDefault()}
        onKeyDown={e => e.preventDefault()}
        tabIndex={-1}
        role="button"
      >
        <SheetTrigger asChild>{children}</SheetTrigger>
      </div>
      <SheetContent
        className="z-[100] flex h-full min-h-full flex-col bg-white sm:max-w-sm md:max-w-lg"
        side="left"
        onCloseAutoFocus={e => e.preventDefault()}
      >
        <VehicleDrawerRender
          key={
            vehicleKey ??
            "empty" /* we must set a key as vehicle might be undefined */
          }
        />
      </SheetContent>
    </Sheet>
  );
}
export function VehicleDrawerRender() {
  const { vehicle } = useRootLayoutData();
  const [plateValue, setPlateValue] = useState("");
  const [hasError, setHasError] = useState(false);
  const [selectedState, setSelectedState] = useState<string>("");
  const [isManualSearch, setIsManualSearch] = useState<boolean>(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    state,
    setMake,
    setModel,
    setYear,
    setSeries,
    setEngine,
    setDetails,
    setVehicleMakes,
    setVehicleModels,
    setVehicleYears,
    setVehicleSeries,
    setVehicleEngines,
    setVehicleDetails,
  } = useVehicleState();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlateValue(event.target.value.toLocaleUpperCase());
    if (hasError) {
      setHasError(false);
    }
  };

  const vehichleRegoSearch = useLoadVehicleRego();
  const vehichleRegoSearchData = isValidationError(
    vehichleRegoSearch.fetcher.data,
  )
    ? undefined
    : vehichleRegoSearch.fetcher.data;
  const resultCount = vehichleRegoSearchData?.searchStatus?.results;
  const { fetcher } = useMakesVehicle();
  const vehicleRegoActionPath = useLoadVehicleRegoActionPath("setVehicle");

  useEffect(() => {
    const hasNoResults = resultCount === "0";
    const vehicles = vehichleRegoSearchData?.vehicles;
    const vehiclesMatch = vehicles && doesVehicleHaveMatchingProducts(vehicles);

    if (hasNoResults || (vehicles && !vehiclesMatch)) {
      setErrorMessage(ERR_MESSAGES.noVehicle);
      setHasError(true);
    } else if (resultCount && resultCount !== "0" && vehiclesMatch) {
      setIsOpenDetails(true);
    }
  }, [resultCount, vehichleRegoSearchData]);

  useEffect(() => {
    if (fetcher.data && !isValidationError(fetcher.data)) {
      const processedData = processFetcherData(fetcher.data);

      if (processedData.type === "VehicleList") {
        if (processedData.data) {
          setVehicleDetails(processedData.data);
        } else {
          setVehicleDetails([]);
        }
      } else if (processedData.type === "VehicleFieldList") {
        const { data: vehicleFieldsData, responseField } = processedData;
        if (vehicleFieldsData && responseField) {
          const options = vehicleFieldsData
            .filter(
              (item): item is VehicleField & { id: string; name: string } =>
                item.id !== undefined && item.name !== undefined,
            )
            .map(item => ({
              value: item.id,
              label: item.name,
            }));

          switch (responseField) {
            case "make":
              setVehicleMakes(options);
              break;
            case "model":
              setVehicleModels(options);
              break;
            case "year":
              setVehicleYears(options);
              break;
            case "series":
              setVehicleSeries(options);
              break;
            case "engine":
              setVehicleEngines(options);
              break;
            // Handle other cases if needed
          }
        }
      }
    }
  }, [fetcher.data]);

  const closeDetails = () => {
    setIsOpenDetails(false);
  };
  useOnActionCompleted<{ result: PartsFitment; addedVehicle: string }>(
    vehicleRegoActionPath,
    data => {
      if (data && "partList" in data && !data.partList) {
        setErrorMessage(ERR_MESSAGES.noParts);
        setHasError(true);
        closeDetails();
        return;
      }
    },
  );

  return (
    <div className="flex flex-grow flex-col overflow-y-scroll">
      <div
        className={cn(
          "sticky top-0 z-50 flex h-[70px] w-full items-center gap-2 py-[9px] pl-[18px] pr-6 align-middle",
          {
            "bg-brand-primary": vehicle,
            "bg-heading": !vehicle,
          },
        )}
      >
        <div>
          <CarIcon fill="#121212" />
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex max-w-sm flex-col">
            <Label className="text-base font-bold ">My Vehicle</Label>
            {vehicle && (
              <Label className="text-sm ">
                {vehicle?.desc}
                {vehicle.rego && " " + vehicle.rego}
              </Label>
            )}
          </div>
          <SheetClose>
            <Cross2Icon
              color="#141414"
              width={32}
              height={32}
              viewBox="0 0 15.25 15.25"
            />
          </SheetClose>
        </div>
      </div>
      <div className={`flex-grow`}>
        {vehicle ? (
          <VehicleSelected vehicle={vehicle} />
        ) : isOpenDetails &&
          vehichleRegoSearchData &&
          vehichleRegoSearchData.rego &&
          vehichleRegoSearchData.vehicles ? (
          <VehicleList
            onClose={closeDetails}
            rego={vehichleRegoSearchData.rego}
            vehicles={vehichleRegoSearchData.vehicles}
          />
        ) : (
          <div className="flex flex-col overflow-y-auto px-6 py-4 lg:gap-1 xl:gap-4">
            <header className="flex flex-col gap-1">
              <Label className="font-bold leading-[32.8px] sm:text-xl lg:text-product-price">
                Add your vehicle
              </Label>
              <Label className="text-base font-normal text-neutral-1">
                Find parts designed to fit your vehicle
              </Label>
            </header>

            <Tabs
              defaultValue="rego-search"
              onValueChange={value => {
                setIsManualSearch(value === "manual-search");
                setHasError(false);
              }}
            >
              <TabsList className="lg:h-13 mb-2 flex h-11 gap-1 rounded bg-neutral-9 px-1 py-1 xl:h-14">
                <TabsTrigger
                  value="rego-search"
                  className={cn(
                    "flex h-full w-1/2 items-center justify-center rounded-sm border-0 text-xs leading-4 text-black md:font-bold lg:font-extrabold",
                  )}
                >
                  Rego Search
                </TabsTrigger>
                <TabsTrigger
                  value="manual-search"
                  className={cn(
                    "flex h-full w-1/2 items-center justify-center rounded-sm border-0 text-[12px] leading-[15.6px] text-black md:font-bold lg:font-extrabold",
                  )}
                >
                  Manual Search
                </TabsTrigger>
              </TabsList>

              <TabsContent value="rego-search">
                <RegoSearch
                  setHasError={setHasError}
                  plateValue={plateValue}
                  handleInputChange={handleInputChange}
                  selectedState={selectedState}
                  setSelectedState={state => {
                    setHasError(false);
                    setSelectedState(state);
                  }}
                  hasError={hasError}
                  errorMessage={errorMessage}
                />
              </TabsContent>

              <TabsContent
                className="data-[state=inactive]:hidden"
                value="manual-search"
                forceMount
              >
                <ManualSearch
                  state={state}
                  setMake={setMake}
                  setModel={setModel}
                  setYear={setYear}
                  setSeries={setSeries}
                  setEngine={setEngine}
                  setDetails={setDetails}
                />
              </TabsContent>
            </Tabs>
          </div>
        )}
      </div>

      {!isOpenDetails && !isManualSearch && !vehicle ? (
        <div className="sticky bottom-0 w-full bg-white p-6 shadow-lg shadow-black/90 md:py-4">
          <TriButton
            form="form-rego-search"
            className={`h-11 w-full border-0 font-extrabold hover:bg-neutral-2 hover:no-underline ${
              hasError || !plateValue || !selectedState
                ? "bg-neutral-9 text-neutral-3"
                : ""
            }`}
            disabled={hasError || !plateValue || !selectedState}
            isLoading={vehichleRegoSearch.fetcher.state !== "idle"}
          >
            Find My Vehicle
          </TriButton>
        </div>
      ) : (
        !isOpenDetails &&
        isManualSearch &&
        !vehicle && (
          <div className="sticky bottom-0 w-full bg-white p-6 shadow-lg shadow-black/90 md:py-4">
            <fetcher.Form
              action={vehicleRegoActionPath}
              className="flex flex-col"
              method="post"
            >
              <GlobalErrors data={fetcher.data} />
              <TriButton
                isLoading={
                  fetcher.state !== "idle" &&
                  fetcher.formAction === vehicleRegoActionPath
                }
                disabled={!state.details?.desc}
                className={`h-11 w-full border-0 font-extrabold hover:bg-neutral-2 hover:no-underline ${
                  !state.details?.desc && "bg-neutral-9 text-neutral-3"
                }`}
              >
                Find My Vehicle
              </TriButton>
              {Object.entries(state.details).map(([key, value]) => (
                <input key={key} type="hidden" name={key} value={value} />
              ))}
            </fetcher.Form>
          </div>
        )
      )}
    </div>
  );
}
