import { Link } from "@remix-run/react";

import { Separator } from "@radix-ui/react-separator";

import type { Cart } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useFormatPrice } from "~/commerce-sap/shop/utils/price";
import { Price } from "~/components/price/price";
import { Button } from "~/components/ui/button";
import { Text } from "~/components/ui/text";
import { useURL } from "~/contexts";
import giftCardCheckout from "~/routes/($locale)+/_._checkout+/utils/gift-card-checkout";
import CouponCodeSummary from "~/routes/($locale)+/_cart+/components/cart-coupon-code-summary";

type Props = {
  basket: Cart;
};

const MiniCartSummary = ({ basket }: Props) => {
  const url = useURL();
  const formatPrice = useFormatPrice();
  const productSubtotal = basket?.entries?.reduce(
    (acc, currentValue) => acc + (currentValue?.totalPrice?.value || 0),
    0,
  );

  const isGiftCardCart = giftCardCheckout(basket);

  return (
    <div className="w-full basis-0">
      <div className="flex w-full flex-col gap-4 px-6 py-4 shadow-vehicleSearchDrawer ">
        <div className="flex flex-col gap-[10px]">
          <div className="flex justify-between">
            <Text className="text-sm font-normal">Subtotal</Text>
            <Text className="text-sm font-bold">
              {formatPrice(productSubtotal)}
            </Text>
          </div>
          <div className="flex justify-between">
            <Text className="text-sm font-normal capitalize">
              {basket.deliveryModeType ||
                (isGiftCardCart
                  ? "Digital Delivery"
                  : "Delivery/Click & Collect")}
            </Text>
            <Text className="text-sm font-bold">
              {!basket.deliveryModeType
                ? isGiftCardCart
                  ? "FREE"
                  : "TBC"
                : !basket.deliveryCost?.value ||
                    basket.deliveryCost?.value === 0
                  ? "FREE"
                  : basket.deliveryCost?.formattedValue}
            </Text>
          </div>
          {basket.giftcardPaymentInfo && basket.giftcardPaymentInfo.amount && (
            <div className="flex justify-between">
              <Text className="text-sm font-bold text-success">
                Gift Card Redeemed
              </Text>
              <Text className="text-sm font-bold text-success">
                -${basket.giftcardPaymentInfo.amount.toFixed(2)}
              </Text>
            </div>
          )}

          {basket.loyaltyAmount && (
            <div className="flex justify-between">
              <Text className="text-sm font-normal text-success">
                Loyalty Redeemed
              </Text>
              <Text className="text-sm font-normal text-success">
                -{basket.loyaltyAmount.formattedValue}
              </Text>
            </div>
          )}
          {basket.appliedVouchersList &&
            basket.appliedVouchersList?.map(voucher => (
              <CouponCodeSummary appliedVoucher={voucher} key={voucher.code} />
            ))}

          <div className="flex justify-between">
            <Text className="text-sm font-normal">GST</Text>
            <Text className="text-sm font-bold">
              <Price price={basket.totalTax!} />
            </Text>
          </div>
        </div>
        <Separator
          className="h-[1px] w-full bg-neutral-7"
          decorative
          orientation="horizontal"
        />
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <Text className="text-xl font-bold">Total</Text>
            <Text className="text-xl font-bold">
              <Price price={basket.totalPrice!} />
            </Text>
          </div>
          <Button
            as={Link}
            to={url("/cart")}
            prefetch="intent"
            className="h-[44px] px-6 py-[9px] hover:border-darkgray hover:bg-neutral-2 lg:h-[52px]"
          >
            View Cart & Checkout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MiniCartSummary;
