import { Command as CommandPrimitive } from "cmdk";
import { useMemo, useState } from "react";

import { CheckIcon } from "@radix-ui/react-icons";
import { PopoverAnchor } from "@radix-ui/react-popover";

import { cn } from "~/lib/ui";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent } from "./popover";
import { SearchInput } from "./search-input";
import { Skeleton } from "./skeleton";

type Props<T extends string> = {
  selectedValue: T;
  onSelectedValueChange: (value: T) => void;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  suggestions: { value: T; label: string }[];
  isLoading?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  className?: string;
  shouldSearchValueChange?: boolean;
} & React.ComponentProps<typeof SearchInput>;

export function SearchAutoComplete<T extends string>({
  selectedValue,
  onSelectedValueChange,
  searchValue,
  onSearchValueChange,
  suggestions: items,
  shouldSearchValueChange = true,
  isLoading,
  emptyMessage = "No items.",
  placeholder = "Search...",
  ...props
}: Props<T>) {
  const [open, setOpen] = useState(false);

  const labels = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          acc[item.value] = item.label;
          return acc;
        },
        {} as Record<string, string>,
      ),
    [items],
  );

  const reset = () => {
    //Could not find a reason for this to be here, leaving it for now. If it creates a bug, uncomment the following lines
    // onSelectedValueChange("" as T);
    // onSearchValueChange("");
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      !e.relatedTarget?.hasAttribute("cmdk-list") &&
      labels[selectedValue] !== searchValue
    ) {
      reset();
    }
  };

  const onSelectItem = (inputValue: string) => {
    if (inputValue === selectedValue) {
      reset();
    } else {
      onSelectedValueChange(inputValue as T);
      if (shouldSearchValueChange)
        onSearchValueChange(labels[inputValue] ?? "");
    }
    setOpen(false);
  };

  return (
    <div className="flex items-center">
      <Popover open={open} onOpenChange={setOpen}>
        <Command shouldFilter={false} loop>
          <PopoverAnchor asChild>
            <SearchInput
              value={searchValue}
              onChange={e => onSearchValueChange(e.target.value)}
              onKeyDown={e => setOpen(e.key !== "Escape")}
              onMouseDown={() => setOpen(open => !!searchValue || !open)}
              onFocus={() => setOpen(true)}
              onBlur={onInputBlur}
              placeholder={placeholder}
              {...props}
            />
          </PopoverAnchor>
          {!open && <CommandList aria-hidden="true" className="hidden" />}
          <PopoverContent
            asChild
            onOpenAutoFocus={e => e.preventDefault()}
            onInteractOutside={e => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault();
              }
            }}
            className="z-[120] w-[--radix-popover-trigger-width] p-0"
          >
            <CommandList>
              {isLoading && (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-6 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              )}
              {items.length > 0 && !isLoading ? (
                <CommandGroup>
                  {items.map(option => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onMouseDown={e => e.preventDefault()}
                      onSelect={onSelectItem}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedValue === option.value
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ) : null}
              {!isLoading && searchValue.length >= 3 && items.length == 0 ? (
                <CommandEmpty>{emptyMessage}</CommandEmpty>
              ) : null}
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </div>
  );
}
