import { useMemo } from "react";

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import { useURL } from "~/contexts";

import type { AlgoliaSearchProductSuggestion } from "../../resources+/search-suggestions";

export const useProductUrl = (
  product: AlgoliaSearchResultHit | AlgoliaSearchProductSuggestion,
) => {
  const url = useURL();
  const productURL = useMemo(() => {
    if (product.breadcrumb) {
      const last = product.breadcrumb[product.breadcrumb.length - 1];
      if ("linkClass" in last && last.linkClass === "active") {
        return last.url || `/${product.code}.html`;
      }
    }
    return `/${product.code}.html`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.code]);
  return url(productURL);
};
