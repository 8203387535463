export const CartStyledIcon = ({ iconFill = "#848484" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.09397 21.6004C6.59747 21.6004 6.17422 21.4236 5.82422 21.0701C5.47422 20.7166 5.29922 20.2916 5.29922 19.7951C5.29922 19.2986 5.47597 18.8754 5.82947 18.5254C6.18297 18.1754 6.60797 18.0004 7.10447 18.0004C7.60097 18.0004 8.02422 18.1771 8.37422 18.5306C8.72422 18.8841 8.89922 19.3091 8.89922 19.8056C8.89922 20.3021 8.72247 20.7254 8.36897 21.0754C8.01547 21.4254 7.59047 21.6004 7.09397 21.6004ZM17.894 21.6004C17.3975 21.6004 16.9742 21.4236 16.6242 21.0701C16.2742 20.7166 16.0992 20.2916 16.0992 19.7951C16.0992 19.2986 16.276 18.8754 16.6295 18.5254C16.983 18.1754 17.408 18.0004 17.9045 18.0004C18.401 18.0004 18.8242 18.1771 19.1742 18.5306C19.5242 18.8841 19.6992 19.3091 19.6992 19.8056C19.6992 20.3021 19.5225 20.7254 19.169 21.0754C18.8155 21.4254 18.3905 21.6004 17.894 21.6004ZM6.82422 6.60039L8.89922 11.4004H16.4242L18.4742 6.60039H6.82422ZM6.04922 4.80039H20.2992C20.5326 4.80039 20.7034 4.89206 20.8117 5.07539C20.9201 5.25872 20.9326 5.45039 20.8492 5.65039L18.065 12.0969C17.9211 12.4326 17.7034 12.7004 17.4117 12.9004C17.1201 13.1004 16.7909 13.2004 16.4242 13.2004H8.42422L7.37422 15.0004H19.6992V16.8004H7.39922C6.68255 16.8004 6.15339 16.4991 5.81172 15.8966C5.47005 15.294 5.47422 14.6952 5.82422 14.1004L7.12422 11.8504L3.84922 4.20039H1.69922V2.40039H5.02422L6.04922 4.80039Z"
        fill={iconFill}
      />
    </svg>
  );
};
