import { useReducer } from "react";

import type {
  Vehicle,
  VehicleFieldList,
  VehicleList,
} from "~/commerce-sap/.server/api/generated/__generated_apis";

export type Option = { value: string; label: string };

type State = {
  make: string;
  model: string;
  year: string;
  series: string;
  engine: string;
  details: Vehicle;
  vehicleMakes: Option[];
  vehicleModels: Option[]; // Add vehicleModels to state
  vehicleYears: Option[]; // Add vehicleYears to state
  vehicleSeries: Option[]; // Add vehicleSeries to state
  vehicleEngines: Option[]; // Add vehicleEngines to state
  vehicleDetails: Vehicle[]; // Add vehicleDetails to state
};

type Action =
  | { type: "SET_MAKE"; payload: string }
  | { type: "SET_MODEL"; payload: string }
  | { type: "SET_YEAR"; payload: string }
  | { type: "SET_SERIES"; payload: string }
  | { type: "SET_ENGINE"; payload: string }
  | { type: "SET_DETAILS"; payload: Vehicle }
  | { type: "SET_VEHICLE_MAKES"; payload: Option[] }
  | { type: "SET_VEHICLE_MODELS"; payload: Option[] }
  | { type: "SET_VEHICLE_YEARS"; payload: Option[] }
  | { type: "SET_VEHICLE_SERIES"; payload: Option[] }
  | { type: "SET_VEHICLE_ENGINES"; payload: Option[] }
  | { type: "SET_VEHICLE_DETAILS"; payload: Vehicle[] };

const initialState: State = {
  make: "",
  model: "",
  year: "",
  series: "",
  engine: "",
  details: {},
  vehicleMakes: [],
  vehicleModels: [],
  vehicleYears: [],
  vehicleSeries: [],
  vehicleEngines: [],
  vehicleDetails: [],
};

export const processFetcherData = (
  fetcherData: VehicleFieldList | VehicleList,
) => {
  if ("vehicles" in fetcherData) {
    // It's a VehicleList response
    return {
      type: "VehicleList" as const,
      data: fetcherData.vehicles,
    };
  } else if ("fieldResults" in fetcherData) {
    // It's a VehicleFieldList response
    return {
      type: "VehicleFieldList" as const,
      data: fetcherData.fieldResults,
      responseField: fetcherData.responseField,
    };
  } else {
    // If neither, return null or throw an error
    return {
      type: "Unknown" as const,
      data: null,
    };
  }
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_MAKE":
      return { ...state, make: action.payload };
    case "SET_MODEL":
      return { ...state, model: action.payload };
    case "SET_YEAR":
      return { ...state, year: action.payload };
    case "SET_SERIES":
      return { ...state, series: action.payload };
    case "SET_ENGINE":
      return { ...state, engine: action.payload };
    case "SET_DETAILS":
      return { ...state, details: action.payload };
    case "SET_VEHICLE_MAKES":
      return { ...state, vehicleMakes: action.payload };
    case "SET_VEHICLE_MODELS":
      return { ...state, vehicleModels: action.payload };
    case "SET_VEHICLE_YEARS":
      return { ...state, vehicleYears: action.payload };
    case "SET_VEHICLE_SERIES":
      return { ...state, vehicleSeries: action.payload };
    case "SET_VEHICLE_ENGINES":
      return { ...state, vehicleEngines: action.payload };
    case "SET_VEHICLE_DETAILS":
      return { ...state, vehicleDetails: action.payload };
    default:
      return state;
  }
};

export const useVehicleState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    setMake: (make: string) => dispatch({ type: "SET_MAKE", payload: make }),
    setModel: (model: string) =>
      dispatch({ type: "SET_MODEL", payload: model }),
    setYear: (year: string) => dispatch({ type: "SET_YEAR", payload: year }),
    setSeries: (series: string) =>
      dispatch({ type: "SET_SERIES", payload: series }),
    setEngine: (engine: string) =>
      dispatch({ type: "SET_ENGINE", payload: engine }),
    setDetails: (details: Vehicle) =>
      dispatch({ type: "SET_DETAILS", payload: details }),
    setVehicleMakes: (vehicleMakes: Option[]) =>
      dispatch({ type: "SET_VEHICLE_MAKES", payload: vehicleMakes }),
    setVehicleModels: (vehicleModels: Option[]) =>
      dispatch({ type: "SET_VEHICLE_MODELS", payload: vehicleModels }),
    setVehicleYears: (vehicleYears: Option[]) =>
      dispatch({ type: "SET_VEHICLE_YEARS", payload: vehicleYears }),
    setVehicleSeries: (vehicleSeries: Option[]) =>
      dispatch({ type: "SET_VEHICLE_SERIES", payload: vehicleSeries }),
    setVehicleEngines: (vehicleEngines: Option[]) =>
      dispatch({ type: "SET_VEHICLE_ENGINES", payload: vehicleEngines }),
    setVehicleDetails: (vehicleDetails: Vehicle[]) =>
      dispatch({ type: "SET_VEHICLE_DETAILS", payload: vehicleDetails }),
  };
};
