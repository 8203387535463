// Helper function to split by semi-colon and then add new lines
const splitBySemiColon = (data: string[]) =>
  data
    .flatMap(item => item.split(";"))
    .map(item => item.trim())
    .filter(item => item);

type FitmentDetailProps = {
  label: string;
  data: string[] | undefined;
};

const FitmentPartDetail = ({ label, data }: FitmentDetailProps) => {
  if (!data || data.length === 0) return null;

  const parts = splitBySemiColon(data);
  if (parts.length === 0) return null;

  return (
    <div>
      <span>{label}: </span>
      {parts[0]}
      {parts.slice(1).map((part, idx) => (
        <div key={idx}>{part}</div>
      ))}
    </div>
  );
};

export default FitmentPartDetail;
