import { useEffect } from "react";
import toast from "react-hot-toast";

import { Link } from "@remix-run/react";

import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";

import type { OrderEntry } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { MAX_ORDER_QUANTITY_EXCEEDED } from "~/lib/constants";
import { useMediaScreen } from "~/lib/utils/screens";

import ToastError from "../../../../components/toasts/toast-error";
import ToastSuccess from "../../../../components/toasts/toast-success";
import { Text } from "../../../../components/ui/text";
import { useBasketActions } from "../../resources+/basket";
import MinusButton from "./minus-button";
import PlusButton from "./plus-button";

type Props =
  | {
      renderFallback: true;
      productInCart?: never;
      addToCartEvent?: () => void;
      removeFromCartEvent?: () => void;
    }
  | {
      renderFallback?: false;
      productInCart: OrderEntry;
      addToCartEvent?: () => void;
      removeFromCartEvent?: () => void;
    };

const ProductCountAdjust = ({
  productInCart,
  addToCartEvent,
  removeFromCartEvent,
  renderFallback,
}: Props) => {
  const basket = useBasketActions();
  const productQuantity = productInCart?.quantity ?? 0;
  const basketQuantity = Number.parseInt(
    (basket.fetcher.formData?.get("quantity") as string | undefined) ?? "0",
  );

  const isIncreaseLoading =
    basket.fetcher.state !== "idle" && productQuantity < basketQuantity;
  const isDecreaseLoading =
    basket.fetcher.state !== "idle" && productQuantity > basketQuantity;

  const { isMobile } = useMediaScreen();

  useEffect(() => {
    const fetcherData = basket.fetcher.data;

    const isEdited =
      basket.fetcher.state === "loading" &&
      fetcherData &&
      "successfullyEdited" in fetcherData &&
      fetcherData.successfullyEdited;

    if (
      isEdited &&
      "basket" in fetcherData &&
      fetcherData.basket?.statusCode === MAX_ORDER_QUANTITY_EXCEEDED
    ) {
      const maxQuantity = fetcherData.basket.entry?.product?.maxOrderQuantity;
      toast.custom(
        t => (
          <ToastError
            t={t}
            message={`You're only allowed to purchase ${maxQuantity} units of this product in a single order`}
          />
        ),
        {
          position: isMobile ? "top-center" : "top-right",
          duration: 3000,
          id: "max-order-quantity-exceeded",
        },
      );
    } else if (isEdited && fetcherData.basket?.entry?.product?.name) {
      toast.custom(
        t => (
          <ToastSuccess
            t={t}
            message={`${fetcherData.basket?.entry?.product?.name} has been updated in your cart`}
          >
            <Link to="cart" className="text-sm font-bold underline">
              View Cart
            </Link>
          </ToastSuccess>
        ),
        {
          position: isMobile ? "top-center" : "top-right",
          duration: 3000,
          id: "product-quantity-update",
        },
      );
    } else if (
      fetcherData &&
      basket.fetcher.state === "loading" &&
      "globalErrors" in fetcherData &&
      fetcherData.globalErrors
    ) {
      const errorMessage = fetcherData.globalErrors[0];
      toast.custom(
        t => <ToastError t={t} message={`Error: ${errorMessage}`} />,
        {
          position: "top-right",
          duration: 3000,
          id: "error-message-remove",
        },
      );
    }
  }, [basket.fetcher.data, basket.fetcher.state, isMobile]);

  return renderFallback ? (
    <div className="w-full">
      <div className="flex gap-2">
        <div className="h-[52px] w-[52px] min-w-[52px] rounded-md bg-neutral-9 p-[14px]">
          <MinusIcon color="#6a6a6a" width={24} height={24} />
        </div>
        <div className="flex w-full items-center justify-center rounded-md bg-neutral-9">
          <span className="font-extrabold text-neutral-2">1</span>
        </div>
        <div className="h-[52px] w-[52px] min-w-[52px] rounded-md bg-neutral-9 p-[14px]">
          <PlusIcon color="#6a6a6a" width={24} height={24} />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center gap-3">
      <MinusButton
        isDisabled={isDecreaseLoading || productInCart.quantity === 1}
        isLoading={isDecreaseLoading}
        type="button"
        onClick={() => {
          basket.update(
            String(productInCart.entryNumber),
            productInCart.quantity ? productInCart.quantity - 1 : 0,
          );
          removeFromCartEvent?.();
        }}
      />
      <div className="flex h-[52px] w-full items-center justify-center rounded-md bg-neutral-9">
        <Text className="w-fit text-base font-extrabold text-neutral-1">
          {productInCart.quantity}
        </Text>
      </div>
      <PlusButton
        isDisabled={isIncreaseLoading}
        isLoading={isIncreaseLoading}
        type="button"
        onClick={() => {
          basket.update(
            String(productInCart.entryNumber),
            productInCart.quantity ? productInCart.quantity + 1 : 1,
          );
          addToCartEvent?.();
        }}
      />
    </div>
  );
};

export default ProductCountAdjust;
