import { Suspense, useEffect } from "react";

import type { SerializeFrom } from "@remix-run/cloudflare";
import { Await, useFetcher } from "@remix-run/react";

import type { LocalDeliveryAddress } from "~/commerce-sap/.server/sessions.server";

import type { DeliveryModeList } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { Skeletons } from "~/commerce-sap/routes/($locale)+/_product-list+/components/skeletons";
import { SkeletonAwait } from "~/components/suspense/skeleton";
import { Text } from "~/components/ui/text";
import { cn } from "~/lib/ui";
import { useRootLoaderData } from "~/root";
import type { action } from "~/routes/($locale)+/resources+/store+/check-stock-level";

import { useRootLayoutData } from "../../_layout";
import { useCheckStockLevelPath } from "../../resources+/store+/check-stock-level";
import { FindStoreDrawer } from "./find-store-drawer";
import StockStatusMessage from "./stock-status-message";

type Props = {
  productDeliveryOptions?: Promise<SerializeFrom<{
    deliveryOptions: DeliveryModeList | null;
    localDeliveryAddress: LocalDeliveryAddress;
  }> | null>;
  extractedItemCode: string;
  availableForCnC: boolean | undefined;
};

export const getStockLevelStatus = (
  availableQty: number,
  treshhold: number,
): string => {
  switch (true) {
    case availableQty > treshhold:
      return "inStock";
    case availableQty > 0 && availableQty <= treshhold:
      return "lowStock";
    default:
      return "outOfStock";
  }
};

const PDPStoreFinder = ({
  productDeliveryOptions,
  extractedItemCode,
  availableForCnC,
}: Props) => {
  const fetcher = useFetcher<typeof action>();
  const isSelecting = fetcher.state !== "idle";
  const { DefaultLowStockThreshold } = useRootLoaderData();
  const { myStoreInfo } = useRootLayoutData();
  const checkStockLevelActionPath = useCheckStockLevelPath();

  const storeName = myStoreInfo?.store?.name;

  useEffect(() => {
    if (storeName) {
      fetcher.submit(
        {
          storeName: storeName,
          extractedItemCode: JSON.stringify([extractedItemCode]),
        },
        { method: "post", action: checkStockLevelActionPath() },
      );
    }
  }, [storeName]);

  const availableQty =
    fetcher.data && "availableQty" in fetcher.data
      ? fetcher.data.availableQty
      : 0;
  const stockLevelStatus = getStockLevelStatus(
    availableQty,
    DefaultLowStockThreshold,
  );

  if (fetcher.state !== "idle" || (storeName && !fetcher.data)) {
    return (
      <div className="flex flex-col gap-4 border-b-2 px-2 py-4">
        <Skeletons
          className="h-36 w-full flex-row md:w-full lg:w-full"
          size={1}
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4 border-b-2 px-2 py-4">
      <Suspense
        fallback={
          <Skeletons
            className="h-36 w-full flex-row md:w-full lg:w-full"
            size={1}
          />
        }
      >
        <Await resolve={productDeliveryOptions}>
          {() => {
            return (
              <SkeletonAwait
                forceSkeleton={isSelecting}
                resolve={isSelecting}
                className="h-20 w-full flex-row md:w-full lg:w-full"
              >
                {() => (
                  <>
                    <div
                      className={cn(
                        "flex justify-between",
                        storeName && availableForCnC
                          ? "flex-row items-center"
                          : "flex-col items-start",
                        !availableForCnC
                          ? "pointer-events-none text-neutral-400"
                          : "",
                      )}
                    >
                      <Text
                        className={cn(
                          availableForCnC ? "pb-1" : "pb-3",
                          "text-lg font-bold",
                        )}
                      >
                        In Store
                      </Text>
                      <FindStoreDrawer extractedItemCode={extractedItemCode}>
                        <div
                          className={cn(
                            "pb-1 text-xs",
                            availableForCnC ? "cursor-pointer underline" : "",
                          )}
                        >
                          {availableForCnC
                            ? storeName
                              ? "Choose Another Store"
                              : "Set Your Store"
                            : "Not Available"}
                        </div>
                      </FindStoreDrawer>
                    </div>
                    {storeName && availableForCnC && (
                      <div className="flex items-center justify-between">
                        {myStoreInfo.store?.displayName}
                        <div className="flex items-center gap-1">
                          <StockStatusMessage stockStatus={stockLevelStatus} />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </SkeletonAwait>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
};

export default PDPStoreFinder;
