export const CustomCheckCircleIcon = ({
  width = 12,
  height = 12,
  stroke = "#93D500",
  fill = "#121212",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      fill={fill}
      stroke={stroke}
      strokeWidth="2"
    />
    <path
      d="M9 12.5L11 14.5L15 10.5"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
