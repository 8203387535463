import { useEffect } from "react";
import toast from "react-hot-toast";

import { Separator } from "@radix-ui/react-separator";

import type { OrderEntry } from "~/commerce-sap/.server/api/generated/__generated_apis";
import type { ProductPDP } from "~/commerce-sap/app.types";
import { Price } from "~/components/price/price";
import GiftCardSummary from "~/components/products/gift-card-summary";
import ToastError from "~/components/toasts/toast-error";
import ToastSuccess from "~/components/toasts/toast-success";
import { Button } from "~/components/ui/button";
import { LoadingIndicator } from "~/components/ui/loading-button";
import { P, Text } from "~/components/ui/text";
import { ProductImage } from "~/routes/($locale)+/_product-details+/components/product-image";

import { useBasketActions } from "../../basket";

type Props = {
  product: ProductPDP;
  productBasketEntry: OrderEntry;
};

const MiniCartGiftCardProductItem = ({
  product,
  productBasketEntry,
}: Props) => {
  const { remove, fetcher } = useBasketActions();
  const isLoading = fetcher.state !== "idle";

  useEffect(() => {
    if (
      fetcher.data &&
      fetcher.state === "loading" &&
      "successfullyRemoved" in fetcher.data &&
      fetcher.data.successfullyRemoved
    ) {
      const productName = product.name;

      toast.custom(
        t => (
          <ToastSuccess
            t={t}
            message={`${productName} has been removed from your cart`}
          />
        ),
        {
          position: "top-right",
          duration: 3000,
          id: "product-removed-from-mini-cart",
        },
      );
    }
    if (
      fetcher.data &&
      fetcher.state === "loading" &&
      "globalErrors" in fetcher.data &&
      fetcher.data.globalErrors
    ) {
      const errorMessage = fetcher.data.globalErrors[0];
      toast.custom(
        t => <ToastError t={t} message={`Error: ${errorMessage}`} />,
        {
          position: "top-right",
          duration: 3000,
          id: "error-message-remove",
        },
      );
    }
  }, [fetcher, product.name]);

  return (
    <div className="px-6">
      <div className="grid grid-cols-4 py-6">
        {/* image */}
        <div className="col-span-1 mr-2">
          {product.images && (
            <ProductImage
              image={product.images[0]}
              className="max-h-[48px] max-w-[48px] lg:max-h-[88px] lg:max-w-[88px]"
              small
            />
          )}
        </div>

        <div className="col-span-3">
          <div className="flex flex-col gap-[30px]">
            <div className="flex flex-col gap-2">
              <Text className="text-base font-semibold lg:text-lg lg:font-bold">
                {product.name}
              </Text>
              <Text className="text-xs font-normal text-neutral-3">
                SKU: {product.code}
              </Text>
              <GiftCardSummary
                recipientEmail={productBasketEntry.giftCardData?.recipientEmail}
                recipientName={productBasketEntry.giftCardData?.recipientName}
                giftCardMessage={
                  productBasketEntry.giftCardData?.giftCardMessage
                }
                className="hidden lg:flex"
              />
            </div>
            {/* count & remove cta */}
            <div className="flex items-center justify-between">
              <div>
                <P className="text-sm font-bold text-neutral-3">QTY: 1</P>
              </div>
              <div className="flex flex-row gap-6">
                <Text className="text-[22px] font-bold leading-lg">
                  <Price price={productBasketEntry.totalPrice!} />
                </Text>
                {!isLoading ? (
                  <Button
                    variant="ghost"
                    className="p-0 hover:bg-inherit hover:text-inherit"
                    onClick={() =>
                      remove(String(productBasketEntry.entryNumber))
                    }
                  >
                    <img src="/icons/bin.svg" alt="bin icon" />
                  </Button>
                ) : (
                  <LoadingIndicator className="fill-brand-primary-black" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Separator
        orientation="horizontal"
        className="h-[1px] w-full bg-neutral-7"
      />
    </div>
  );
};

export default MiniCartGiftCardProductItem;
