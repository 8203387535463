import { ContentfulImageWithCTA } from "~/contentful/components/image-with-cta";
import { ContentfulImageWithHyperlink } from "~/contentful/components/image-with-hyperlink";
import { ContentfulPicture } from "~/contentful/components/picture";
import type {
  ImageWithCTAContent,
  ImageWithHyperlinkContent,
  RewardsBannerContent,
} from "~/contentful/types";
import type { ImageProps } from "~/contentful/util";
import { cn } from "~/lib/ui";

type Props = {
  content: RewardsBannerContent;
  className?: string;
};

const MiniCartCMS = ({ content, className }: Props) => {
  const data = content.fields.content;
  const contentType = data?.sys.contentType.sys.id;

  return (
    <div className={cn("my-6 w-full rounded-lg px-6", className)}>
      {contentType === "image" && (
        <ContentfulPicture image={data as ImageProps} />
      )}
      {contentType === "imageWithHyperlink" && (
        <ContentfulImageWithHyperlink
          content={data as ImageWithHyperlinkContent}
        />
      )}
      {contentType === "imageWithCta" && (
        <ContentfulImageWithCTA content={data as ImageWithCTAContent} />
      )}
    </div>
  );
};

export default MiniCartCMS;
