import { useNavigate, useSearchParams } from "@remix-run/react";

import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";

import type { Vehicle } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { isValidationError } from "~/components/forms/validationErrorResponse";
import { TriButton } from "~/components/ui/tri-button";
import { useURL } from "~/contexts";
import { useOnCompleted } from "~/lib/remix/fetcher";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";

import {
  useLoadVehicleRego,
  useLoadVehicleRegoActionPath,
} from "../vehicle-search";
import {
  useToggleVehicleSuitabilityActionPath,
  useVehicleSuitabilityActions,
} from "../vehicle-shop-by-vehicle";

interface VehiclePartsProps {
  vehicle: Vehicle | undefined;
}

export const VehicleSelected: React.FC<VehiclePartsProps> = ({ vehicle }) => {
  const { fetcher } = useLoadVehicleRego();
  const removeActionPath = useLoadVehicleRegoActionPath("removeVehicle");
  const { isVehicleSuitabilityToggled } = useRootLayoutData();
  const vehicleSuitabilityActionPath = useToggleVehicleSuitabilityActionPath(
    "toggleVehicleSuitabilityEnabled",
  );
  const { fetcherVehichleSuitability } = useVehicleSuitabilityActions(
    "toggleVehicleSuitabilityEnabled",
  );
  const [, setSearchParams] = useSearchParams();
  const url = useURL();
  const navigate = useNavigate();

  useOnCompleted(data => {
    if (isValidationError(data)) {
      return;
    }
    navigate(url("/search"));
  }, fetcherVehichleSuitability);
  return (
    <div className="flex h-screen flex-col">
      <div className="flex-grow overflow-y-auto">
        <div className="bg-neutral-9 p-6">
          <h1 className="mb-4 font-bold">Vehicle is set.</h1>
          <fetcherVehichleSuitability.Form
            onSubmit={e => {
              if (isVehicleSuitabilityToggled) {
                e.stopPropagation();
                e.preventDefault();
                navigate(url(`/search${window.location.search}`), {
                  replace: true,
                });
              }
            }}
            action={vehicleSuitabilityActionPath}
            method="post"
          >
            <GlobalErrors data={fetcherVehichleSuitability.data} />
            <TriButton
              size="full"
              isLoading={fetcherVehichleSuitability.state !== "idle"}
            >
              View Parts
            </TriButton>
          </fetcherVehichleSuitability.Form>
        </div>
        <div className="mx-6 my-4 flex flex-grow flex-col">
          <p className="font-sans">{vehicle?.desc}</p>
          <div className="flex items-center">
            <fetcher.Form action={removeActionPath} method="post">
              <input type="hidden" name="type" value="change" />
              <TriButton
                variant="ghost"
                isLoading={
                  fetcher.state !== "idle" &&
                  fetcher.formData?.get("type") === "change"
                }
              >
                <Pencil1Icon className="mr-1 h-5 w-5" />
                Change
              </TriButton>
            </fetcher.Form>
            <fetcher.Form action={removeActionPath} method="post">
              <input type="hidden" name="type" value="remove" />
              <TriButton
                variant="ghost"
                dangerous
                isLoading={
                  fetcher.state !== "idle" &&
                  fetcher.formData?.get("type") === "remove"
                }
              >
                <TrashIcon className="mr-1 h-5 w-5" />
                Remove
              </TriButton>
            </fetcher.Form>
          </div>
        </div>
      </div>
      <div className="mb-16 w-full flex-none bg-white p-6 shadow-lg shadow-black/90 md:py-4">
        <TriButton
          variant="ghost"
          className="h-11 w-full rounded-md border-2 border-black font-bold text-black hover:no-underline"
          isLoading={fetcher.state !== "idle"}
          onClick={() => {
            setSearchParams(
              prevParams => {
                prevParams.delete("drawer");
                return prevParams;
              },
              { preventScrollReset: true, replace: true },
            );
          }}
        >
          Continue Shopping
        </TriButton>
      </div>
    </div>
  );
};
