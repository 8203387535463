import { type ChangeEvent, useState } from "react";

import type { FetcherWithComponents } from "@remix-run/react";

import Breadcrumbs from "@commerce/components/breadcrumbs";
import { ChevronLeftIcon } from "@radix-ui/react-icons";

import { Form, FormFieldInput } from "~/components/forms/Form";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { Button } from "~/components/ui/button";
import { Text } from "~/components/ui/text";
import { TriButton } from "~/components/ui/tri-button";
import { checkEmailValidator } from "~/routes/($locale)+/_auth+/login";

type Props = {
  fetcher: FetcherWithComponents<unknown>;
  actionData: unknown;
  isLoading: boolean;
  customerEmail: string;
  action: string;
  handleBackButton?: () => void;
  isFlyoutMode?: boolean;
};

const ForgotPasswordForm = ({
  handleBackButton,
  action,
  fetcher,
  actionData,
  customerEmail,
  isLoading,
  isFlyoutMode,
}: Props) => {
  const [isInputEmpty, setIsInputEmpty] = useState(!!customerEmail);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsInputEmpty(!!event.target.value);
  };
  return (
    <>
      <div className="flex-1">
        {isFlyoutMode ? (
          <div className="flex cursor-pointer items-center font-bold underline">
            <Button
              size="1"
              variant="link"
              onClick={handleBackButton}
              className="p-0 font-bold"
            >
              <ChevronLeftIcon width="16px" height="16px" />
              <p>Back</p>
            </Button>
          </div>
        ) : (
          <Breadcrumbs pageName={"Forgot Password"} />
        )}
        <Text className="!mt-4 font-bold sm:text-2xl md:text-2xl lg:text-product-price">
          Forgot your password?
        </Text>
        <Text className="!mt-2">
          We will send instructions on how to reset your password to the below
          address.
        </Text>
        <GlobalErrors data={actionData} showFieldErrors />
        <Form
          validator={checkEmailValidator}
          className="!mt-3 space-y-4"
          action={action}
          method="post"
          fetcher={fetcher}
        >
          <FormFieldInput
            label="Email Address*"
            name="email"
            autoComplete="username"
            type="email"
            placeholder="name@email.com"
            defaultValue={customerEmail}
            className="text-contrast-black mt-1"
            onChange={handleInputChange}
          />
          <div>
            <TriButton
              type="submit"
              disabled={
                !isInputEmpty ||
                fetcher.formAction?.includes("forgottenPasswordReset")
              }
              isLoading={
                isLoading &&
                fetcher.formAction?.includes("forgottenPasswordReset")
              }
              className="mt-3 h-[52px] w-full px-6 text-base font-extrabold"
            >
              Reset Password
            </TriButton>
          </div>
        </Form>
      </div>
      {!isFlyoutMode && (
        <div className="w-full flex-1">{/*TODO: Add content here*/}</div>
      )}
    </>
  );
};

export default ForgotPasswordForm;
