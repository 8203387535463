import { useState } from "react";

import { ChevronDownIcon } from "@radix-ui/react-icons";
import { SelectTrigger } from "@radix-ui/react-select";

import { Select, SelectContent, SelectItem } from "~/components/ui/select";
import { TriButton } from "~/components/ui/tri-button";
import { MAX_ORDER_QUANTITY } from "~/lib/constants";
import { cn } from "~/lib/ui";

const getQuantityOptions = (disabled: boolean | undefined) => {
  const options = [];
  for (let i = 1; i <= MAX_ORDER_QUANTITY; i++) {
    options.push(
      <SelectItem
        key={i}
        value={i.toString()}
        className="rounded-none border border-neutral-7 px-4 py-[13px] text-sm font-normal text-brand-primary-black"
        spanClassName="right-6 left-auto pb-0.5 h-0 w-0"
        checkboxClassName="h-[18px] w-[18px] !bg-brand-primary-black !rounded-full"
        disabled={disabled}
      >
        {i}
      </SelectItem>,
    );
  }
  return options;
};
type Props =
  | {
      renderFallback?: true;
      setProductQuantity?: never;
      productQuantity?: never;
      maxQuantity?: never;
      selectTriggerClassName?: never;
      disabled?: boolean | undefined;
    }
  | {
      renderFallback?: false;
      productQuantity: string;
      setProductQuantity: (quantity: string) => void;
      maxQuantity?: number;
      selectTriggerClassName?: string;
      disabled?: boolean | undefined;
    };

const ProductQuantitySelector = ({
  setProductQuantity,
  productQuantity,
  renderFallback,
  selectTriggerClassName,
  disabled,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return renderFallback ? (
    <>
      <div className="flex h-11 w-[68px] items-center justify-center gap-2 rounded-bl-md rounded-tl-md bg-neutral-9 px-[6px] py-[15px] focus-visible:outline-0">
        <span
          className={cn(
            "text-base font-extrabold",
            disabled && "text-neutral-3",
          )}
        >
          1
        </span>
        <ChevronDownIcon color="#848484" width={20} height={20} />
      </div>

      <TriButton
        type="submit"
        disabled={disabled}
        className="h-[44px] w-[inherit] animate-popupStrong rounded-bl-none rounded-tl-none text-base font-extrabold hover:border-neutral-2 hover:bg-neutral-2"
        name="action"
        value="add"
        icon="/icons/cart.svg"
      >
        Add to Cart
      </TriButton>
    </>
  ) : (
    <Select
      name="quantity"
      defaultValue="1"
      onValueChange={value => setProductQuantity?.(value)}
      onOpenChange={open => setIsOpen(open)}
      disabled={disabled}
    >
      <SelectTrigger
        className={cn(
          "flex h-11 w-[68px] items-center justify-center gap-3 rounded-bl-md rounded-tl-md bg-brand-primary-black px-[6px] py-[15px] hover:bg-neutral-2 focus-visible:outline-0 disabled:bg-neutral-9",
          selectTriggerClassName,
        )}
      >
        <span
          className={cn(
            "text-base font-extrabold text-white",
            disabled && "text-neutral-3",
          )}
        >
          {productQuantity}
        </span>

        <ChevronDownIcon
          className={`transform transition-transform duration-300 ease-in-out ${
            isOpen ? "rotate-180" : "mb-[3px] rotate-0"
          }`}
          color={`${disabled ? "#848484" : "#ffffff"}`}
          width={20}
          height={20}
        />
      </SelectTrigger>
      <SelectContent
        position="popper"
        className="min-w-[68px] bg-white"
        viewPortClassName="p-0"
      >
        {getQuantityOptions(disabled)}
      </SelectContent>
    </Select>
  );
};

export default ProductQuantitySelector;
