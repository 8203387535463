import { useEffect } from "react";
import toast from "react-hot-toast";

import { Separator } from "@radix-ui/react-separator";

import type {
  OrderEntry,
  Product,
  ProductStock,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import type { ProductPDP } from "~/commerce-sap/app.types";
import ToastError from "~/components/toasts/toast-error";
import ToastSuccess from "~/components/toasts/toast-success";
import { Button } from "~/components/ui/button";
import InStockIcon from "~/components/ui/icons/in-stock-icon";
import OutOfStockIcon from "~/components/ui/icons/out-of-stock-icon";
import { StockStatusIcon } from "~/components/ui/icons/stock-status-icon";
import { LoadingIndicator } from "~/components/ui/loading-button";
import { Text } from "~/components/ui/text";
import {
  ADD_TO_CART_EVENT,
  REMOVE_FROM_CART_EVENT,
  transformProductsForGTM,
  useGTMTracker,
} from "~/google-tagmanager";
import CartProductStockInfo from "~/routes/($locale)+/_cart+/components/cart-product-stock-info";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";
import ProductCountAdjust from "~/routes/($locale)+/_product-details+/components/product-count-adjust";
import { ProductImage } from "~/routes/($locale)+/_product-details+/components/product-image";
import CartPriceDisplay from "~/routes/($locale)+/product-list+/components/price/cart-price-display";

import { useBasketActions } from "../../basket";

type Props = {
  product: ProductPDP;
  productBasketEntry: OrderEntry;
  stockData?: ProductStock;
  isLoyaltyMember: boolean | undefined;
};

const MiniCartProductItem = ({
  product,
  productBasketEntry,
  stockData,
  isLoyaltyMember,
}: Props) => {
  const { remove, fetcher } = useBasketActions();
  const { myStoreInfo } = useRootLayoutData();
  const isLoading = fetcher.state !== "idle";
  const storeName = myStoreInfo?.store?.name;
  const { cartEventsEvent } = useGTMTracker();

  const addToCartEvent = () => {
    cartEventsEvent(
      ADD_TO_CART_EVENT,
      transformProductsForGTM(
        [productBasketEntry.product as Product],
        0,
        isLoyaltyMember,
      ),
    );
  };
  const removeFromCartEvent = (removeAll: boolean = false) => {
    cartEventsEvent(
      REMOVE_FROM_CART_EVENT,
      transformProductsForGTM(
        [productBasketEntry.product as Product],
        0,
        isLoyaltyMember,
        removeAll ? productBasketEntry.quantity : 1,
      ),
    );
  };

  useEffect(() => {
    if (
      fetcher.data &&
      fetcher.state === "loading" &&
      "successfullyRemoved" in fetcher.data &&
      fetcher.data.successfullyRemoved
    ) {
      const productName = product.name;

      toast.custom(
        t => (
          <ToastSuccess
            t={t}
            message={`${productName} has been removed from your cart`}
          />
        ),
        {
          position: "top-right",
          duration: 3000,
          id: "product-removed-from-mini-cart",
        },
      );
    }
    if (
      fetcher.data &&
      fetcher.state === "loading" &&
      "globalErrors" in fetcher.data &&
      fetcher.data.globalErrors
    ) {
      const errorMessage = fetcher.data.globalErrors[0];
      toast.custom(
        t => <ToastError t={t} message={`Error: ${errorMessage}`} />,
        {
          position: "top-right",
          duration: 3000,
          id: "error-message-remove",
        },
      );
    }
  }, [fetcher, product.name]);

  return (
    <div className="px-6">
      <div className="grid grid-cols-4 py-6">
        {/* image */}
        <div className="col-span-1 mr-2">
          {product.images && (
            <ProductImage
              image={product.images[0]}
              className="max-h-[48px] max-w-[48px] lg:max-h-[88px] lg:max-w-[88px]"
              small
            />
          )}
        </div>

        <div className="col-span-3">
          <div className="flex flex-col gap-[30px]">
            <div className="flex flex-col gap-2">
              <Text className="text-base font-semibold lg:text-lg lg:font-bold">
                {product.name}
              </Text>
              <Text className="text-xs font-normal text-neutral-3">
                SKU: {product.code}
              </Text>
            </div>

            <CartPriceDisplay productBasketEntry={productBasketEntry} />
            {/* stock availability */}
            <div>
              <div className="flex flex-col gap-1 text-xs">
                <div className="flex items-center gap-1">
                  {product.availableForDelivery ? (
                    <InStockIcon />
                  ) : (
                    <OutOfStockIcon />
                  )}
                  {product.availableForDelivery ? "Available" : "Not available"}{" "}
                  for Delivery
                </div>
                {storeName && (
                  <CartProductStockInfo
                    productBasketEntry={productBasketEntry}
                    availableQty={stockData?.availableQty ?? 0}
                    myStoreInfo={myStoreInfo}
                  />
                )}
                {!storeName &&
                  !productBasketEntry.product?.availableForClickCollect && (
                    <div className="flex items-center gap-1">
                      <StockStatusIcon status="notAvailable" />
                      Not available for Collection
                    </div>
                  )}
              </div>
            </div>

            {/* count & remove cta */}
            <div className="flex items-center justify-between">
              <div className="w-[168px] [&>div>button]:h-[44px] [&>div>button]:w-[44px] [&>div>button]:min-w-[44px] [&>div>div]:h-[44px]">
                <ProductCountAdjust
                  productInCart={productBasketEntry}
                  addToCartEvent={addToCartEvent}
                  removeFromCartEvent={removeFromCartEvent}
                />
              </div>
              {!isLoading ? (
                <Button
                  variant="ghost"
                  className="h-6 w-6 p-0 hover:bg-inherit hover:text-inherit"
                  onClick={() => {
                    remove(String(productBasketEntry.entryNumber));
                    removeFromCartEvent(true);
                  }}
                >
                  <img src="/icons/bin.svg" alt="bin icon" />
                </Button>
              ) : (
                <LoadingIndicator className="fill-brand-primary-black" />
              )}
            </div>
          </div>
        </div>
      </div>
      <Separator
        orientation="horizontal"
        className="h-[1px] w-full bg-neutral-7"
      />
    </div>
  );
};

export default MiniCartProductItem;
