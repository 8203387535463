import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { cn } from "~/lib/ui";

import type { SideBySideContent } from "../types";
import { type ImageProps, getMaxWidthClass } from "../util";
import { CardTextBox } from "./card-text-box";
import { ContentfulPicture } from "./picture";

export const SideBySide = ({
  content,
  className = "",
}: {
  content: SideBySideContent;
  className?: string;
}) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();

  if (!fields.components || fields.components.length !== 2) return null;

  // Desktop order: original order from Contentful
  const desktopComponentsOrder = fields.components;

  // Mobile order: image first, then text
  const mobileComponentsOrder: Array<
    SideBySideContent["fields"]["components"][number]
  > = [];
  const imageComponents = fields.components.filter(
    c => c.sys.contentType.sys.id === "image",
  );
  const textComponents = fields.components.filter(
    c => c.sys.contentType.sys.id === "cardTextBox",
  );
  mobileComponentsOrder.push(...imageComponents, ...textComponents);

  const renderComponent = (component: any) => {
    if (!component) return null;
    switch (component.sys.contentType.sys.id) {
      case "cardTextBox":
        return (
          <CardTextBox
            content={component}
            {...inspectorProps({
              entryId: content.sys.id,
              fieldId: "components",
            })}
          />
        );
      case "image": {
        const imageData = component.fields?.image;
        if (!imageData) return null;

        const formattedImage: ImageProps = {
          sys: component.sys,
          metadata: component.metadata,
          fields: {
            name: component.fields.name,
            image: component.fields.image,
            alt: component.fields.alt || "Default Alt Text",
            mobileImage: component.fields.mobileImage || undefined,
            altMobile: component.fields.altMobile || undefined,
          },
        };

        return (
          <ContentfulPicture
            image={formattedImage}
            className={`${className} rounded-md lg:rounded-lg`}
            {...inspectorProps({
              entryId: content.sys.id,
              fieldId: "components",
            })}
            numberOfColumns={[1, 2, 2]}
          />
        );
      }
      default:
        return null;
    }
  };

  // Get the Tailwind class for maxWidth
  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  return (
    <div
      className={`side-by-side ${className} ${maxWidthClass} mx-auto min-w-full lg:min-w-[unset]`}
    >
      <div
        className={cn(
          "mx-auto flex w-full gap-7 lg:gap-[60px]",
          "flex-col md:flex-row",
          className,
        )}
      >
        {mobileComponentsOrder.map((component, index) => (
          <div
            key={component.sys.id}
            className={cn(
              "flex w-full items-center justify-center",
              "md:w-1/2",
              // Mobile order: image first, text second
              `order-${index + 1}`,
              // Desktop order: original Contentful order
              {
                "md:order-1": component === desktopComponentsOrder[0],
                "md:order-2": component === desktopComponentsOrder[1],
              },
            )}
          >
            {renderComponent(component)}
          </div>
        ))}
      </div>
    </div>
  );
};
