import { namedAction } from "remix-utils/named-action";

import { json } from "@remix-run/cloudflare";
import { useFetcher } from "@remix-run/react";

import { getVehicleStore } from "./store/vehicle-store.server";
import { commitSession } from "~/commerce-sap/.server/sessions.server";
import { validationError } from "~/components/forms/validationErrorResponse.server";

import type { PartsFitment } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useURL } from "~/contexts";

import type { GetVehiclesByRegoParams } from "./schemas/vehicle-rego";
import { vehicleRegoSchemaValidator } from "./schemas/vehicle-rego";
import { setVehicleValidator } from "./schemas/vehicle-set";

export { VehicleDrawer } from "./components/vehicle-drawer";
export const loader = async ({ request, context }: ActionArgs) => {
  const { api } = context;
  const url = new URL(request.url);
  const query = Object.fromEntries(url.searchParams.entries());
  const validate = await vehicleRegoSchemaValidator.validate(query);
  if (validate.error) {
    console.error("Validation Error", validate.error);
    return validationError(validate.error);
  }

  const vehicleRego = await api.getVehiclesByRego({
    userCookie: context.session.id,
    callingIPAddress: context.ip,
    ...validate.data,
  });
  return json(vehicleRego);
};

export const action = async ({ request, context }: ActionArgs) => {
  const formData = await request.formData();
  const { api, session, auth } = context;
  return namedAction(request, {
    removeVehicle: async () => {
      context.session.unset("vehicle");
      context.session.unset("vehicleKey");
      context.session.unset("vehiclePartsCount");
      context.session.unset("isVehicleSuitabilityToggled");
      if (!auth.isGuest) {
        auth.setCustomerVehicle({}, auth.get("uid"));
      }

      if (!auth.isGuest) {
        const uid = auth.get("uid");
        await auth.removeCustomerVehicle(uid);
      }

      await commitSession();
      return json({ success: true });
    },
    setVehicle: async () => {
      const setVehicleQuery = {
        userCookie: context.session.id,
        callingIPAddress: context.ip,
      };

      const validateVehicle = await setVehicleValidator.validate(formData);
      if (validateVehicle.error) {
        console.error("Validation Error", validateVehicle.error);
        return validationError(validateVehicle.error);
      }
      const vehicleStore = getVehicleStore();
      const result = await api.setVehicle(
        setVehicleQuery,
        validateVehicle.data,
      );
      if (!result) {
        await commitSession();
        if (!auth.isGuest) {
          auth.setCustomerVehicle({}, auth.get("uid"));
        }
        return json({ partList: null });
      }

      if (result && result.partList && result.partList.length > 0) {
        await vehicleStore.setVehicleParts(
          vehicleStore.getVehicleKey(validateVehicle.data),
          result.partList,
        );
      }
      if (!auth.isGuest) {
        const uid = auth.get("uid");
        const vehicle = validateVehicle.data;
        await auth.setCustomerVehicle(vehicle, uid);
      }

      session.set("vehicle", validateVehicle.data);
      session.set(
        "vehicleKey",
        vehicleStore.getVehicleKey(validateVehicle.data),
      );
      session.set("isVehicleSuitabilityToggled", true);
      session.set("vehiclePartsCount", result?.partList?.length ?? undefined);

      await commitSession();
      return json({ ...result, addedVehicle: true });
    },
  });
};

export const useLoadVehicleRegoActionPath = (
  action?: "setVehicle" | "removeVehicle" | undefined,
) => {
  const _url = useURL();
  return _url(
    `/resources/vehicle/vehicle-search${action ? `?/${action}` : ""}`,
  );
};

export const useLoadVehicleRegoAction = () => {
  const path = useLoadVehicleRegoActionPath();
  return (params: GetVehiclesByRegoParams) => {
    const query = new URLSearchParams(params);
    const url = `${path}?${query}`;
    return url;
  };
};

export const useLoadVehicleRego = () => {
  const fetcher = useFetcher<typeof loader>({ key: "vehicleRego" });
  return { fetcher } as const;
};

export const useSetVehicle = () => {
  const fetcher = useFetcher<PartsFitment>({ key: "setVehicle" });
  return { fetcher } as const;
};

export type VehicleRegoSearchResult = ReturnType<typeof loader>;
