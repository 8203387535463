import { useEffect, useMemo, useRef, useState } from "react";

import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";
import {
  Select,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectViewport,
} from "@radix-ui/react-select";

import { Spinner } from "~/components/ui/spinner";
import { cn } from "~/lib/ui";

interface VehicleSelectProps {
  options: string[];
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  label: string;
  disabled?: boolean;
  isLoading?: boolean;
  dataSide?: "bottom" | "top" | "right" | "left" | undefined;
  open: boolean;
  onOpen: () => void;
}

export function VehicleSelect({
  options,
  label,
  disabled,
  selectedOption,
  setSelectedOption,
  isLoading,
  dataSide,
  open,
  onOpen,
}: VehicleSelectProps) {
  const triggerRef = useRef<HTMLButtonElement>(null);

  // Currently backend returns duplicated ids/values for some fields
  // Map each option to a unique key to prevent issues with scroll and selection
  // caused by duplicated IDs or values in the dropdown options.
  const valueMapping = useMemo(() => {
    return options.reduce<Record<string, string>>((acc, option, index) => {
      const key = `${option}-${index}`;
      acc[key] = option;
      return acc;
    }, {});
  }, [options]);

  // Update selected option using the original option text
  const handleValueChange = (value: string) => {
    setSelectedOption(valueMapping[value]);
  };

  const [maxHeight, setMaxHeight] = useState("50vh");

  useEffect(() => {
    const handleResize = () => {
      if (window.screen.orientation.type.includes("landscape")) {
        setMaxHeight("30vh");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [dataSide]);

  return (
    <div className="mt-2">
      <Label className="text-sm font-semibold leading-[18.2px]">{label}</Label>
      <div className="mt-2 w-full rounded-md">
        <Select
          onValueChange={handleValueChange}
          disabled={disabled}
          value={
            selectedOption
              ? Object.keys(valueMapping).find(
                  key => valueMapping[key] === selectedOption,
                )
              : undefined
          }
          open={open}
          onOpenChange={onOpen}
        >
          <SelectTrigger
            ref={triggerRef}
            className={cn(
              "border-brand-green-dark inline-flex w-full items-center justify-between rounded-md border border-neutral-5 px-[12px] py-4 text-sm font-semibold leading-[18.2px]",
              disabled && "border-neutral-7 bg-neutral-8",
            )}
          >
            {disabled || !selectedOption ? (
              <>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <span
                    className={cn(
                      !disabled ? "text-neutral-2" : "text-neutral-4",
                    )}
                  >
                    Select an option
                  </span>
                )}
              </>
            ) : (
              <span className="text-brand-primary-black">{selectedOption}</span>
            )}
            <SelectIcon className="ml-2 text-brand-primary-black">
              <ChevronDownIcon className="h-5 w-5 text-brand-primary-black" />
            </SelectIcon>
          </SelectTrigger>

          {!disabled && (
            <SelectContent
              className="border-brand-green-dark z-1000 w-full overflow-y-auto border bg-white shadow-lg"
              position="popper"
              side={dataSide ? dataSide : "bottom"}
              align="start"
              style={{
                width: triggerRef.current
                  ? `${triggerRef.current.offsetWidth}px`
                  : "auto",
                maxHeight: maxHeight,
              }}
              avoidCollisions={false}
            >
              <SelectViewport>
                {Object.entries(valueMapping).map(([value, displayText]) => (
                  <SelectItem
                    key={value}
                    value={value}
                    className="cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-gray-200"
                  >
                    <SelectItemText>{displayText}</SelectItemText>
                  </SelectItem>
                ))}
              </SelectViewport>
            </SelectContent>
          )}
        </Select>
      </div>
    </div>
  );
}
