import type { InputFieldProps } from "~/components/forms/Form";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/forms/Form";
import { AustralianFlagIcon } from "~/components/ui/icons/australian-flag-icon";
import { Input } from "~/components/ui/input";
import { P } from "~/components/ui/text";

import { dialingCode } from "../../account+/consts";

const FormPhoneInputField = ({
  name,
  label,
  description,
  placeholder,
  ...props
}: InputFieldProps) => {
  return (
    <FormField name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-contrast-black text-sm font-semibold">
            {`${label}`}
          </FormLabel>
        )}

        <div className="flex items-end gap-1">
          <div className="flex h-[52px] flex-row items-center gap-1 rounded-[4px] border border-neutral-6 bg-neutral-10 pl-2 pr-[14.5px] text-base font-semibold">
            <AustralianFlagIcon />
            <P>{dialingCode}</P>
          </div>

          <div className="relative w-full">
            <FormControl>
              <Input placeholder={placeholder} {...props} />
            </FormControl>
          </div>
        </div>

        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    </FormField>
  );
};

export default FormPhoneInputField;
