import { useEffect, useRef, useState } from "react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import type { Video } from "../types";

type Props = {
  content: Video;
};

const getEmbedUrl = (
  url: string,
  options: {
    autoplay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
  },
) => {
  const youtubeMatch = url.match(
    /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  );
  const vimeoMatch = url.match(/vimeo\.com\/(\d+)/);

  const autoplay = options.autoplay ? 1 : 0;
  const muted = options.muted ? 1 : 0;
  const loop = options.loop ? 1 : 0;
  const controls = options.controls ? 1 : 0;

  if (youtubeMatch) {
    return `https://www.youtube.com/embed/${youtubeMatch[1]}?modestbranding=1&autoplay=${autoplay}&controls=${controls}&mute=${muted}&loop=${loop}`;
  } else if (vimeoMatch) {
    return `https://player.vimeo.com/video/${vimeoMatch[1]}?autoplay=${autoplay}&muted=${muted}&loop=${loop}&controls=${controls}`;
  }

  return null;
};

const ContentfulVideo = ({ content }: Props) => {
  const inspectorProps = useContentfulInspectorMode();
  const videoRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.25 },
    );

    const currentVideoRef = videoRef.current;

    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }

    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const embedLink = getEmbedUrl(content.fields.contentId, {
        ...content.fields,
      });
      if (embedLink) {
        setEmbedUrl(embedLink);
      }
    }
  }, [isVisible, content.fields.contentId]);

  if (!content) return null;

  return (
    <div
      className="relative overflow-hidden rounded-lg pt-[56.25%]"
      ref={videoRef}
    >
      {embedUrl && (
        <iframe
          src={embedUrl}
          title={content.fields.name}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          loading="lazy"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "none",
          }}
          {...inspectorProps({
            entryId: content.sys.id,
            fieldId: "name",
          })}
        ></iframe>
      )}
    </div>
  );
};

export default ContentfulVideo;
