import type { Cart } from "~/commerce-sap/.server/api/generated/__generated_apis";

import MiniCartNoItems from "./mini-cart-no-items";
import MiniCartProductsList from "./mini-cart-products-list";
import MiniCartSummary from "./mini-cart-summary";

type Props = {
  basket: Cart;
};
const MiniCartContentWrapper = ({ basket }: Props) => {
  return (
    <div className="flex h-full flex-col">
      {basket.entries?.length ? (
        <div className="flex h-full flex-col pt-4">
          <MiniCartProductsList basket={basket} />

          <MiniCartSummary basket={basket} />
        </div>
      ) : (
        <MiniCartNoItems />
      )}
    </div>
  );
};

export default MiniCartContentWrapper;
