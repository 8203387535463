import { type FetcherWithComponents, useFetcher } from "@remix-run/react";

import {
  Form,
  FormFieldInput,
  FormFieldPassword,
} from "~/components/forms/Form";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { EditIcon } from "~/components/ui/icons/edit-icon";
import { Text } from "~/components/ui/text";
import { TriButton } from "~/components/ui/tri-button";
import { renderContentfulComponent } from "~/lib/generic-template-helper";
import ValidationPasswordField from "~/routes/($locale)+/_auth+/components/form-password-field";
import type {
  RegisterState,
  action as actionRegister,
} from "~/routes/($locale)+/_auth+/register";
import { registerGetStartedValidator } from "~/routes/($locale)+/_auth+/register";

import { useRootLayoutData } from "../../_layout";
import FormInputWithIcon from "./form-input-with-icon";

type Props = {
  fetcher: FetcherWithComponents<unknown>;
  actionData: unknown;
  isLoading: boolean;
  action: string;
  isFlyoutMode?: boolean;
  defaultValues?: Partial<Pick<RegisterState, "email">>;
  onEdit: (() => void) | undefined;
};

const RegisterGetStarted = ({
  fetcher,
  isLoading,
  actionData,
  action,
  isFlyoutMode = false,
  defaultValues,
  onEdit,
}: Props) => {
  const fetcherRegister = useFetcher<typeof actionRegister>({
    key: "register-verify",
  });

  const { configurationTemplate } = useRootLayoutData();
  const registrationContent =
    configurationTemplate?.registrationContentBlocks?.fields;
  return (
    <div className="flex">
      <div className=" flex-1">
        <div className="w-full flex-1 pt-6">
          {registrationContent?.registrationTop &&
            renderContentfulComponent(registrationContent.registrationTop)}
        </div>
        <Text className="!mt-4 text-2xl font-bold">Let’s get started</Text>
        <Text className="text-sm font-normal">
          We couldn’t find an account connected to the email address you’ve
          entered. Create a new account today!
        </Text>

        <GlobalErrors data={actionData} showFieldErrors={false} />
        <Form
          validator={registerGetStartedValidator}
          className="!mt-3 space-y-4"
          action={action}
          method="post"
          fetcher={fetcher}
          defaultValues={{
            email: defaultValues?.email,
            firstName:
              fetcherRegister.data && "firstName" in fetcherRegister.data
                ? fetcherRegister.data?.firstName
                : "",
            lastName:
              fetcherRegister.data && "lastName" in fetcherRegister.data
                ? fetcherRegister.data?.lastName
                : "",
            password:
              fetcherRegister.data && "password" in fetcherRegister.data
                ? fetcherRegister.data?.password
                : "",
            confirmPassword:
              fetcherRegister.data && "confirmPassword" in fetcherRegister.data
                ? fetcherRegister.data?.confirmPassword
                : "",
          }}
        >
          <FormInputWithIcon
            label="Email Address*"
            name="email"
            type="email"
            placeholder="name@email.com"
            className="text-contrast-black mt-1 font-semibold"
            aria-autocomplete="none"
            autoComplete="off"
            readOnly
            onIconClick={onEdit}
            IconComponent={<EditIcon />}
          />
          <FormFieldInput
            label="First Name*"
            name="firstName"
            autoComplete="fist-name"
            type="text"
            placeholder="e.g. John"
            className="text-contrast-black mt-1 font-semibold"
          />
          <FormFieldInput
            label="Last Name*"
            name="lastName"
            autoComplete="last-name"
            type="text"
            placeholder="e.g. Doe"
            className="text-contrast-black mt-1 font-semibold"
          />
          <ValidationPasswordField
            label="Create Password*"
            autoComplete="password"
            name="password"
            className="text-contrast-black mt-1"
            placeholder="Enter password"
          />

          <FormFieldPassword
            label="Confirm Password*"
            autoComplete="confirmPassword"
            name="confirmPassword"
            className="text-contrast-black mt-1"
            placeholder="Confirm password"
          />
          <div className="sticky -bottom-4 bg-white p-6">
            {/* TODO: disable on invalid form */}
            <TriButton
              type="submit"
              aria-disabled={
                isLoading && fetcher.formAction?.includes("register/")
              }
              isLoading={isLoading && fetcher.formAction?.includes("register/")}
              className="mt-3 h-[52px] w-full px-6  text-base font-extrabold"
            >
              Next
            </TriButton>
          </div>
        </Form>
      </div>

      {!isFlyoutMode && (
        <div className="hidden w-full flex-1 px-12 md:block lg:block">
          {registrationContent?.registrationBottom &&
            renderContentfulComponent(registrationContent.registrationBottom)}
        </div>
      )}
    </div>
  );
};

export default RegisterGetStarted;
