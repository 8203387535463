import type { IconProps } from "../../types";

export const NoImageIcon = ({
  width = 100,
  height = 60,
  fill = "#E7E7E7",
  ...props
}: IconProps & { width?: number; height?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 49 49"
      fill="none"
      {...props}
    >
      <path
        d="M10.5859 42.5C9.48594 42.5 8.54427 42.1083 7.76094 41.325C6.9776 40.5417 6.58594 39.6 6.58594 38.5V10.5C6.58594 9.4 6.9776 8.45833 7.76094 7.675C8.54427 6.89167 9.48594 6.5 10.5859 6.5H38.5859C39.6859 6.5 40.6276 6.89167 41.4109 7.675C42.1943 8.45833 42.5859 9.4 42.5859 10.5V38.5C42.5859 39.6 42.1943 40.5417 41.4109 41.325C40.6276 42.1083 39.6859 42.5 38.5859 42.5H10.5859ZM10.5859 38.5H38.5859V10.5H10.5859V38.5ZM12.5859 34.5H36.5859L29.0859 24.5L23.0859 32.5L18.5859 26.5L12.5859 34.5Z"
        fill={fill}
      />
    </svg>
  );
};
