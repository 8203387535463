import { useMemo } from "react";

import type { Image } from "~/commerce-sap/.server/api/generated/__generated_apis";

type useProductPrimaryImageProps = {
  images?: Image[] | { format: string; url: string }[];
};

const useProductPrimaryImage = ({ images }: useProductPrimaryImageProps) => {
  const primaryImage = useMemo(() => {
    if (!images || !Array.isArray(images)) return undefined;

    return images.find((image: Image) => image.format === "product");
  }, [images]);

  return {
    primaryImage,
  };
};

export default useProductPrimaryImage;
