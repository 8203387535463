import type { IconProps } from "../../types";

const EmailIcon = ({ size = 48, fill = "#121212", ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.9 44L23.4 35.5L26.2 32.7L31.9 38.4L43.2 27.1L46 29.9L31.9 44ZM24 22L40 12H8L24 22ZM24 26L8 16V36H18.3L22.3 40H8C6.9 40 5.95833 39.6083 5.175 38.825C4.39167 38.0417 4 37.1 4 36V12C4 10.9 4.39167 9.95833 5.175 9.175C5.95833 8.39167 6.9 8 8 8H40C41.1 8 42.0417 8.39167 42.825 9.175C43.6083 9.95833 44 10.9 44 12V20.7L40 24.7V16L24 26Z"
        fill={fill}
      />
    </svg>
  );
};

export default EmailIcon;
