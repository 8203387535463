import { Label } from "@radix-ui/react-label";

import { Form, FormErrors, GlobalErrors } from "~/components/forms/Form";
import { Input } from "~/components/ui/input";
import { AU_STATE_CODES } from "~/lib/constants";
import { cn } from "~/lib/ui";

import { vehicleRegoSchemaValidator } from "../schemas/vehicle-rego";
import {
  useLoadVehicleRego,
  useLoadVehicleRegoActionPath,
} from "../vehicle-search";

interface RegoSearchProps {
  plateValue: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedState: string;
  setSelectedState: (state: string) => void;
  hasError: boolean;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage?: string;
}

export const RegoSearch: React.FC<RegoSearchProps> = ({
  plateValue,
  handleInputChange,
  selectedState,
  setSelectedState,
  hasError,
  setHasError,
  errorMessage,
}) => {
  const vehicleRegoActionPath = useLoadVehicleRegoActionPath();
  const { fetcher } = useLoadVehicleRego();
  return (
    <div>
      <Form
        action={vehicleRegoActionPath}
        id="form-rego-search"
        className="flex flex-col gap-4"
        method="get"
        fetcher={fetcher}
        validator={vehicleRegoSchemaValidator}
      >
        {/* Do not remove: normally this should not appear  */}
        {/* do not remove as this is a fallback as a feedback of wrong implementations  */}
        <GlobalErrors data={fetcher.data} />
        {/* normally this should not appear  */}
        {/* do not remove as this is a fallback as a feedback of wrong implementations  */}
        <FormErrors />
        <div className="flex flex-col gap-3">
          <Label className="text-sm font-semibold text-neutral-2">
            Enter your Rego plate:*
          </Label>
          <div
            className={`relative bg-neutral-1 ${
              hasError ? "!bg-error" : ""
            } flex h-[87px] w-full flex-col items-center justify-center rounded-xl`}
          >
            <div className="absolute top-[7px] h-[5px] w-[95%] rounded-t-lg bg-brand-primary"></div>
            <Input
              type="text"
              name="rego"
              autoComplete="off"
              value={plateValue.toUpperCase()}
              onChange={handleInputChange}
              onFocus={() => setHasError(false)}
              className={`h-[53px] w-full rounded-md border-0 font-roboto text-[48px] leading-md focus:outline-none ${
                hasError
                  ? "bg-error"
                  : "bg-brand-primary-black placeholder-neutral-3"
              } placeholder-bold text-center text-5xl font-bold uppercase text-white placeholder-white focus-visible:border-0`}
              placeholder="ABC 123"
            />

            <div className="absolute bottom-[7px] h-[5px] w-[95%] rounded-b-xl bg-heading"></div>
          </div>
        </div>

        {hasError && (
          <span className="block text-sm font-normal text-error">
            {errorMessage}
          </span>
        )}

        <div className="flex flex-col gap-3">
          <Label className="text-sm font-semibold text-neutral-2">
            Select your State:*
          </Label>
          <div className="grid grid-cols-4 gap-2">
            {AU_STATE_CODES.map((state, index) => (
              <button
                type="button"
                className={cn(
                  "h-10 rounded-md border-2 border-brand-primary-black ",
                  selectedState === state
                    ? "bg-brand-primary-black  text-white"
                    : "text-brand-primary-black",
                )}
                key={`${index}_${state}`}
                onClick={() => setSelectedState(state)} // Update state on click
              >
                <span className="text-xs font-semibold">{state}</span>
              </button>
            ))}
          </div>
        </div>

        <input type="hidden" name="state" value={selectedState} />
      </Form>
    </div>
  );
};
