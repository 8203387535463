import type { PriceRangeMap } from "~/algolia/algolia.types";

import { RRPrice } from "./price-utils";

type PriceRangeDisplayProps = {
  priceRangeMap: PriceRangeMap;
};

const PriceRangeDisplay = ({ priceRangeMap }: PriceRangeDisplayProps) => {
  return (
    <div className="flex pb-5  font-bold text-brand-primary-black">
      <RRPrice price={priceRangeMap?.minPrice} className="text-2xl" />
      <span className="px-0.5 text-2xl font-bold">-</span>
      <RRPrice price={priceRangeMap?.maxPrice} className="text-2xl" />
    </div>
  );
};

export default PriceRangeDisplay;
