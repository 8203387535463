import { forwardRef, useCallback, useMemo } from "react";

import { getImageMediaLink } from "@commerce/shop/utils/image";

import type { Image } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { cn } from "~/lib/ui";
import { useRequestInfo } from "~/lib/utils/request-info";

let _isFirst = false;
export const ProductImage = forwardRef<
  HTMLPictureElement,
  React.HTMLAttributes<HTMLPictureElement> & {
    image?: Image;
    lazy?: boolean;
    small?: boolean;
    type?: "plp" | "pdp";
    disableZoom?: (disabled: boolean) => void;
  }
>(
  (
    { image, lazy, small, className, type = "plp", disableZoom, ...props },
    ref,
  ) => {
    const isMobile = useRequestInfo().ismobile;

    const isFirstMobile = useMemo(() => {
      if (isMobile && !_isFirst) {
        _isFirst = true;
        return true;
      }
      return false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImageLoad = useCallback(
      (e: React.SyntheticEvent<HTMLImageElement>) => {
        const img = e.currentTarget;
        const containerWidth = img.parentElement?.clientWidth || 0;
        const containerHeight = img.parentElement?.clientHeight || 0;

        // Ensure this code runs only in the browser
        if (typeof window !== "undefined") {
          // Calculate the effective container size based on the device pixel ratio
          const effectiveContainerWidth =
            containerWidth * window.devicePixelRatio;
          const effectiveContainerHeight =
            containerHeight * window.devicePixelRatio;

          // Check if the image is smaller than the effective container size
          const isSmallerThanContainer =
            img.naturalWidth <= effectiveContainerWidth &&
            img.naturalHeight <= effectiveContainerHeight;

          // Disable zoom if the image is already at 100% size
          if (disableZoom) {
            disableZoom(isSmallerThanContainer);
          }
        }
      },
      [disableZoom],
    );

    return image ? (
      <picture className={cn("h-full w-full")} ref={ref} {...props}>
        {small ? (
          // Small images are used for the product list page
          <source
            srcSet={getImageMediaLink(image, {
              params: {
                sm: "edge",
                "resize.edge": "w",
                q: "85",
                "resize.edge.length": "400",
                fmt: "auto",
              },
            })}
            media="(min-width:767px)"
          />
        ) : (
          <source
            srcSet={getImageMediaLink(image, {
              params: {
                sm: "edge",
                "resize.edge": "w",
                q: "85",
                "resize.edge.length": "300",
                fmt: "auto",
              },
            })}
            media="(min-width:600)"
          />
        )}
        <source
          srcSet={`${getImageMediaLink(image, {
            params: {
              sm: "edge",
              "resize.edge": "w",
              q: "85",
              "resize.edge.length": "700",
              fmt: "auto",
            },
          })}`}
          media="(min-width:1024px)"
        />
        <img
          src={getImageMediaLink(image, {
            type,
            params: {
              fmt: "auto",
            },
          })}
          height={320}
          width={320}
          alt={image.altText || "image"}
          className={cn(
            "block h-full w-full object-cover object-center",
            className,
          )}
          onLoad={disableZoom && handleImageLoad} // Handle image load to check size and disable zoom if necessary
          {...(!isMobile
            ? {
                loading: "eager",
                fetchPriority: "high",
              }
            : isFirstMobile
              ? {
                  loading: "eager",
                  fetchPriority: "high",
                }
              : { loading: "lazy" })}
        />
      </picture>
    ) : null;
  },
);

ProductImage.displayName = "ProductImage";
