import { useCallback } from "react";

import { json } from "@remix-run/cloudflare";

import { validationError } from "~/components/forms/validationErrorResponse.server";

import { useURL } from "~/contexts";

export const loader = () => {};

export const action = async ({ request, context }: ActionArgs) => {
  const formData = await request.formData();
  const { api } = context;

  const storeName = formData.get("storeName");
  const extractedItemCode = JSON.parse(
    formData.get("extractedItemCode") + "" || "[]",
  );

  if (typeof storeName === "string" && Array.isArray(extractedItemCode)) {
    try {
      const multipleItemsStockData = [];
      let data;
      if (extractedItemCode.length > 1) {
        for (const itemCode of extractedItemCode) {
          const productStockData = await api.getStoreProductStock({
            productCode: itemCode,
            storeName: storeName,
          });
          multipleItemsStockData.push({
            itemCode,
            availableQty: productStockData?.stockLevel ?? 0,
          });
        }
      } else if (extractedItemCode.length === 1) {
        data = await api.getStoreProductStock({
          productCode: extractedItemCode[0],
          storeName: storeName,
        });
      }

      return json({
        availableQty: data?.stockLevel ?? 0,
        multipleItemsStockData,
      });
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.stack || error);

        return validationError(
          "Something went wrong. Please try selecting the address again.",
        );
      }
      throw error;
    }
  }

  return null;
};

export const useCheckStockLevelPath = () => {
  const _url = useURL();
  return useCallback(() => _url(`/resources/store/check-stock-level`), [_url]);
};
