import { namedAction } from "remix-utils/named-action";

import { json } from "@remix-run/cloudflare";
import { useFetcher } from "@remix-run/react";

import { commitSession } from "~/commerce-sap/.server/sessions.server";

import { useURL } from "~/contexts";

export { default as VehicleSuitability } from "./components/vehicle-suitability";

export const action = async ({ request, context }: ActionArgs) => {
  const { session } = context;
  return namedAction(request, {
    toggleVehicleSuitability: async () => {
      const currentStatus = session.get("isVehicleSuitabilityToggled");
      session.set("isVehicleSuitabilityToggled", !currentStatus);
      await commitSession();
      return json({
        success: true,
        isVehicleSuitabilityToggled: !currentStatus,
      });
    },
    toggleVehicleSuitabilityEnabled: async () => {
      session.set("isVehicleSuitabilityToggled", true);
      await commitSession();
      return json({
        success: true,
        isVehicleSuitabilityToggled: true,
      });
    },
  });
};

export const useToggleVehicleSuitabilityActionPath = (actionName: string) => {
  const _url = useURL();
  return _url(
    `/resources/vehicle/vehicle-shop-by-vehicle?action=${actionName}`,
  );
};

// Custom hook to handle vehicle suitability actions
export const useVehicleSuitabilityActions = (actionName: string) => {
  const fetcherVehichleSuitability = useFetcher({
    key: "vehicleSuitabilityKey",
  });
  const togglePath = useToggleVehicleSuitabilityActionPath(actionName);

  const toggleVehicleSuitability = () => {
    fetcherVehichleSuitability.submit(null, {
      method: "post",
      action: togglePath,
    });
  };

  return {
    fetcherVehichleSuitability,
    toggleVehicleSuitability,
  };
};

export const useIsVehicleSuitabilityPending = () => {
  const fetcher = useFetcher({ key: "vehicleSuitabilityKey" });
  return fetcher.state !== "idle";
};
