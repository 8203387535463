import type { ReactElement, RefObject } from "react";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";

import { Await, Link, useLocation } from "@remix-run/react";

import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";

import { P, Text } from "~/components/ui/text";
import { AlertBanner } from "~/contentful/components/alert-banner";
import { DesktopNavBar } from "~/contentful/components/desktop-nav-bar";
import { NavSectionContent } from "~/contentful/components/nav-section";
import { UspBanner } from "~/contentful/components/usp-banner";
import type {
  AlertBannerContent,
  DesktopNavBarContent,
  NavSectionContent as NavSectionContentType,
  USPBannerContent,
} from "~/contentful/types";
import { useURL } from "~/contexts";
import { useCategory } from "~/contexts/mobile-menu";
import { cn } from "~/lib/ui";
import { useMediaScreen } from "~/lib/utils/screens";
import { LoginDrawer } from "~/routes/($locale)+/_auth+/components/login-drawer";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";
import {
  ACCOUNT_LANDING_PAGE_URL,
  ACCOUNT_PAGES,
} from "~/routes/($locale)+/account+/consts";
import { useBasket } from "~/routes/($locale)+/resources+/hooks/useBasket";
import MiniCartDrawer from "~/routes/($locale)+/resources+/mini-cart+/components/mini-cart-drawer";

import { Price } from "../price/price";
import { Dialog, DialogContent } from "../ui/dialog";
import { Logo } from "../ui/logo";
import { NavigationMenuLink } from "../ui/navigation-menu";
import { Skeleton } from "../ui/skeleton";
import { Spinner } from "../ui/spinner";
import { MobileMenu } from "./meganav-mobile-menu";
import * as Nav from "./meganav-nav";
import { MeganavSearchBar } from "./meganav-search-bar";
import { MeganavSearchBarSuggestions } from "./meganav-search-suggestions";

/**
 * NB: check \@see section below
 *
 * ## Meganav
 *
 * This component is the main navigation for the site.
 * It has a background that is revealed when the user scrolls past the ManuBoundy component.
 * ##Usage:
 * ```tsx
 * const boundryRef = useRef<HTMLDivElement>(null);
 * ...
 * <Meganav boundryRef={boundryRef} />
 * ...
 * ...
 * // some content
 * ...
 * ...
 * ...
 * <MenuBoundry ref={boundryRef} />
 * ```
 * --------------------
 * ## Routes
 * In routes file you can specify the variant of the meganav
 *
 * ```tsx
 *  export const handle: AppHandle = {
 *   meganav: {
 *    variant: "light",
 *  //or
 *   variant: (data:SerializeFrom<typeof loader>) =>{
 *      return data.theme === "light" ? "light" : "dark"
 *   }
 *  },
 * };
 * ```
 * --------------------
 * ## Types
 * @see app/lib/utils-types/handle.types.ts
 * --------------------
 * @see https://remix.run/docs/en/main/route/handle (handle)
 * @see https://remix.run/docs/en/main/hooks/use-matches (matches)
 * @see https://sergiodxa.com/articles/bubble-up-data-on-remix-routes
 */

export const Meganav = ({
  boundryRef,
  desktopNavBarContent,
  navigationSectionContent,
  uspBannerContent,
  alertBannerContent,
}: {
  boundryRef: RefObject<HTMLElement>;
  desktopNavBarContent?: DesktopNavBarContent;
  navigationSectionContent?: NavSectionContentType;
  uspBannerContent?: USPBannerContent;
  alertBannerContent?: AlertBannerContent;
}) => {
  const url = useURL();

  const oldScrollY = useRef(0);
  const menuRef = useRef<HTMLDivElement>(null);
  const [direction, setDirection] = useState<"up" | "down">("up");

  const scrollDirectionHandler = useCallback(() => {
    const scrollY = window.scrollY;
    const topThreshold = 10;

    if (scrollY <= topThreshold) {
      setDirection("up");
    } else if (scrollY > oldScrollY.current) {
      setDirection("down");
    } else if (scrollY < oldScrollY.current) {
      setDirection("up");
    }

    oldScrollY.current = scrollY;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollDirectionHandler);
    return () => {
      window.removeEventListener("scroll", scrollDirectionHandler);
    };
  }, [scrollDirectionHandler]);

  return (
    <>
      <Nav.Root boundryRef={boundryRef}>
        {/* The whole nav content container */}
        <Nav.Content ref={menuRef} className="fixed w-full">
          {/* Header */}
          <Nav.Header className="w-full">
            <div className="col-span-1 flex h-[46px] flex-wrap items-center justify-start gap-3 lg:h-12 xl:max-w-max">
              <LeftSideContent
                navContent={navigationSectionContent}
                className="lg:hidden"
              />
              <NavigationMenuLink
                to={url("/")}
                aria-label="Go to homepage"
                className="h-full py-[6px]"
              >
                <Logo className="h-7 lg:h-9" />
              </NavigationMenuLink>
            </div>
            <div className="order-last col-span-2 md:order-none md:col-span-1">
              <Nav.Search className="flex w-full flex-1 flex-row">
                <MeganavSearchBar
                  suggestions={text => <MeganavSearchBarSuggestions q={text} />}
                />
              </Nav.Search>
            </div>
            <div className="col-span-1 md:col-span-1">
              <RightSideContent />
            </div>
          </Nav.Header>
          <DesktopNavBar
            content={desktopNavBarContent}
            navContent={navigationSectionContent}
          />

          {/* It is currently not in the figma design so it is hidden */}
          {/* <Nav.MenuContent className="h-auto sm:hidden sm-max:block">
          <Button className="w-full rounded-none bg-brand-green-light py-5 text-xs text-brand-green-dark">
              <div className="flex items-center">
                <TruckIcon className="mr-4 w-6" />{" "}
                <span className="mr-1 font-bold">Add your vehicle </span>{" "}
                <span>and find parts designed to fit</span>{" "}
                <PlusIcon className="ml-4 h-5 w-5" />
              </div>
          </Button>
        </Nav.MenuContent> */}
        </Nav.Content>
        <div
          className={
            direction === "up"
              ? "sticky top-nav z-20 transition-all duration-300"
              : "sticky top-[54px] z-20 transition-all duration-300 md:top-0 lg:top-[58px]"
          }
        >
          <UspBanner content={uspBannerContent} />
          <AlertBanner content={alertBannerContent} />
        </div>

        {/* <Nav.PromoHeading className="place-content-around items-center bg-surface-accent-dark text-xs text-white">
        <span>
          <b>FREE Delivery</b> by Saturday, 10 Dec
        </span>
        <span className="sm-max:hidden">
          For <b>10% OFF</b> use code <b>PROMOCODE</b> at checkout
        </span>
        <span className="sm-max:hidden">
          Order by 2 PM for <b>NEXT DAY Delivery</b>
        </span>
      </Nav.PromoHeading> */}
      </Nav.Root>
    </>
  );
};

type LeftSideContentProps = {
  className?: string;
  navContent?: NavSectionContentType;
};

export const LeftSideContent = ({
  className,
  navContent,
}: LeftSideContentProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { key, search } = useLocation();
  const url = useURL();
  const { selectedCategory } = useCategory();

  const { user, myStoreInfo } = useRootLayoutData();
  const storeToDisplay = myStoreInfo?.store ?? null;

  useEffect(() => {
    setMenuOpen(false);
  }, [key]);

  return (
    <div className={cn("relative pl-2 xl:pl-0", className)}>
      <div
        className="relative flex cursor-pointer flex-col items-center justify-center gap-1 lg:flex-row"
        onClick={() => setMenuOpen(true)}
        role="button"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === "Enter" || e.key === " ") {
            setMenuOpen(true);
          }
        }}
      >
        {/* Increase touch size with pseudo-element */}
        <span className="absolute inset-[-5px] xl:inset-[-10px]" />
        <HamburgerMenuIcon
          height={20}
          width={20}
          className="z-10 ml-0 text-white"
        />
        <div className="z-10 flex items-center gap-2 text-2xs font-bold text-white no-underline hover:text-neutral-9 lg:text-base lg:font-extrabold">
          Menu
        </div>
      </div>

      <Dialog
        open={menuOpen}
        onOpenChange={() => {
          setMenuOpen(false);
        }}
      >
        <DialogContent
          closeButtonClassName="top-5 right-5 bg-transparent shadow-none text-neutral-1"
          className="bg-panel-translucent left-0   block h-screen w-full max-w-lg translate-x-0 overflow-hidden rounded-none bg-white p-0 sm:w-[340px] md:w-[448px] lg:w-[512px]"
        >
          <div className="flex h-full flex-col">
            <div className="flex-none">
              <div
                className={cn(
                  "flex h-[70px] items-center gap-2 bg-brand-secondary py-4 pl-[18px] pr-6 text-brand-primary-black",
                  user?.cdcUser && "bg-brand-primary",
                )}
              >
                <img
                  src="/icons/person.svg"
                  alt="My Account"
                  className="h-8 w-8 text-neutral-1"
                />
                <div className="flex flex-col gap-0">
                  <P className="text-base font-bold">My Account</P>
                  {!user?.cdcUser && (
                    <div className="flex flex-row items-center gap-2">
                      <Link
                        className="p-0 text-sm font-normal text-brand-primary-black"
                        to={(search ? search : "") + "#drawer=login"}
                      >
                        Log in
                      </Link>
                      |
                      <Link
                        className="p-0 text-sm font-normal text-brand-primary-black"
                        to={(search ? search : "") + "#drawer=login"}
                      >
                        Sign up
                      </Link>
                    </div>
                  )}
                  {user?.cdcUser && (
                    <Link to={ACCOUNT_LANDING_PAGE_URL}>
                      <Text className="text-xs font-normal">{`Hi, ${user.cdcUser.profile.firstName}`}</Text>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            {
              <>
                <div className="flex-grow overflow-y-auto overflow-x-hidden px-6">
                  {navContent ? (
                    <NavSectionContent content={navContent}>
                      <div className="mt-6">
                        {!selectedCategory && (
                          <Label className="mb-2 inline-block font-roboto text-lg font-bold uppercase text-black">
                            {navContent.fields.middleSectionTitle}
                          </Label>
                        )}
                        <MobileMenu key={key} />
                      </div>
                    </NavSectionContent>
                  ) : (
                    <>
                      {!selectedCategory && (
                        <Label className="mb-2 mt-6 inline-block font-roboto text-base font-bold uppercase text-black">
                          Shop By Category
                        </Label>
                      )}
                      <MobileMenu key={key} />
                    </>
                  )}
                </div>
                <div className="flex-none">
                  <div className="flex h-12 items-center gap-2 bg-brand-primary-black px-5">
                    <img src="/icons/location_marker.svg" alt="Location" />
                    <Link
                      to={url("/store-locator")}
                      onClick={() => setMenuOpen(false)}
                      className="text-sm font-bold text-white hover:no-underline"
                    >
                      <Suspense fallback={<Spinner />}>
                        <Await resolve={storeToDisplay}>
                          {store =>
                            store ? store.displayName : "Set your Store"
                          }
                        </Await>
                      </Suspense>
                    </Link>
                  </div>
                </div>
              </>
            }
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const RightSideContent = () => {
  const { user, myStoreInfo } = useRootLayoutData();
  const storeToDisplay = myStoreInfo?.store ?? null;
  const basket = useBasket();
  return (
    <div className="flex flex-row justify-end gap-4 pr-2 xl:gap-6 xl:pr-0">
      <RightSideContentGroup
        icon="/icons/location.svg"
        title="My Store"
        subtitle={storeToDisplay ? storeToDisplay.displayName : "Set Store"}
        smallScreenTitle="Stores"
        checked={!!storeToDisplay}
        checkIcon="/icons/check.svg"
      />
      <RightSideContentGroup
        icon="/icons/account.svg"
        title="My Account"
        subtitle={
          user?.cdcUser
            ? `Hi, ${user.cdcUser.profile.firstName}`
            : "Log in / Sign up"
        }
        smallScreenTitle={user?.cdcUser ? "Account" : "Log in"}
        //When user is logged in, should be set to true
        checked={!!user?.cdcUser}
        checkIcon="/icons/check.svg"
      />

      <MiniCartDrawer>
        <div>
          <RightSideContentGroup
            icon="/icons/cart.svg"
            title="My Cart"
            subtitle={
              basket?.basket && basket?.basket.totalPrice ? (
                <Price price={basket.basket.totalPrice} />
              ) : (
                <div className="h-4 py-1">
                  <Skeleton className="h-full bg-neutral-2" />
                </div>
              )
            }
            smallScreenTitle="Cart"
            //When adding product to cart, the counter should appear
            checked={false}
            checkIcon=""
            cartCount={
              basket?.basket?.totalUnitCount ??
              basket?.basket?.entries?.reduce(
                (acc, entry) => acc + (entry?.quantity ?? 1),
                0,
              ) ??
              0
            }
          />
        </div>
      </MiniCartDrawer>
    </div>
  );
};

type RightSideContent = {
  icon: string;
  title: string;
  subtitle: string | ReactElement | undefined;
  smallScreenTitle: string;
  checked: boolean;
  checkIcon: string;
  cartCount?: number;
};

const RightSideContentGroup = ({
  icon,
  title,
  subtitle,
  smallScreenTitle,
  checked,
  checkIcon,
  cartCount,
}: RightSideContent) => {
  const { pathname, search } = useLocation();
  const { isMobile } = useMediaScreen();

  const getLinkTo = () => {
    switch (title) {
      case "My Account":
        return checked
          ? !isMobile
            ? ACCOUNT_LANDING_PAGE_URL
            : ACCOUNT_PAGES.dashboard.path
          : "#";
      case "My Store":
        return "/store-locator";
      case "My Cart":
        if (pathname.includes("cart")) {
          return "#";
        }
        return (search ? search : "") + "#drawer=mini-cart";
      default:
        return "#";
    }
  };

  if (!checked && title === "My Account") {
    return (
      <LoginDrawer>
        <div className="flex shrink-0 cursor-pointer flex-col items-center gap-1 hover:no-underline lg:min-w-[90px] lg:flex-row">
          <RightSideContentGroupItem
            icon={icon}
            title={title}
            subtitle={subtitle}
            smallScreenTitle={smallScreenTitle}
            checked={checked}
            checkIcon={checkIcon}
          />
        </div>
      </LoginDrawer>
    );
  }

  return (
    <Link
      to={getLinkTo()}
      className="flex shrink-0 cursor-pointer flex-col items-center gap-1 hover:no-underline lg:min-w-[90px] lg:flex-row"
      preventScrollReset={title === "My Cart"}
      replace={title === "My Cart"}
    >
      <RightSideContentGroupItem
        icon={icon}
        title={title}
        subtitle={subtitle}
        smallScreenTitle={smallScreenTitle}
        checked={checked}
        checkIcon={checkIcon}
        cartCount={cartCount}
      />
    </Link>
  );
};

const RightSideContentGroupItem = ({
  icon,
  title,
  checkIcon,
  checked,
  subtitle,
  smallScreenTitle,
  cartCount,
}: RightSideContent) => (
  <>
    <span className="relative">
      <img
        src={icon}
        className="h-5 w-5 fill-white lg:h-8 lg:w-8"
        alt={title}
      />
      {checked && (
        <img
          src={checkIcon}
          className="absolute bottom-3 left-3 h-4 w-4 lg:bottom-5 lg:left-5"
          alt="check-icon"
        />
      )}
      {cartCount !== undefined && cartCount > 0 ? (
        <span
          className={`absolute bottom-3 left-2 rounded-lg bg-brand-primary px-1 text-xs font-normal text-brand-primary-black lg:bottom-[22px] ${
            cartCount <= 9 ? "lg:left-4" : "lg:left-3"
          }`}
        >
          {cartCount <= 9 ? cartCount : "9+"}
        </span>
      ) : null}
    </span>
    <div className="hidden lg:flex lg:flex-col">
      <span className="font-bold leading-lg tracking-wide text-white sm:block lg:text-base">
        {title}
      </span>
      <span className="font-normal leading-lg text-white lg:text-xs lg:font-semibold">
        {subtitle || "\u00A0"}
      </span>
    </div>
    <span className="inline text-2xs font-bold text-white lg:hidden">
      {smallScreenTitle}
    </span>
  </>
);
